import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

import { ReportEngineService } from "../../../services/report-engine.service";
import { ParametersTypeModel } from "src/app/models/parameters-type-model";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: 'app-parameters-create',
  templateUrl: './parameters-create.component.html',
  styleUrls: ['./parameters-create.component.scss']
})
export class ParametersCreateComponent implements OnInit {
  public reportEngineService: ReportEngineService;

  public parametersTypeModel: ParametersTypeModel;

  modelFilterControl = new FormControl('');
  filteredModels: Observable<string[]>;

  public operationValids: any = [];
  public fieldValids: any = [];
  models: string[] = [];

  public parametersTypeForm: FormGroup;

  public operationValidSelected
  public fieldValidSelected
  public modelSelected

  public errorMessage;

  ngOnInit(): void {
    this.loadDataElements();

    this.parametersTypeForm = this.formBuilder.group(
      {
        validOperation: ["", Validators.required],
        validFields: ["", Validators.required],
        description: [""],
        modelRule: [""],
      },
      { updateOn: "blur" }
    );

    // Configurar filtro dinâmico baseado nos modelos carregados
    this.filteredModels = this.modelFilterControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterModels(value || ''))
    );

  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    reportEngineService: ReportEngineService
  ) {
    this.reportEngineService = reportEngineService;
  }

  public async loadDataElements() {
    try {
      this.spinner.show();

      this.reportEngineService
        .getByOperationValid()
        .subscribe(
          (t) => {
            this.operationValids = t;
            this.spinner.hide();
          },
          (error) => {
            console.log("Erro ao obter as informações");
            this.spinner.hide();
          }
        );

      this.reportEngineService
        .getByFieldValid()
        .subscribe(
          (t) => {
            this.fieldValids = t;
            this.spinner.hide();
          },
          (error) => {
            console.log("Erro ao obter as informações");
            this.spinner.hide();
          }
        );

      this.reportEngineService
        .getByModelVehicles()
        .subscribe(
          (t) => {
            this.models = t;
            this.spinner.hide();
          },
          (error) => {
            console.log("Erro ao obter as informações");
            this.spinner.hide();
          }
        );

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  private _filterModels(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.models.filter(model => model.toLowerCase().includes(filterValue));
  }

  onModelSelected(event: any) {
    const selectedModel = event.option.value;
    console.log('Modelo selecionado:', selectedModel);
  }

  public async submitForm(): Promise<void> {
    this.spinner.show();

    const values = this.parametersTypeForm.value;
    let parametersType: ParametersTypeModel = new ParametersTypeModel();

    parametersType.name = values.description;
    parametersType.fieldName = this.fieldValidSelected;
    parametersType.operationType = this.operationValidSelected;
    parametersType.ruleModelContains = this.modelSelected;

    console.log(parametersType)

    this.reportEngineService.saveParametersType(parametersType).subscribe((t) => {
      this.router.navigate(["/tipo-parametro"]);
    });

    this.spinner.hide();
  }

}

