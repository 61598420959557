import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReportReprocessList } from 'src/app/models/report-reprocess-list';
import { ReportReprocessModel } from 'src/app/models/report-reprocess-model';
import { ToolsService } from 'src/app/services/migrate/tools.service';
import { ReportEngineService } from 'src/app/services/report-engine.service';

@Component({
  selector: 'app-detailed-analysis',
  templateUrl: './detailed-analysis.component.html',
  styleUrls: ['./detailed-analysis.component.scss']
})
export class DetailedAnalysisComponent implements OnInit {

  public reportEngineService: ReportEngineService;
  public analyzeJsonUuid = "";
  public reportUuid = "";
  public tableColumns: string[];
  public excludedDice = new MatTableDataSource<any>([]);

  constructor(
    private activatedRoute: ActivatedRoute,
    private tools: ToolsService,
    reportEngineService: ReportEngineService,
    private spinner: NgxSpinnerService,
    public router: Router,
  ) {
    this.reportEngineService = reportEngineService;
  }

  ngOnInit(): void {
    this.analyzeJsonUuid = this.activatedRoute.snapshot.params.uuidMinio;
    this.reportUuid = this.activatedRoute.snapshot.params.uuid;
    this.loadTableDefinitions();
    this.loadDataElements(this.analyzeJsonUuid);
  }

  private loadTableDefinitions(): void {
    this.tableColumns = [
      "tipoVeiculo",
      "motorista",
      "placa",
      "kmTotal",
      "consumoMovimento",
      "consumoParado",
      "consumoTotal",
      "mediaKmL",
      "rpmVerde",
      "rpmAmarelo",
      "rpmVermelho",
      "eliminar",
    ];
  }


  public async loadDataElements(analyzeReportUuid: any) {
    try {
      this.spinner.show();

      this.reportEngineService.getMinioJsonTrip(analyzeReportUuid).subscribe((data) => {
        if (data && data.length > 0) {
          data.pop(); // Remove o último elemento da lista
        }
        // Adiciona a propriedade 'eliminar' como false em cada elemento
        this.excludedDice.data = data.map(item => ({ ...item, eliminar: false }));
      });

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  public sendSelectedRows(): void {
    this.spinner.show();
    // Filtra os dados com o checkbox marcado
    const selectedRows = this.excludedDice.data.filter((element: any) => element.eliminar === true);

    if (selectedRows.length > 0) {
      // Criar um payload que será um ReportReprocessList com o campo 'content'
      const payload: ReportReprocessList = new ReportReprocessList();
      payload.payload = selectedRows.map((row: any) => ({
        driverName: row['MOTORISTA'],  // Pegando o campo motorista
        plate: row['PLACA']            // Pegando o campo placa
      }));

      // Chamar o serviço de reprocessamento com o payload correto
      this.reportEngineService.reprocessReport(payload, this.reportUuid).subscribe(response => {
        console.log('Dados enviados com sucesso:', response);
        this.spinner.hide();
        this.router.navigate([
          "/relatorio-analitico-mensal/consulta"
        ]);
      }, error => {
        this.spinner.hide();
        console.error('Erro ao enviar dados:', error);
      });
    } else {
      this.spinner.hide();
      console.log('Nenhuma linha foi selecionada.');
    }
  }


}
