import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

import {
  STORAGE_KEY_CURRENT_USER,
  STORAGE_KEY_ID_PERFIL,
} from "../../storageCore/constStorageKeys";
import storageX from "../../storageCore/storageX";

import { MatTableDataSource } from "@angular/material/table";
import { PageEvent } from "@angular/material/paginator";
import { AnalyzeReportOperatorModelPaginated } from "../../models/analyze-report-operator-model-paginated";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";

import Swal from "sweetalert2";
import { AnalyzeTripService } from "../../services/analyze-trip.service";
import { MinioService } from "../../services/minio.service";
import { MonitoringModel } from "../../models/monitoring-model";


@Component({
  selector: 'app-monitoring-request',
  templateUrl: './monitoring-request.component.html',
  styleUrls: ['./monitoring-request.component.scss']
})
export class MonitoringRequestComponent implements OnInit {
  public analyzeTripService: AnalyzeTripService;
  public minioService: MinioService;

  public datePipe: DatePipe;
  public actualPage: PageEvent;

  public analyzeReportOperatorModelsPaginated: AnalyzeReportOperatorModelPaginated;
  public monitoringModel: MonitoringModel;
  public analyzeReportOperatorsPage: AnalyzeReportOperatorModelPaginated;

  public analyzesOperators: MatTableDataSource<any> =
    new MatTableDataSource<any>();

  public tableColumns: string[];
  public typeFieldSelect = [
    { name: "Número Viagem", value: "PACKAGE" },
    { name: "Motorista", value: "DRIVER" },
    { name: "Placa", value: "PLATE" },
  ];

  public permissions: [];

  public filter = "";
  public field = "";
  public typeFieldFilterSelected;
  public errorMessage;

  constructor(
    datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    public router: Router,
    analyzeTripService: AnalyzeTripService,
    minioService: MinioService
  ) {
    this.datePipe = datePipe;
    this.analyzeTripService = analyzeTripService;
    this.minioService = minioService;
  }

  ngOnInit(): void {
    this.typeFieldFilterSelected = this.typeFieldSelect[0].value;
    this.field = this.typeFieldFilterSelected;
    this.initialPage();
    this.loadReports(this.actualPage, this.filter, this.field);
    this.loadTableDefinitions();
  }


  private loadReports(page: PageEvent, name: string, field: string): void {
    this.spinner.show();

    var profile: string = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get();
    this.analyzeTripService
      .getAllMonitoring(page, profile, name, field)
      .subscribe(
        (t) => {
          this.analyzeReportOperatorsPage = t;
          this.analyzesOperators =
            new MatTableDataSource<MonitoringModel>(
              this.analyzeReportOperatorsPage.content
            );
          this.spinner.hide();
        },
        (error) => {
          console.log("Erro ao obter as analises do operador");
          this.spinner.hide();
        }
      );
  }

  fieldFilter(): void {
    this.field = this.typeFieldFilterSelected;
    this.filter = "";
  }

  applyFilter(): void {
    this.loadReports(this.actualPage, this.filter, this.field);
  }

  private loadTableDefinitions(): void {
    this.tableColumns = [
      "packageNumber",
      "dateStart",
      "responsible",
      "driverName",
      "companyName",
      "plate",
      "statusMonitoring",
      "viewOrigin",
      "viewOperatorHandling",
      "sentManager",
      "update",
      "delete",
    ];
  }

  public handleSent(element) {
    this.router.navigate(["/solicitacao-monitoramento/create-analyze/" + element]);
  }

  openToUpdate(uuid: string) {
    this.router.navigate(["/solicitacao-monitoramento/edit/" + uuid]);
  }

  openAddressPopup(uuid: string) {
    this.analyzeTripService.getByAddress(uuid).subscribe((addresses: any[]) => {
      if (!addresses || addresses.length === 0) {
        Swal.fire({
          title: "Nenhum endereço encontrado",
          icon: "warning",
          confirmButtonText: "Fechar"
        });
        return;
      }

      const formattedAddresses = addresses.map(addr => `
        <div style="margin-bottom: 15px;">
          📍 <strong>${addr.addressname}</strong><br/>
          <strong> Latitude:</strong> ${addr.latitude}<br/>
          <strong>Longitude:</strong> ${addr.longitude}
        </div>
      `).join("");

      Swal.fire({
        title: "Lista de Endereços",
        html: `
          <div style="
            text-align: left;
            font-size: 15px;
            max-height: 300px;
            overflow-y: auto;
          ">
            ${formattedAddresses}
          </div>
        `,
        icon: "info",
        confirmButtonText: "Fechar",
        confirmButtonColor: "#3085d6",
        width: "500px",
        customClass: {
          popup: "swal-address-popup"
        }
      });
    });
  }


  openOriginPopup(uuid: string) {
    this.analyzeTripService.getByOrigin(uuid).subscribe((origin: any) => {

      if (!origin || !origin.addressname) {
        Swal.fire("Endereço não encontrado!", "", "warning");
        return;
      }
  
      const formattedAddress = `
        <div style="margin-bottom: 15px;">
          <strong>Data Prevista da Origem:</strong> ${origin.expected_departure_date}<br/>
          📍 <strong>${origin.addressname}</strong><br/>
          <strong>Latitude:</strong> ${origin.latitude}<br/>
          <strong>Longitude:</strong> ${origin.longitude}
        </div>
      `;
  
      Swal.fire({
        title: "Endereço de Origem",
        html: `
          <div style="
            text-align: left;
            font-size: 15px;
            max-height: 300px;
            overflow-y: auto;
          ">
            ${formattedAddress}
          </div>
        `,
        icon: "info",
        confirmButtonText: "Fechar",
        confirmButtonColor: "#3085d6",
        width: "500px",
        customClass: {
          popup: "swal-address-popup"
        }
      });
    });
  }
  

  public async handleDelete(element): Promise<void> {
    Swal.fire({
      icon: "warning",
      title: "Excluir Solicitação de Monitoramento",
      html: `<h6>Tem certeza que você deseja excluir essa Solicitação? </h6>`,
      showCloseButton: false,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: "Cancelar",
      showConfirmButton: true,
      confirmButtonText: "Confirmar",
      focusConfirm: false,
    }).then((action) => {
      if (action.isConfirmed) {
        this.SentFinish(element);
      }
    });
  }

  SentFinish(element): void {
    this.spinner.show();

    var operator = storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id;
    this.analyzeTripService.finishMonitoring(operator, element.uuid).subscribe(
      (v) => {
        console.log("Monitoramento finalizada com sucesso");
        this.loadReports(this.actualPage, this.filter, this.field);
        this.spinner.hide();
      },
      (e) => {
        console.log("Erro ao finalizar Monitoramento");
        this.spinner.hide();
        this.errorMessage = e.message;
        throw new EvalError(e);
      }
    );
  }

  exportReport(element): void {
    this.minioService.exportReport(element).subscribe();
  }

  onChangePage(event: PageEvent): void {
    this.actualPage = event;
    this.loadReports(this.actualPage, this.filter, this.field);
  }

  private initialPage(): void {
    this.actualPage = new PageEvent();
    this.actualPage.pageIndex = 0;
    this.actualPage.pageSize = 20;
  }

  getStatusStyle(status: string): { [key: string]: string } {
    switch (status) {
      case "M":
        return { color: "blue" };
      case "O":
        return { color: "green" };
      default:
        return {}; // Estilo padrão, se o status não corresponder a nenhum caso
    }
  }

}

