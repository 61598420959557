import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AnalyzeReportOperatorModel } from 'src/app/models/analyze-report-operator-model';
import { ReportMonthlyModel } from 'src/app/models/report-monthly-model';
import { AnalyzeReportOperatorModelPaginated } from 'src/app/models/analyze-report-operator-model-paginated';
import { MinioService } from 'src/app/services/minio.service';
import { ReportEngineService } from 'src/app/services/report-engine.service';

@Component({
  selector: 'app-monthly-report-all',
  templateUrl: './monthly-report-all.component.html',
  styleUrls: ['./monthly-report-all.component.scss']
})
export class MonthlyReportAllComponent implements OnInit {
  public reportEngineService: ReportEngineService;
  public minioService: MinioService;

  public datePipe: DatePipe;
  public actualPage: PageEvent;

  public analyzeReportOperatorModelsPaginated: AnalyzeReportOperatorModelPaginated;
  public analyzeReportOperatorModel: ReportMonthlyModel;
  public analyzeReportOperatorsPage: AnalyzeReportOperatorModelPaginated;

  public analyzesOperators: MatTableDataSource<any> =
    new MatTableDataSource<any>();

  public tableColumns: string[];
  public typeFieldSelect = [
    { name: "Nome Empresa", value: "NAME" },
  ];

  public permissions: [];
  private intervalId: any;

  public filter = "";
  public field = "";
  public typeFieldFilterSelected;
  public errorMessage;

  constructor(
    datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    public router: Router,
    reportEngineService: ReportEngineService,
    minioService: MinioService
  ) {
    this.datePipe = datePipe;
    this.reportEngineService = reportEngineService;
    this.minioService = minioService;
  }

  ngOnInit(): void {
    this.typeFieldFilterSelected = this.typeFieldSelect[0].value;
    this.field = this.typeFieldFilterSelected;
    this.initialPage();
    this.loadReports(this.actualPage, this.filter, this.field);
    this.loadTableDefinitions();
    this.startCallingLoadReports();
  }

  ngOnDestroy(): void {
    this.stopCallingLoadReports();
  }

  private startCallingLoadReports(): void {
    console.log("Data atual:", new Date());
    this.intervalId = setInterval(() => {
      this.loadReports(this.actualPage, this.filter, this.field);
    }, 50000); // Chama o método loadReports a cada 50 segundos
  }

  private stopCallingLoadReports(): void {
    clearInterval(this.intervalId); // Para de chamar loadReports
  }

  private loadReports(page: PageEvent, name: string, field: string): void {
    this.spinner.show();

    this.reportEngineService
      .getAllReportMonthly(page, name, field)
      .subscribe(
        (t) => {
          this.analyzeReportOperatorsPage = t;
          this.analyzesOperators =
            new MatTableDataSource<ReportMonthlyModel>(
              this.analyzeReportOperatorsPage.content
            );
          this.spinner.hide();
        },
        (error) => {
          console.log("Erro ao obter as analises do operador");
          this.spinner.hide();
        }
      );
  }

  fieldFilter(): void {
    this.field = this.typeFieldFilterSelected;
    this.filter = "";
  }

  applyFilter(): void {
    this.loadReports(this.actualPage, this.filter, this.field);
  }

  private loadTableDefinitions(): void {
    this.tableColumns = [
      "dateStart",
      "dateEnd",
      "statusReport",
      "companyName",
      "graficoFile",
      "consumoFile",
      "resumoFile",
      "baseFile",
      "jsonFile",
    ];
  }


  exportReport(element): void {
    // Use uma expressão regular para substituir os textos indesejados
    const cleanedElement = element.replace(/-BASE|-CONSUMO|-GRAFICO|-RESUMO/g, '');
    this.minioService.exportReport(cleanedElement).subscribe();
  }

  onChangePage(event: PageEvent): void {
    this.actualPage = event;
    this.loadReports(this.actualPage, this.filter, this.field);
  }

  private initialPage(): void {
    this.actualPage = new PageEvent();
    this.actualPage.pageIndex = 0;
    this.actualPage.pageSize = 20;
  }

  getStatusStyle(status: string): { [key: string]: string } {
    switch (status) {
      case "ERROR":
        return { color: "red" };
      case "FINISHED":
        return { color: "green" };
      case "PROCESSING":
        return { color: "blue" };
      default:
        return {}; // Estilo padrão, se o status não corresponder a nenhum caso
    }
  }

  public analyDetail(uuid: string, jsonFile: string) {
    // Remover o texto "-VALIDARJSON" de jsonFile
    const sanitizedJsonFile = jsonFile.replace('-VALIDARJSON', '');

    // Substituir a barra "/" por um hífen "-"
    const sanitizedFileName = sanitizedJsonFile.replace(/\//g, '-');
    console.log(sanitizedFileName);

    this.router.navigate([
      "/relatorio-analitico-mensal/analise-detalhada/",
      uuid,
      sanitizedFileName
    ]);
  }

}
