<div class="main-page consultas">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-12 my-2">
                <h1>
                    <a class="btn btn-voltar text-center mr-2" (click)="this.router.navigate(['/consultations'])"
                        data-title="Voltar"><i class="fas fa-arrow-left"></i></a>
                    Consulta de arquivos
                </h1>
            </div>
        </div>
        <!-- Cabeçalho de Detalhes do Relatório -->
        <div class="row" style="align-items: center;">
            <div class="col-6">
                <h3 style="color: #11207b; ">Dados do Relatório</h3>
                <div class="report-details row">
                    <div class="col-md-6">
                        <p style="text-transform: uppercase;"><strong style="color: #11207b;">Início do
                                Processamento:</strong> {{
                            detailReport.processing_start }}</p>
                    </div>
                    <div class="col-md-6">
                        <p style="text-transform: uppercase;"><strong style="color: #11207b;">Fim do
                                Processamento:</strong> {{ detailReport.processing_end
                            }}</p>
                    </div>
                    <div class="col-md-6">
                        <p style="text-transform: uppercase;"><strong style="color: #11207b;">Gerado por:</strong> {{
                            detailReport.nm_user }}</p>
                    </div>
                    <div class="col-md-6">
                        <p style="text-transform: uppercase;"><strong style="color: #11207b;">Tipo de
                                Relatório:</strong> {{ detailReport.name_type_report
                            }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row no-gutters mt-3">
            <div class="col-12">
                <table mat-table [dataSource]="multiplesFiles" class="mat-elevation-z8">

                    <!-- Objeto do Minio -->
                    <!-- Objeto do Minio -->
                    <ng-container matColumnDef="minio_object">
                        <th mat-header-cell *matHeaderCellDef>Arquivo</th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Arquivo" matTooltip="Arquivo">
                                <a (click)="exportReport(element.minio_object)" class="btn btn-link">
                                    <img height="20" [src]="getFileIcon(element.type_file_enum)"
                                        alt="Ícone de Arquivo" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="type_file_enum">
                        <th mat-header-cell *matHeaderCellDef>Tipo do Arquivo</th>
                        <td mat-cell *matCellDef="let element">{{ element.type_file_enum }}</td>
                    </ng-container>

                    <!-- Descrição -->
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef>Descrição</th>
                        <td mat-cell *matCellDef="let element">
                            {{ getDescription(element.minio_object) }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </div>
</div>