import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TrackerFileModel} from '../../../models/tracker-file-model';

@Component({
  selector: 'app-view-tracker-file',
  templateUrl: './view-tracker-file.component.html',
  styleUrls: ['./view-tracker-file.component.scss']
})
export class ViewTrackerFileComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public trackerFile: TrackerFileModel) { }

  ngOnInit(): void {

  }

}
