export enum TrackerFileSourceEventEnum {
    NORMAL = 'Gravação Normal',
    IGNITION_ON = 'Ignição Ligada',
    IGNITION_OFF = 'Ignição desligada',
    POWER_DISCONNECTED = 'Energia Desconectada',
    CRASH = 'Colisão',
    HARSH_ACCELERATION = 'Aceleração Brusca',
    HARSH_BRAKING = 'Frenagem Brusca',
    HARSH_TURNING = 'Virada Brusca',
    OVER_SPEED_ALARM = 'Excesso de Velocidade',
    PANIC_ALARM = 'Alarme de Panico',
    SOS_ALARM = 'Alarme de SOS',
    ELECTRONIC_FENCE = 'Cerca Eletrônica',
    PARKING_SAFEGUARD = 'Anti Furto Violado',
    MANUAL_RECORD_BY_TIME = 'Video Requisitado por horário',
    MANUAL_RECORD_BY_TIME_RANGE = 'Video requisitado por periodo',
    EYES_CLOSE = 'Sono (olho fechado)',
    YAWNING = 'Sono (bocejo)',
    DISTRACTION = 'Distração',
    SMOKING = 'Uso de cigarro',
    PHONE_USE = 'Uso de telefone',
    DRIVER_UNIDENTIFIED = 'Motorista não identificado)',
    IR_BLOCKING_DETECTION = 'Óculos com bloqueio infravermelho',
    SEATBELT_UNFASTENED = 'Sem cinto de segurança',
    DSM_ALARM = 'Alarme DSM',
    ADAS_ALARM = 'Alarme ADAS'
}
