<div class="main-page cadastros">
  <div class="container-fluid">
    <app-title>Relatório Analitico Mensal</app-title>

    <div class="row mt-3 no-gutters p-4 row-form">
      <div class="col-12">
        <form [formGroup]="ReportOperationalForm" (ngSubmit)="submitForm()">
          <h2 class="mb-3">Dados do Relatório</h2>
          <div class="form-row">
            <div class="form-group col-3">
              <mat-form-field appearance="outline">
                <mat-label>Data Inicio</mat-label>
                <input formControlName="dateStart" type="date" matInput placeholder="Data Inicio" required />
              </mat-form-field>
            </div>
            <div class="form-group col-3">
              <mat-form-field appearance="outline">
                <mat-label>Data Fim</mat-label>
                <input formControlName="dateEnd" type="date" matInput placeholder="Data Fim" required />
              </mat-form-field>
            </div>
            <div class="form-group col-md-3">
              <mat-form-field appearance="outline">
                <mat-label>Tipo de Relatório</mat-label>
                <mat-select formControlName="modelReport" [(value)]="typesReportSelected"
                  [(value)]="typesReportSelected" required>
                  <mat-option *ngFor="let type of typesReport" [value]="type.value">
                    {{ type.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="form-group col-3" style="margin-top: 15px;">
              <mat-checkbox formControlName="gpsJumps">Remover o salto GPS ?</mat-checkbox>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-4">
              <mat-form-field appearance="outline">
                <mat-label>Modelo de Empresa</mat-label>
                <mat-select formControlName="selectCompany" [(value)]="companyFilterSelected"
                  [(ngModel)]="companyFilterSelected" (selectionChange)="this.filterCompany()" required>
                  <mat-option *ngFor="let type of companiesSelect" [value]="type.name">
                    {{ type.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="form-group col-md-4">
              <mat-form-field appearance="outline">
                <mat-label>Empresa</mat-label>
                <input type="text" matInput [formControl]="controlCompany" [matAutocomplete]="autoEmpresa" required>
                <mat-autocomplete #autoEmpresa="matAutocomplete" [displayWith]="displayCompany">
                  <mat-option *ngFor="let type of this.companiesFilter" [value]="type">
                    {{type.nmNome}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>

            <div class="form-group col-4" style="margin-top: 15px;">
              <mat-checkbox formControlName="removeKm">Remover dados com KM inferior a 40 no mês ?</mat-checkbox>
            </div>
          </div>


          <div *ngIf="errorMessage" class="alert alert-danger">
            {{ errorMessage }}
          </div>

          <div class="form-group mt-2 mb-0 text-center">
            <back-button></back-button>
            <button type="submit" [disabled]="!ReportOperationalForm.valid"
              class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
              Gerar Relatório
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>