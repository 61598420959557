import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

import { AnalyzeReportSupervisorModel } from "src/app/models/analyze-report-supervisor-model";
import {
  STORAGE_KEY_CURRENT_USER,
  STORAGE_KEY_UUID_SUPERVISOR,
} from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";

import { MatTableDataSource } from "@angular/material/table";
import { PageEvent } from "@angular/material/paginator";
import { AnalyzeReportSupervisorModelPaginated } from "src/app/models/analyze-report-supervisor-model-paginated";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";

import Swal from "sweetalert2";
import { AnalyzeTripService } from "src/app/services/analyze-trip.service";
import { MatDialog } from "@angular/material/dialog";
import { MinioService } from "src/app/services/minio.service";

@Component({
  selector: "app-supervisor-analysis",
  templateUrl: "./supervisor-analysis.component.html",
  styleUrls: ["./supervisor-analysis.component.scss"],
})
export class SupervisorAnalysisComponent implements OnInit {
  public analyzeTripService: AnalyzeTripService;
  public minioService: MinioService;
  public datePipe: DatePipe;

  public analyzeReportSupervisorModelsPaginated: AnalyzeReportSupervisorModelPaginated;
  public analyzeReportSupervisorModel: AnalyzeReportSupervisorModel;
  public errorMessage;
  public analyzeReportSupervisorsPage: AnalyzeReportSupervisorModelPaginated;
  public analyzesManagers: MatTableDataSource<any> =
    new MatTableDataSource<any>();
  public tableColumns: string[];
  public typeFieldSelect = [
    { name: "Número Pacote", value: "PACKAGE" },
    { name: "Motorista", value: "DRIVER" },
    { name: "Placa", value: "PLATE" },
    { name: "Base Opera.", value: "BASE" },
  ];

  public actualPage: PageEvent;
  public permissions: [];
  public filter = "";
  public field = "";
  public typeFieldFilterSelected;
  private intervalId: any;

  constructor(
    datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    public router: Router,
    analyzeTripService: AnalyzeTripService,
    minioService: MinioService,
    public dialog: MatDialog
  ) {
    this.datePipe = datePipe;
    this.analyzeTripService = analyzeTripService;
    this.minioService = minioService;
  }

  ngOnInit(): void {
    this.typeFieldFilterSelected = this.typeFieldSelect[0].value;
    this.field = this.typeFieldFilterSelected;
    this.initialPage();
    this.loadReports(this.actualPage, this.filter, this.field);
    this.loadTableDefinitions();
    this.startCallingLoadReports();
  }

  ngOnDestroy(): void {
    this.stopCallingLoadReports();
  }

  private startCallingLoadReports(): void {
    console.log("Data atual:", new Date());
    this.intervalId = setInterval(() => {
      this.loadReports(this.actualPage, this.filter, this.field);
    }, 30000); // Chama o método loadReports a cada 30 segundos
  }

  private stopCallingLoadReports(): void {
    clearInterval(this.intervalId); // Para de chamar loadReports
  }

  private loadReports(page: PageEvent, name: string, field: string): void {
    this.spinner.show();

    var uuidSupervisor: string = storageX
      .whereKeyIs(STORAGE_KEY_UUID_SUPERVISOR)
      .get();
    this.analyzeTripService
      .getAllAnalyzeReportSupervisor(page, uuidSupervisor, name, field)
      .subscribe(
        (t) => {
          this.analyzeReportSupervisorsPage = t;
          this.analyzesManagers =
            new MatTableDataSource<AnalyzeReportSupervisorModel>(
              this.analyzeReportSupervisorsPage.content
            );
          this.spinner.hide();
        },
        (error) => {
          console.log("Erro ao obter as analises do operador");
          this.spinner.hide();
        }
      );
  }

  applyFilter(): void {
    this.loadReports(this.actualPage, this.filter, this.field);
  }

  openToTreatment(uuid: string) {
    const url = "/analise-supervisor/tratamento-operador/" + uuid;
    window.open(url, "_blank");
  }

  fieldFilter(): void {
    this.field = this.typeFieldFilterSelected;
    this.filter = "";
  }

  private loadTableDefinitions(): void {
    this.tableColumns = [
      "packageNumber",
      "dateStart",
      "dateEnd",
      "operationalBase",
      "companyDestiny",
      "driverName",
      "plate",
      "OperadorName",
      "filePdfAnalytical",
      "filePdfSynthetic",
      "operatorHandling",
      "managerName",
      "treatManagerName",
      "descriptionManager",
      "rejectionSupervisor",
      "finish",
    ];
  }

  public async handleFinish(element): Promise<void> {
    Swal.fire({
      icon: "warning",
      title: "Finalizar Análise",
      html: `<h6>Tem certeza que você deseja finalizar essa analise? </h6>`,
      showCloseButton: false,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: "Cancelar",
      showConfirmButton: true,
      confirmButtonText: "Confirmar",
      focusConfirm: false,
    }).then((action) => {
      if (action.isConfirmed) {
        this.analyzeFinish(element);
      }
    });
  }

  analyzeFinish(element): void {
    this.spinner.show();

    var operator = storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id;
    this.analyzeTripService.finishSupervisor(operator, element.uuid).subscribe(
      (v) => {
        console.log("Analise finalizada com sucesso");
        this.loadReports(this.actualPage, this.filter, this.field);

        const url = "/analise-supervisor/form-penalties/" + element.uuid;
        window.open(url, "_blank");

        this.spinner.hide();
      },
      (e) => {
        console.log("Erro ao finalizar analise");
        this.spinner.hide();
        this.errorMessage = e.message;
        throw new EvalError(e);
      }
    );
  }

  exportReport(element): void {
    this.minioService.exportReport(element).subscribe();
  }

  onChangePage(event: PageEvent): void {
    this.actualPage = event;
    this.loadReports(this.actualPage, this.filter, this.field);
  }

  private initialPage(): void {
    this.actualPage = new PageEvent();
    this.actualPage.pageIndex = 0;
    this.actualPage.pageSize = 20;
  }

}
