<div class="main-page cadastros">
  <div class="container-fluid">
    <app-title>Criar nova análise</app-title>

    <div class="row mt-3 no-gutters p-4 row-form">
      <div class="col-12">
        <form [formGroup]="reportOperationalForm" (ngSubmit)="submitForm()">
          <h2 class="mb-3">Dados da análise</h2>
          <div class="form-row">
            <div class="form-group col-2">
              <mat-form-field appearance="outline">
                <mat-label>Data Inicio</mat-label>
                <input formControlName="dateStart" type="datetime-local"  step="1" matInput placeholder="Data Inicio" required />
              </mat-form-field>
            </div>
            <div class="form-group col-2" *ngIf="reportOperationalForm.get('dateStart').value != ''">
              <mat-form-field appearance="outline">
                <mat-label>Data Fim</mat-label>
                <input formControlName="dateEnd" type="datetime-local"  step="1" matInput placeholder="Data Fim" required />
              </mat-form-field>
              <div *ngIf="reportOperationalForm.hasError('invalidDateRange')">
                A data de início não pode ser maior que a data atual e a data de fim deve ser maior que a data de
                início.
              </div>
              <div *ngIf="reportOperationalForm.hasError('invalidFutureDate')">
                As datas não podem ser no futuro.
              </div>
            </div>

            <div class="form-group col-2">
              <mat-form-field appearance="outline">
                <mat-label>Núm. Pacote</mat-label>
                <input formControlName="packageNumber" matInput placeholder="Núm. Pacote" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Bases Operacionais</mat-label>
                <mat-select formControlName="selectOperationaBase" required>
                  <mat-option *ngFor="let type of operationalBases[0]" [value]="type.uuid">
                    {{ type.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="form-group col-md-3">
              <mat-form-field appearance="outline">
                <mat-label>Tipo de Análise</mat-label>
                <mat-select formControlName="selectTypeAnalyze" [(value)]="typeAnalyzeFilterSelected"
                  [(ngModel)]="typeAnalyzeFilterSelected" (selectionChange)="this.filterCompany()" required>
                  <mat-option *ngFor="let type of typeAnalyzeSelect" [value]="type.value">
                    {{ type.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-3" *ngIf="typeAnalyzeFilterSelected == 'DRIVER_OPERATIONAL'">
              <mat-form-field appearance="outline">
                <mat-label>Modelo de Empresa</mat-label>
                <mat-select formControlName="selectCompany" [(value)]="companyFilterSelected"
                  [(ngModel)]="companyFilterSelected" (selectionChange)="this.filterCompany()">
                  <mat-option *ngFor="let type of companiesSelect" [value]="type.name">
                    {{ type.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="form-group col-md-3" *ngIf="typeAnalyzeFilterSelected == 'DRIVER_OPERATIONAL'">
              <mat-form-field appearance="outline">
                <mat-label>Empresa</mat-label>
                <input type="text" matInput [formControl]="controlCompany" [matAutocomplete]="autoEmpresa" required>
                <mat-autocomplete (optionSelected)="this.getManagerAndSupervisor()" #autoEmpresa="matAutocomplete"
                  [displayWith]="displayCompany">
                  <mat-option *ngFor="let type of this.companiesFilter" [value]="type">
                    {{type.nmNome}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>

            <div class="form-group col-md-6"
              *ngIf="controlCompany.value != '' && typeAnalyzeFilterSelected == 'DRIVER_OPERATIONAL'">
              <mat-form-field appearance="outline">
                <mat-label>Motorista</mat-label>
                <input type="text" matInput [formControl]="controlDriver" [matAutocomplete]="autoDriver" required>
                <mat-autocomplete #autoDriver="matAutocomplete" [displayWith]="displayDriver">
                  <mat-option *ngFor="let type of this.driversFiltered | async" [value]="type">
                    {{type.nmNome}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>

            <div class="form-group col-md-3" *ngIf="typeAnalyzeFilterSelected == 'VEHICLE_OPERATIONAL'">
              <mat-form-field appearance="outline">
                <mat-label>Placa / Frota</mat-label>
                <input type="text" matInput [formControl]="controlVehicle" [matAutocomplete]="autoTerminal" required />
                <mat-autocomplete #autoTerminal="matAutocomplete" [displayWith]="displayTerminal"
                  (optionSelected)="onVehicleSelected($event.option.value)">
                  <mat-option *ngFor="let type of vehiclesFiltered | async" [value]="type">
                    {{ type.cdPlaca }} / {{ type.frota }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>

            <div class="form-group col-md-3" *ngIf="typeAnalyzeFilterSelected == 'VEHICLE_OPERATIONAL'">
              <mat-form-field appearance="outline">
                <mat-label>Nome Motorista</mat-label>
                <input formControlName="driverName" matInput placeholder="Nome Motorista" />
              </mat-form-field>
            </div>

            <div class="form-group col-md-3" *ngIf="typeAnalyzeFilterSelected == 'VEHICLE_OPERATIONAL'">
              <mat-form-field appearance="outline">
                <mat-label>Nome Empresa</mat-label>
                <input type="text" matInput [formControl]="controlCompanyName" placeholder="Nome da Empresa" disabled />
              </mat-form-field>
            </div>

          </div>
          <h2 class="mb-3">Gestores Disponiveis</h2>
          <div class="form-row">
            <div *ngFor="let task of managerAvailable" class="task-section">
              <div>
                <input type="checkbox" [checked]="task.allComplete" (click)="toggleSelectedManager(task)"
                  (change)="setAllManager(task, $event.target.checked)">
                <span style="margin-left: 5px;">{{ task.managerName }}</span>
              </div>
              <ul>
                <li *ngFor="let subtask of task.subtasks">
                  <input type="checkbox" [(ngModel)]="subtask.completed" (click)="toggleSelectedManager(subtask)"
                    (ngModelChange)="updateAllCompleteManager(task)">
                  <span style="margin-left: 5px;">{{ subtask.managerName }}</span>
                </li>
              </ul>
            </div>
          </div>

          <h2 class="mb-3">Supervisores Disponiveis</h2>
          <div class="form-row">
            <div *ngFor="let task of supervisorAvailable" class="task-section">
              <div>
                <input type="checkbox" [checked]="task.allComplete" (click)="toggleSelectedSupervisor(task)"
                  (change)="setAllSupervisor(task, $event.target.checked)">
                <span style="margin-left: 5px;">{{ task.supervisorName }}</span>
              </div>
              <ul>
                <li *ngFor="let subtask of task.subtasks">
                  <input type="checkbox" [(ngModel)]="subtask.completed" (click)="toggleSelectedSupervisor(subtask)"
                    (ngModelChange)="updateAllCompleteSupervisor(task)">
                  <span style="margin-left: 5px;">{{ subtask.supervisorName }}</span>
                </li>
              </ul>
            </div>
          </div>


          <div class="form-row">
            <div class="form-group col-8">
              <mat-form-field appearance="outline">
                <mat-label>Descrição análise</mat-label>
                <input formControlName="description" matInput placeholder="Descrição" required />
              </mat-form-field>
            </div>
            <div class="form-group col-4">
              <mat-checkbox formControlName="gpsJumps">Remover o salto GPS ?</mat-checkbox>
            </div>
          </div>


          <div *ngIf="errorMessage" class="alert alert-danger">
            {{ errorMessage }}
          </div>

          <div class="form-group mt-2 mb-0 text-center">
            <back-button></back-button>
            <button type="submit" [disabled]="!reportOperationalForm.valid"
              class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
              Gerar Análise
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>