<div class="main-page cadastros">
  <div class="container-fluid">
    <!-- LINHA TITULO -->
    <div class="row align-items-center">
      <div class="col-12 my-2">
        <h1>Qual cadastro você deseja fazer?</h1>
      </div>
    </div>
    <!-- ITEM CARDS -->
    <div class="row mt-3">
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('JORNADA_INSERCAO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/journey-control/register'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icon-1-page.svg" height="55" alt="" />
              <h5>Jornada</h5>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('COMPANY_INSERCAO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/companies/create'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/empresas.svg" height="40" alt="" />
              <h5>Empresa</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('USER_INSERCAO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/users/create'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/usuario.svg" height="40" alt="" />
              <h5>Usuário</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('VEHICLE_INSERCAO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/vehicles/create'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/veiculo.svg" height="40" alt="" />
              <h5>Veículo</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('SENSOR_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/sensor/create'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/sensor.svg" height="40" alt="" />
              <h5>Sensor TPMS</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2" *ngIf="this.permissionService.hasPermission('PNEU_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/pneu/create'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/pneu.svg" height="40" alt="" />
              <h5>Gestão de Pneu</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('TERMINAL_INSERCAO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/terminal/create'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/processador.svg" height="40" alt="" />
              <h5>Terminal</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('DRIVER_INSERCAO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/drivers/create'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/motorista.svg" height="40" alt="" />
              <h5>Motorista</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('TRANSPORTER_INSERCAO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/transporters/create'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/motorista.svg" height="40" alt="" />
              <h5>Transportador/Agregado</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('MAINTENANCE_INSERCAO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/maintenance/create'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/manutencao.svg" height="40" alt="" />
              <h5>Manutenção</h5>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('TERMINAL_ALARME_INSERCAO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/terminal-alarme/create'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/controle.svg" height="40" alt="" />
              <h5>Eventos</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('ATIVIDADE_INSERCAO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/atividades/create'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/agenda.svg" height="40" alt="" />
              <h5>Atividade</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('PERFIL_INSERCAO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/perfil/create'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/escudo.svg" height="40" alt="" />
              <h5>Perfil</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('PERFIL_TIPO_INSERCAO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/perfil-tipo/create'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/compartilhar.svg" height="40" alt="" />
              <h5>Tipo de Perfil</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('MODULO_INSERCAO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/modulo/create'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/modulo.svg" height="40" alt="" />
              <h5>Modulo</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('FUNCIONALIDADE_INSERCAO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/funcionalidade/create'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/funcao.svg" height="40" alt="" />
              <h5>Funcionalidade</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('FUNCIONALIDADE_TIPO_INSERCAO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/funcionalidade-tipo/create'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/funcao2.svg" height="40" alt="" />
              <h5>Tipo de Funcionalidade</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('SUPERVISOR_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/supervisor/create'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/supervisor.svg" height="85" alt="" />
              <h5>Supervisor</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('GESTOR_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/gestor/create'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/manager.svg" height="85" alt="" />
              <h5>Gestor</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2">
        <a (click)="this.router.navigate(['/analise-operacional/create'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/report-async.svg" height="55" alt="" />
              <h5>Analise Operacional</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('MONITORING_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/solicitacao-monitoramento/create'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/monitoring.svg" height="55" alt="" />
              <h5>Solicitação de Monitoramento</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
      *ngIf="this.permissionService.hasPermission('TIPO_PARAMETRO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/tipo-parametro/create'])"
          >
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/tipo-parametro.svg" height="55" alt="" />
              <h5>Tipo de Paramêtros Relatorios</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('REGRA_RELATORIO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/regra-relatorio/create'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/relatorio-regra.svg" height="55" alt="" />
              <h5>Regras Relatorio</h5>
            </div>
          </div>
        </a>
      </div>
      
    </div>
  </div>
</div>