import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AnalyzeTripService } from "../../../services/analyze-trip.service";
import { toCamel } from "snake-camel";
import { CoreService } from "src/app/services/migrate/api.core.service";
import { ToolsService } from "src/app/services/migrate/tools.service";
import { STORAGE_KEY_CURRENT_USER } from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";

import { AnalyzeReportModel } from "src/app/models/analyze-report-model";

@Component({
  selector: "app-new-analysis",
  templateUrl: "./new-analysis.component.html",
  styleUrls: ["./new-analysis.component.scss"],
})
export class NewAnalysisComponent implements OnInit {
  public analyzeTripService: AnalyzeTripService;
  public serviceCore: CoreService;

  public analyzeReportModel: AnalyzeReportModel;

  public reportOperationalForm: FormGroup;

  public analyzeReportOld: any;
  public analyzeReportUuid = "";

  public typeAnalyzeSelect: any = [];

  public typeAnalyzeFilterSelected;

  public errorMessage;

  ngOnInit(): void {
    this.reportOperationalForm = this.formBuilder.group(
      {
        dateStart: ["", Validators.required],
        dateEnd: ["", Validators.required],
      },
      { validators: this.validateDateRange.bind(this) }
    );

    this.analyzeReportUuid = this.activatedRoute.snapshot.params.uuid;

    this.loadDataElements(this.analyzeReportUuid);

    this.reportOperationalForm.setValidators(this.validateDateRange.bind(this));
  }

  constructor(
    private tools: ToolsService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    analyzeTripService: AnalyzeTripService,
    apiserviceCore: CoreService
  ) {
    this.serviceCore = apiserviceCore;
    this.analyzeTripService = analyzeTripService;
  }

  validateDateRange(formGroup: FormGroup) {
    const dateStartControl = formGroup.get("dateStart");
    const dateEndControl = formGroup.get("dateEnd");
  
    if (!dateStartControl || !dateEndControl) return;
  
    const startDate = new Date(dateStartControl.value);
    const endDate = new Date(dateEndControl.value);
    const now = new Date();
  
    const invalidFutureDateError = startDate > now || endDate > now;
    const invalidDateRangeError = endDate < startDate;
  
    if (invalidFutureDateError) {
      dateStartControl.setErrors({ invalidFutureDate: true });
      dateEndControl.setErrors({ invalidFutureDate: true });
    } else {
      dateStartControl.setErrors(null);
      dateEndControl.setErrors(null);
    }
  
    if (invalidDateRangeError) {
      dateEndControl.setErrors({ invalidDateRange: true });
    } else if (!invalidFutureDateError) {
      dateEndControl.setErrors(null);
    }
  
    if (!invalidFutureDateError && !invalidDateRangeError) {
      formGroup.setErrors(null);
    } else {
      formGroup.setErrors({
        ...(invalidFutureDateError && { invalidFutureDate: true }),
        ...(invalidDateRangeError && { invalidDateRange: true })
      });
    }
  }

  public async loadDataElements(reportUuid: string) {
    try {
      this.analyzeTripService
        .getByUuidAnalyzeReport(reportUuid)
        .subscribe((resp) => {
          this.analyzeReportOld = toCamel(resp);
          this.fillTransporterData();
        });

      this.tools.builder().spinner.hide();
    } catch (error) {
      this.tools.builder().spinner.hide();
      throw new EvalError(error);
    }
  }

  private fillTransporterData(): void {
    console.log(this.analyzeReportOld);
    this.reportOperationalForm
      .get("dateStart")
      .setValue(this.analyzeReportOld.analyzeEndDate);
  }

  public async submitForm(): Promise<void> {
    this.spinner.show();
    const values = this.reportOperationalForm.value;

    var analyzeReport: AnalyzeReportModel = new AnalyzeReportModel();

    const dataFimRaw = values.dateEnd;

    // Converte para Date
    const dataFim = new Date(dataFimRaw);

    // Função auxiliar para garantir formatação completa
    const formatDateTime = (d: Date): string => {
      const pad = (n: number) => n.toString().padStart(2, '0');
      return `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())}T${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}`;
    };

    const dateEnd = formatDateTime(dataFim);

    this.typeAnalyzeFilterSelected =
      this.analyzeReportOld.typeReportAnalyzeEnum;

    const date = this.analyzeReportOld.analyzeStartDate;

    analyzeReport.analyzeStartDate = date.length === 16 ? date + ":00" : date;
    analyzeReport.analyzeEndDate = dateEnd;
    analyzeReport.description = this.analyzeReportOld.description;
    analyzeReport.packageNumber = this.analyzeReportOld.packageNumber;
    analyzeReport.operationalBaseUuid =
      this.analyzeReportOld.operationalBase.uuid;
    if (this.analyzeReportOld.driver != null) {
      analyzeReport.idDriver = this.analyzeReportOld.driver.id;
    }

    analyzeReport.typeReportAnalyzeEnum = this.typeAnalyzeFilterSelected;
    analyzeReport.jumpGps = this.analyzeReportOld.jumpGps;
    analyzeReport.managerAnalyzeUuid = null;
    analyzeReport.supervisorUuid = null;
    analyzeReport.operatorAnalyzeId = storageX
      .whereKeyIs(STORAGE_KEY_CURRENT_USER)
      .get()?.id;
    analyzeReport.userId = storageX
      .whereKeyIs(STORAGE_KEY_CURRENT_USER)
      .get()?.id;

    if (this.analyzeReportOld.company != null) {
      analyzeReport.idCompany = this.analyzeReportOld.company.id;
      analyzeReport.idDivision = null;
    } else {
      analyzeReport.idDivision = this.analyzeReportOld.division.id;
      analyzeReport.idCompany = null;
    }

    if (this.analyzeReportOld.terminal != null) {
      analyzeReport.nameDriver = this.analyzeReportOld.nameDriverManual;
      analyzeReport.idTerminal = this.analyzeReportOld.terminal.id;
    }

    console.log(analyzeReport);

    this.analyzeTripService
      .updateNewAnalyzeReport(
        analyzeReport,
        this.analyzeReportUuid,
        values.dateStart + ":01",
        values.dateEnd + ":00"
      )
      .subscribe((t) => {
        this.analyzeReportModel = toCamel(t) as AnalyzeReportModel;
      });

    // Inicia o temporizador de 3 segundos
    setTimeout(() => {
      // Esconde o spinner
      this.spinner.hide();

      // Navega para a rota desejada
      this.router.navigate(["/analise-operacional"]);
    }, 3000); // 3000 milissegundos = 3 segundos
  }
}
