import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";

import { ReportEngineService } from "../../../services/report-engine.service";
import { ReportingRuleModel } from "src/app/models/reporting-rule-model";
import { toCamel } from "snake-camel";

@Component({
  selector: 'app-reporting-rule-edit',
  templateUrl: './reporting-rule-edit.component.html',
  styleUrls: ['./reporting-rule-edit.component.scss']
})
export class ReportingRuleEditComponent implements OnInit {
  public reportEngineService: ReportEngineService;

  public reportingRuleUuid = "";
  public reportingRuleOld: any;

  public reportingRuleModelModel: ReportingRuleModel;

  public reportTypes: any = [];
  public parametersValids: any = [];

  public reportingRuleForm: FormGroup;

  public typeReportSelected
  public typeParametersSelected

  public errorMessage;

  operationTypeMap: { [key: string]: string } = {
    'B': 'MAIOR',
    'S': 'MENOR',
    'E': 'IGUAL',
    'D': 'DIFERENTE'
  };


  ngOnInit(): void {
    this.reportingRuleForm = this.formBuilder.group(
      {
        typeReport: ["", Validators.required],
        typeParameters: ["", Validators.required],
        valueRule: ["", Validators.required],
      },
      { updateOn: "blur" }
    );

    this.reportingRuleUuid = this.activatedRoute.snapshot.params.uuid;
    this.loadDataElements(this.reportingRuleUuid);

  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    reportEngineService: ReportEngineService
  ) {
    this.reportEngineService = reportEngineService;
  }

  // Método para retornar a descrição correta
  getOperationTypeDescription(operationType: string): string {
    return this.operationTypeMap[operationType] || operationType; // Retorna o valor mapeado ou o valor original se não houver mapeamento
  }

  public async loadDataElements(reportingRuleUuid: string) {
    try {
      this.spinner.show();

      this.reportEngineService
        .getByReportTypes()
        .subscribe(
          (t) => {
            this.reportTypes = t;
            this.spinner.hide();
          },
          (error) => {
            console.log("Erro ao obter as informações");
            this.spinner.hide();
          }
        );

      this.reportEngineService
        .getByParameters()
        .subscribe(
          (t) => {
            this.parametersValids = t;
            this.spinner.hide();
          },
          (error) => {
            console.log("Erro ao obter as informações");
            this.spinner.hide();
          }
        );

      this.reportEngineService
        .getByUuidReportingRule(reportingRuleUuid)
        .subscribe((resp) => {
          this.reportingRuleOld = toCamel(resp);
          this.fillTransporterData();
        });

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  private fillTransporterData(): void {
    console.log(this.reportingRuleOld);

    this.reportingRuleForm
      .get("typeReport")
      .setValue(this.reportingRuleOld.reportTypeUuid);
    this.reportingRuleForm
      .get("typeParameters")
      .setValue(this.reportingRuleOld.parametersTypeUuid);

    this.typeReportSelected = this.reportingRuleOld.reportTypeUuid;
    this.typeParametersSelected = this.reportingRuleOld.parametersTypeUuid;
    this.reportingRuleForm
      .get("valueRule")
      .setValue(this.reportingRuleOld.valueRule);
  }


  public async submitForm(): Promise<void> {
    this.spinner.show();

    const values = this.reportingRuleForm.value;
    let reportingRule: ReportingRuleModel = new ReportingRuleModel();

    reportingRule.reportTypeUuid = this.typeReportSelected;
    reportingRule.reportingRuleUuid = this.typeParametersSelected;
    reportingRule.value = values.valueRule;

    console.log(reportingRule)


    this.reportEngineService
      .updateReportingRule(reportingRule, this.reportingRuleUuid)
      .subscribe((t) => {
        this.reportingRuleModelModel = toCamel(t) as ReportingRuleModel;
        this.router.navigate(["/regra-relatorio"]);
      });

    this.spinner.hide();
  }

}
