import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";

import { ReportEngineService } from "../../../services/report-engine.service";
import { ReportingRuleModel } from "src/app/models/reporting-rule-model";

@Component({
  selector: 'app-reporting-rule-create',
  templateUrl: './reporting-rule-create.component.html',
  styleUrls: ['./reporting-rule-create.component.scss']
})
export class ReportingRuleCreateComponent implements OnInit {
  public reportEngineService: ReportEngineService;

  public reportingRuleModelModel: ReportingRuleModel;

  public reportTypes: any = [];
  public parametersValids: any = [];

  public reportingRuleForm: FormGroup;

  public typeReportSelected
  public typeParametersSelected

  public errorMessage;

  operationTypeMap: { [key: string]: string } = {
    'B': 'MAIOR',
    'S': 'MENOR',
    'E': 'IGUAL',
    'D': 'DIFERENTE'
  };


  ngOnInit(): void {
    this.loadDataElements();

    this.reportingRuleForm = this.formBuilder.group(
      {
        typeReport: ["", Validators.required],
        typeParameters: ["", Validators.required],
        valueRule: ["", Validators.required],
      },
      { updateOn: "blur" }
    );

  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    reportEngineService: ReportEngineService
  ) {
    this.reportEngineService = reportEngineService;
  }

  // Método para retornar a descrição correta
  getOperationTypeDescription(operationType: string): string {
    return this.operationTypeMap[operationType] || operationType; // Retorna o valor mapeado ou o valor original se não houver mapeamento
  }

  public async loadDataElements() {
    try {
      this.spinner.show();

      this.reportEngineService
        .getByReportTypes()
        .subscribe(
          (t) => {
            this.reportTypes = t;
            this.spinner.hide();
          },
          (error) => {
            console.log("Erro ao obter as informações");
            this.spinner.hide();
          }
        );

      this.reportEngineService
        .getByParameters()
        .subscribe(
          (t) => {
            this.parametersValids = t;
            this.spinner.hide();
          },
          (error) => {
            console.log("Erro ao obter as informações");
            this.spinner.hide();
          }
        );

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }


  public async submitForm(): Promise<void> {
    this.spinner.show();

    const values = this.reportingRuleForm.value;
    let reportingRule: ReportingRuleModel = new ReportingRuleModel();

    reportingRule.reportTypeUuid = this.typeReportSelected;
    reportingRule.reportingRuleUuid = this.typeParametersSelected;
    reportingRule.value = values.valueRule;

    console.log(reportingRule)

    this.reportEngineService.saveReportingRule(reportingRule).subscribe((t) => {
      this.router.navigate(["/regra-relatorio"]);
    });

    this.spinner.hide();
  }

}

