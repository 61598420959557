import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MinioService } from 'src/app/services/minio.service';
import { ReportEngineService } from 'src/app/services/report-engine.service';

@Component({
  selector: 'app-multiple-files-report',
  templateUrl: './multiple-files-report.component.html',
  styleUrls: ['./multiple-files-report.component.scss']
})
export class MultipleFilesReportComponent implements OnInit {
  public reportEngineService: ReportEngineService;
  public minioService: MinioService;

  public reportUuid = "";

  displayedColumns: string[] = ["type_file_enum", 'minio_object', "description"];


  public multiplesFiles: MatTableDataSource<any> =
    new MatTableDataSource<any>();

  public detailReport: any;

  constructor(private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public router: Router,
    reportEngineService: ReportEngineService,
    minioService: MinioService
  ) {
    this.reportEngineService = reportEngineService;
    this.minioService = minioService;
  }

  ngOnInit(): void {
    this.reportUuid = this.activatedRoute.snapshot.params.uuid;
    this.loadMultiples();
  }

  private loadMultiples(): void {
    this.spinner.show();

    this.reportEngineService
      .getDetailReport(this.reportUuid)
      .subscribe(
        (t) => {
          this.detailReport = t;
        },
        (error) => {
          console.log("Erro ao obter as analises do operador");
          this.spinner.hide();
        }
      );

    this.reportEngineService
      .getMultiplesFiles(this.reportUuid)
      .subscribe(
        (t) => {
          this.multiplesFiles = new MatTableDataSource(t); // Ajuste para atribuir ao MatTableDataSource
        },
        (error) => {
          console.log("Erro ao obter as analises do operador");
          this.spinner.hide();
        }
      );

    this.spinner.hide();
  }

  getFileIcon(type: string): string {
    switch (type) {
      case 'PDF':
        return 'assets/img/file-pdf.svg';
      case 'XLSX':
        return 'assets/img/file-xlxs.svg';
      case 'JSON':
        return 'assets/img/file-json.svg';
      default:
        return 'assets/img/file-default.svg'; // Ícone padrão, se necessário
    }
  }

  exportReport(element): void {
    // Use uma expressão regular para substituir os textos indesejados
    const cleanedElement = element.replace(/-BASE|-CONSUMO|-GRAFICO|-RESUMO/g, '');
    this.minioService.exportReport(cleanedElement).subscribe();
  }

  getDescription(minioObject: string): string {
    // Expressão regular para capturar o texto após a extensão e o traço
    const match = minioObject.match(/\.(pdf|xlsx|json)-(.+)$/);
    return match ? match[2] : ''; // Extrai o texto após o traço
  }


}
