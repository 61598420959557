<div class="main-page consultas">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-12 my-2">
                <h1>
                    <a class="btn btn-voltar text-center mr-2" (click)="this.router.navigate(['/consultations'])"
                        data-title="Voltar"><i class="fas fa-arrow-left"></i></a>
                    Consulta de historico de análises
                </h1>
            </div>
        </div>
        <div *ngIf="analyzeReportHistoricPage && analyzeReportHistoricPage.page?.totalElements === 0">
            <div class="row no-gutters mt-3">
                <p>Nenhum registro até o momento...</p>
            </div>
        </div>
        <div class="row no-gutters mt-3">
            <div class="mat-elevation table-content">
                <div fxLayout fxLayoutAlign="center center">
                    <mat-form-field fxFlex="10%" class="ml-2">
                        <mat-label>Campo Filtro</mat-label>
                        <mat-select (selectionChange)="this.fieldFilter()" [(value)]="typeFieldFilterSelected"
                            [(ngModel)]="typeFieldFilterSelected">
                            <mat-option *ngFor="let type of typeFieldSelect" [value]="type.value">
                                {{ type.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="40%" class="ml-2">
                        <mat-label>Filtrar por pacote</mat-label>
                        <input matInput autocomplete="off" (keyup)="this.applyFilter()" [(ngModel)]="filter"
                            style="text-transform: uppercase;" #input>
                    </mat-form-field>
                </div>
                <table mat-table [dataSource]="analyzesManagers" matSort class="mat-elevation-z8">
                    <ng-container matColumnDef="dateStart">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Inicio Viagem</th>
                        <td mat-cell *matCellDef="let element">{{ element.formatted_analyze_start_date }}</td>
                    </ng-container>
                    <ng-container matColumnDef="dateEnd">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Fim Viagem</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.formatted_analyze_end_date }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="plate" style="padding: 10px;">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Placa</th>
                        <td mat-cell *matCellDef="let element">{{ element.plate }}</td>
                    </ng-container>
                    <ng-container matColumnDef="operationalBase">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Base Oper.</th>
                        <td mat-cell *matCellDef="let element">{{ element.name_base }}</td>
                    </ng-container>
                    <ng-container matColumnDef="companyDestiny">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Destino Empresa</th>
                        <td mat-cell *matCellDef="let element">{{ element.name_company != null ? element.name_company :
                            element.name_division }}</td>
                    </ng-container>
                    <ng-container matColumnDef="driverName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Motorista</th>
                        <td mat-cell *matCellDef="let element">{{ element.name_driver ? element.name_driver :
                            element.name_driver_manual }}</td>
                    </ng-container>
                    <ng-container matColumnDef="OperadorName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Operador</th>
                        <td mat-cell *matCellDef="let element">{{ element.name_operator }}</td>
                    </ng-container>

                    <ng-container matColumnDef="packageNumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Núm. Pacote</th>
                        <td mat-cell *matCellDef="let element"> {{ element.package_number }} </td>
                    </ng-container>

                    <ng-container matColumnDef="filePdfAnalytical"
                        *ngIf="this.permissionService.hasPermission('ANALYZE_MINIO_FUNCIONALIDADE', this.permissions)">
                        <th mat-header-cell *matHeaderCellDef>Ref. Anali</th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.minio_analytic_pdf" class="alerta mr-2" data-title="Arquivo PDF"
                                matTooltip="Arquivo PDF">
                                <a (click)="exportReport(element.minio_analytic_pdf)" class="btn btn-link">
                                    <img height="20" src="assets/img/file-pdf.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="filePdfSynthetic"
                        *ngIf="this.permissionService.hasPermission('ANALYZE_MINIO_FUNCIONALIDADE', this.permissions)">
                        <th mat-header-cell *matHeaderCellDef>Ref. Sint</th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.minio_synthetic_pdf" class="alerta mr-2" data-title="Arquivo PDF"
                                matTooltip="Arquivo PDF">
                                <a (click)="exportReport(element.minio_synthetic_pdf)" class="btn btn-link">
                                    <img height="20" src="assets/img/file-pdf.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="operatorHandling">
                        <th mat-header-cell *matHeaderCellDef> Trata. Ope</th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Tratativa operador" matTooltip="Tratativa operador">
                                <a (click)="openToTreatment(element.uuid)" class="btn btn-link">
                                    <img height="20" src="assets/img/operator-handling.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="managerName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Gestor</th>
                        <td mat-cell *matCellDef="let element">{{ element.name_manager }}</td>
                    </ng-container>
                    <ng-container matColumnDef="supervisorName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Supervisor</th>
                        <td mat-cell *matCellDef="let element">{{ element.name_supervisor }}</td>
                    </ng-container>
                    <ng-container matColumnDef="treatManagerName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tratativa Ges.</th>
                        <td mat-cell *matCellDef="let element">{{ element.treat_name_manager }}</td>
                    </ng-container>
                    <ng-container matColumnDef="descriptionManager">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Descrição da Tratativa</th>
                        <td mat-cell *matCellDef="let element">{{ element.description_manager }}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
                </table>

                <mat-paginator *ngIf="analyzeReportHistoricPage && analyzeReportHistoricPage.page"
                    [pageSizeOptions]="[10, 20, 40]" [pageSize]="analyzeReportHistoricPage.page.size"
                    (page)="onChangePage($event)" [length]="analyzeReportHistoricPage.page.totalElements"
                    showFirstLastButtons>
                </mat-paginator>
            </div>
        </div>
    </div>
</div>