<div class="main-page cadastros journey-main">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-12 my-2">
                <h1>Relatório Operacional do Veículo</h1>
            </div>
        </div>
        <div style="margin-bottom: 45px;">
            <p-menubar #menu [model]="items"></p-menubar>
        </div>
        <mat-horizontal-stepper [linear]="true" #stepper>
            <mat-step>
                <!-- COMECA -->
                <p-table #journeyheader [columns]="this.colsHeader" [value]="this.resultado" tableStyleClass="table">
                    <ng-template pTemplate="body" let-rowdata let-expanded="expanded">
                        <tr>
                            <td style="text-align:center !important;border:none" colspan=13 align="center" valign=middle
                                bgcolor="#999999" [pRowToggler]="rowdata"><strong>Empresa:</strong>
                                {{rowdata["empresaNome"]}}</td>
                        </tr>
                        <tr>
                            <td style="text-align:center !important;border:none" colspan=13 align="center" valign=middle
                                bgcolor="#999999" [pRowToggler]="rowdata"><strong>Placa:</strong> {{rowdata["placa"]}}
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align:center !important;border:none" colspan=13 align="center" valign=middle
                                bgcolor="#999999" [pRowToggler]="rowdata"><strong>Frota:</strong>
                                {{rowdata["frotaNome"]}}</td>
                        </tr>
                        <tr>
                            <td style="text-align:center !important;border:none" colspan=13 align="center" valign=middle
                                bgcolor="#999999" [pRowToggler]="rowdata">{{rowdata["dataTexto"]}}</td>
                        </tr>
                        <tr>
                            <td style="text-align:center !important;" height="44" align="center" valign=middle><b>Km
                                    Total</b></td>
                            <td style="text-align:center !important;" align="center" valign=middle><b>Consumo<br>Total
                                    (L)</b></td>
                            <td style="text-align:center !important;" align="center" valign=middle>
                                <b>Média<br>(Km/L)</b>
                            </td>
                            <td style="text-align:center !important;" align="center" valign=middle>
                                <b>Consumo<br>Movimento (L)</b>
                            </td>
                            <td style="text-align:center !important;" align="center" valign=middle><b>Consumo<br>Parado
                                    (L)</b></td>
                            <td style="text-align:center !important;" align="center" valign=middle>
                                <b>Furto<br>Combustível</b>
                            </td>
                            <td style="text-align:center !important;" align="center" valign=middle>
                                <b>Ignição<br>Ociosa</b>
                            </td>
                            <td style="text-align:center !important;" align="center" valign=middle><b>Veloc<br>Média
                                    (Km/h)</b></td>
                            <td style="text-align:center !important;" align="center" valign=middle><b>Impacto</b></td>
                            <td style="text-align:center !important;" align="center" valign=middle>
                                <b>Pressão<br>Pneu</b>
                            </td>
                            <td style="text-align:center !important;" align="center" valign=middle><b>Bateria
                                    TPMS<br>Baixa</b></td>
                            <td style="text-align:center !important;" align="center" valign=middle><b>Temp.
                                    Irregular<br>Pneu</b></td>
                            <td style="text-align:center !important;" align="center" valign=middle>
                                <b>Frenagem<br>Brusca</b>
                            </td>


                        </tr>
                        <tr>
                            <td style="text-align:center !important;border-block-end: 3px solid #999999;" height="18"
                                align="center" valign=middle sdval="2" sdnum="1046;">{{rowdata["odometroTotal"]}}</td>
                            <td style="text-align:center !important; border-block-end: 3px solid #999999;"
                                align="center" valign=middle sdval="2" sdnum="1046;">
                                {{rowdata["combustivelConsumidoTotal"]}}</td>
                            <td style="text-align:center !important; border-block-end: 3px solid #999999;"
                                align="center" valign=middle sdval="2" sdnum="1046;">{{rowdata["consumoCombustivel"]}}
                            </td>
                            <td style="text-align:center !important; border-block-end: 3px solid #999999;"
                                align="center" valign=middle sdval="2" sdnum="1046;">
                                {{rowdata["combustivelConsumidoMovimento"]}}</td>
                            <td style="text-align:center !important; border-block-end: 3px solid #999999;"
                                align="center" valign=middle sdval="2" sdnum="1046;">
                                {{rowdata["combustivelConsumidoParado"]}}</td>
                            <td style="text-align:center !important; border-block-end: 3px solid #999999;"
                                align="center" valign=middle sdval="2" sdnum="1046;">{{rowdata["furtoCombustivel"]}}
                            </td>
                            <td style="text-align:center !important; border-block-end: 3px solid #999999;"
                                align="center" valign=middle sdval="2" sdnum="1046;">{{rowdata["ignicaoOciosa"]}}</td>
                            <td style="text-align:center !important; border-block-end: 3px solid #999999;"
                                align="center" valign=middle sdval="2" sdnum="1046;">{{rowdata["velocidadeMedia"]}}</td>
                            <td style="text-align:center !important; border-block-end: 3px solid #999999;"
                                align="center" valign=middle sdval="2" sdnum="1046;">{{rowdata["colisao"]}}</td>
                            <td style="text-align:center !important; border-block-end: 3px solid #999999;"
                                align="center" valign=middle sdval="2" sdnum="1046;">{{rowdata["pressaoPneu"]}}</td>
                            <td style="text-align:center !important; border-block-end: 3px solid #999999;"
                                align="center" valign=middle sdval="2" sdnum="1046;">
                                {{rowdata["bateriaSensorTpmsBaixa"]}}</td>
                            <td style="text-align:center !important; border-block-end: 3px solid #999999;"
                                align="center" valign=middle sdval="2" sdnum="1046;">
                                {{rowdata["temperaturaIrregularPneu"]}}</td>
                            <td style="text-align:center !important; border-block-end: 3px solid #999999;"
                                align="center" valign=middle sdval="2" sdnum="1046;">{{rowdata["frenagemBrusca"]}}</td>


                        </tr>
                        <tr>
                            <td style="text-align:center !important;" height="44" align="center" valign=middle>
                                <b>Horímetro Total</b>
                            </td>
                            <td style="text-align:center !important;" align="center" valign=middle><b>Horímetro
                                    Movimento</b></td>
                            <td style="text-align:center !important;" align="center" valign=middle><b>Horímetro
                                    Parado</b></td>
                            <td style="text-align:center !important;" align="center" valign=middle>
                                <b>Vel Máxima</b>
                            </td>
                            <td style="text-align:center !important;" align="center" valign=middle><b>Tempo
                                    Excesso Vel</b></td>
                            <td style="text-align:center !important;" align="center" valign=middle>
                                <b>Tempo Excesso RPM</b>
                            </td>
                            <td style="text-align:center !important;" colspan=2 align="center" valign=middle>
                                <b>RPM<br>Máximo</b>
                            </td>
                            <td style="text-align:center !important;" align="center" valign=middle>
                                <b>Curva<br>Agressiva</b>
                            </td>
                            <td style="text-align:center !important;" align="center" valign=middle><b>Excesso<br>de
                                    RPM</b></td>
                            <td style="text-align:center !important;" align="center" valign=middle>
                                <b>Excesso<br>Turbo</b>
                            </td>
                            <td style="text-align:center !important;" align="center" valign=middle>
                                <b>Excesso<br>Noite</b>
                            </td>
                            <td style="text-align:center !important;" height="44" align="center" valign=middle>
                                <b>Arrancada<br>Brusca</b>
                            </td>
                        </tr>
                        <tr style="font-size: 5px;">
                            <td style="text-align:center !important; border-block-end: 3px solid #999999;" height="18"
                                align="center" valign=middle sdval="2" sdnum="1046;">{{rowdata["horimetroTotalTexto"]}}
                            </td>
                            <td style="text-align:center !important; border-block-end: 3px solid #999999;"
                                align="center" valign=middle sdval="2" sdnum="1046;">
                                {{rowdata["horimetroMovimentoTexto"]}}</td>
                            <td style="text-align:center !important; border-block-end: 3px solid #999999;"
                                align="center" valign=middle sdval="2" sdnum="1046;">{{rowdata["horimetroParadoTexto"]}}
                            </td>
                            <td style="text-align:center !important; border-block-end: 3px solid #999999;"
                                align="center" valign=middle sdval="2" sdnum="1046;">{{rowdata["velocidadeMaxima"]}}
                            </td>
                            <td style="text-align:center !important; border-block-end: 3px solid #999999;"
                                align="center" valign=middle sdval="2" sdnum="1046;">
                                {{rowdata["tempoExcessoVeloTexto"]}}</td>
                            <td style="text-align:center !important; border-block-end: 3px solid #999999;"
                                align="center" valign=middle sdval="2" sdnum="1046;">{{rowdata["tempoExcessoRpmTexto"]}}
                            </td>
                            <td style="text-align:center !important; border-block-end: 3px solid #999999;" colspan=2
                                align="center" valign=middle sdval="99,99" sdnum="1046;">{{rowdata["rpmMaximo"]}}</td>
                            <td style="text-align:center !important; border-block-end: 3px solid #999999;"
                                align="center" valign=middle sdval="2" sdnum="1046;">{{rowdata["curvaBrusca"]}}</td>
                            <td style="text-align:center !important; border-block-end: 3px solid #999999;"
                                align="center" valign=middle sdval="2" sdnum="1046;">{{rowdata["rpmExcesso"]}}</td>
                            <td style="text-align:center !important; border-block-end: 3px solid #999999;"
                                align="center" valign=middle sdval="2" sdnum="1046;">{{rowdata["tempoExcessoTurbo"]}}
                            </td>
                            <td style="text-align:center !important; border-block-end: 3px solid #999999;"
                                align="center" valign=middle sdval="2" sdnum="1046;">
                                {{rowdata["velocidadeExcessoNoite"]}}</td>
                            <td style="text-align:center !important; border-block-end: 3px solid #999999;" height="18"
                                align="center" valign=middle sdval="2" sdnum="1046;">{{rowdata["aceleracaoBrusca"]}}
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align:center !important;" colspan=3 height="44" align="center"
                                valign=middle><b>Perfil de RPM</b></td>
                            <td style="text-align:center !important;" align="center" valign=middle>
                                <b>Bateria<br>Desconectada</b>
                            </td>
                            <td style="text-align:center !important;" align="center" valign=middle>
                                <b>Bateria<br>Anormal</b>
                            </td>
                            <td style="text-align:center !important;" align="center" valign=middle><b>Temp Máx<br>Motor
                                    (ºC)</b></td>
                            <td style="text-align:center !important;" align="center" valign=middle><b>Tanque<br>Mínimo
                                    (%)</b></td>
                            <td style="text-align:center !important;" align="center" valign=middle><b>Abastecimento</b>
                            </td>
                            <td style="text-align:center !important;" colspan=2 align="center" valign=middle>
                                <b>Superaquecimento<br>Motor</b>
                            </td>
                            <td style="text-align:center !important;" align="center" valign=middle>
                                <b>Total<br>Frenagens</b>
                            </td>
                            <td style="text-align:center !important;" align="center" valign=middle>
                                <b>Excesso<br>Veloc</b>
                            </td>
                            <td style="text-align:center !important;" align="center" valign=middle>
                                <b>Excesso<br>Chuva</b>
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align:center !important;" height="18" align="center" valign=middle
                                bgcolor="#069A2E" sdval="0">{{rowdata["tempoRpmVerdeTexto"]}}</td>
                            <td style="text-align:center !important;" align="center" valign=middle bgcolor="#FFFF38"
                                sdval="0">{{rowdata["tempoRpmAmareloTexto"]}}</td>
                            <td style="text-align:center !important;" align="center" valign=middle bgcolor="#FF0000"
                                sdval="0">{{rowdata["tempoRpmVermelhoTexto"]}}</td>
                            <td style="text-align:center !important;" align="center" valign=middle sdval="0"
                                sdnum="1046;0;HH:MM:SS">{{rowdata["bateriaDesconectada"]}}</td>
                            <td style="text-align:center !important;" align="center" valign=middle sdval="99,99"
                                sdnum="1046;">{{rowdata["bateriaAnormalVeiculo"]}}</td>
                            <td style="text-align:center !important;" align="center" valign=middle sdval="99,99"
                                sdnum="1046;">{{rowdata["temperaturaMaximaMotor"]}}</td>
                            <td style="text-align:center !important;" align="center" valign=middle sdval="2"
                                sdnum="1046;">{{rowdata["tanqueMinimoPerc"]}}</td>
                            <td style="text-align:center !important;" align="center" valign=middle sdval="2"
                                sdnum="1046;">{{rowdata["abastecimento"]}}</td>
                            <td style="text-align:center !important;" colspan=2 align="center" valign=middle sdval="2"
                                sdnum="1046;">{{rowdata["superAquecimentoMotor"]}}</td>
                            <td style="text-align:center !important;" align="center" valign=middle sdval="999,99"
                                sdnum="1046;">{{rowdata["freioAcionado"]}}</td>
                            <td style="text-align:center !important;" align="center" valign=middle sdval="2"
                                sdnum="1046;">{{rowdata["velocidade"]}}</td>
                            <td style="text-align:center !important;" align="center" valign=middle sdval="2"
                                sdnum="1046;">{{rowdata["velocidadeExcessoChuva"]}}</td>


                        </tr>
                        <tr>
                            <td style="background-color: #e9ecef;text-align:center !important;border:none" colspan=13
                                align="center" valign=middle height="20px"></td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- FIM-->
                <!-- COMECA -->
                <p-table #journeyheader [value]="this.resultadoAnalitico" tableStyleClass="tableAnalitico">
                    <ng-template pTemplate="header" let-columns>
                        <tr [hidden]="this.resultadoAnalitico.length == 0"
                            [ngClass]="this.filterDialog == true ? '' : 'sticky-top'" style='top:65px;'>
                            <th style="text-align:center !important;border:none">Placa</th>
                            <th style="text-align:center !important;border:none">Frota</th>
                            <th style="text-align:center !important;border:none">Motorista</th>
                            <th style="text-align:center !important;border:none">Data</th>
                            <th style="text-align:center !important;border:none">Evento</th>
                            <th style="text-align:center !important;border:none">Valor do Evento</th>
                            <th style="text-align:center !important;border:none">Mapa</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowdata>
                        <tr>
                            <td style="text-align:center !important;border:none" align="center" valign=middle
                                [pRowToggler]="rowdata">{{rowdata["placa"]}}</td>
                            <td style="text-align:center !important;border:none" align="center" valign=middle
                                [pRowToggler]="rowdata">{{rowdata["frota"]}}</td>
                            <td style="text-align:center !important;border:none" align="center" valign=middle
                                [pRowToggler]="rowdata">{{rowdata["motoristaNome"]}}</td>
                            <td style="text-align:center !important;border:none" align="center" valign=middle
                                [pRowToggler]="rowdata">{{rowdata["dataTexto"]}}</td>
                            <td style="text-align:center !important;border:none" align="center" valign=middle
                                [pRowToggler]="rowdata">{{rowdata["alarmeTexto"]}}</td>
                            <td style="text-align:center !important;border:none" align="center" valign=middle
                                [pRowToggler]="rowdata">{{rowdata["valorEvento"]}}</td>
                            <td style="text-align:center !important;border:none" align="center" valign=middle
                                [pRowToggler]="rowdata">
                                <span class="alerta mr-2" data-title="Visualizar" matTooltip="Visualizar">
                                    <a class="btn btn-link">
                                        <img (click)="this.openMap(rowdata['latitude'], rowdata['longitude'])"
                                            height="15" src="assets/img/view.svg" alt="" />
                                    </a>
                                </span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- FIM-->
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</div>

<p-sidebar [(visible)]="this.filterDialog" position="right">
    <div style="margin-bottom: 20px;">
        <span>Filtros</span>
    </div>
    <form [formGroup]="this.consultaFormGroup">
        <div class="form-row">
            <div class="form-group col-lg-12">
                <mat-form-field appearance="outline">
                    <mat-label>Data Inicío</mat-label>
                    <input formControlName="dataInicio" type="datetime-local" matInput placeholder="Inicio" required />
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-lg-12">
                <mat-form-field appearance="outline">
                    <mat-label>Data Fim</mat-label>
                    <input formControlName="dataFim" type="datetime-local" matInput placeholder="Fim" required />
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-8">
                <mat-form-field appearance="outline">
                    <mat-label>Tipo de Empresa</mat-label>
                    <mat-select formControlName="selecaoEmpresa" [(value)]="empresaFilterSelected"
                        [(ngModel)]="empresaFilterSelected" (selectionChange)="this.filterCompanyReports()">
                        <mat-option *ngFor="let type of companiesSelect" [value]="type.name">
                            {{ type.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-8">
                <mat-form-field appearance="outline">
                    <mat-label>Matriz/Divisão/Filial</mat-label>
                    <input type="text" matInput [formControl]="controlEmpresa" [matAutocomplete]="autoEmpresa" required>
                    <mat-autocomplete #autoEmpresa="matAutocomplete" [displayWith]="displayEmpresa">
                        <mat-option *ngFor="let type of this.companiesFilter" [value]="type">
                            {{type.nmNome}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-8">
                <mat-form-field appearance="outline" *ngIf="controlEmpresa.value != ''">
                    <mat-label>Placa / Frota</mat-label>
                    <input type="text" matInput [formControl]="controlVeiculo" [matAutocomplete]="autoTerminal"
                        required>
                    <mat-autocomplete #autoTerminal="matAutocomplete" [displayWith]="displayTerminal">
                        <mat-option *ngFor="let type of this.vehiclesFiltered | async" [value]="type">
                            {{type.cdPlaca}}/{{type.frota}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-8">
                <mat-form-field appearance="outline">
                    <mat-label>Motorista</mat-label>
                    <input type="text" matInput [formControl]="controlMotorista" [matAutocomplete]="autoMotorista">
                    <mat-autocomplete #autoMotorista="matAutocomplete" [displayWith]="displayMotorista">
                        <mat-option *ngFor="let type of this.driversFiltered| async" [value]="type">
                            {{type.nmNome}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-12">
                <mat-slide-toggle name="saltoGps" formControlName="saltoGps" color="primary">
                    Ignorar Saltos GPS</mat-slide-toggle>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-8">
                <mat-form-field appearance="outline">
                    <mat-label>Tipo:</mat-label>
                    <select matNativeControl formControlName="tipoRelatorio">
                        <option value=1 selected>
                            Sintético
                        </option>
                        <option value=2>
                            Analítico
                        </option>
                    </select>
                </mat-form-field>
            </div>
        </div>


        <div class="form-row">
            <div class="form-group col-md-8">
                <!-- [disabled]="!this.lastAnswers[this.lastAnswers.length -1].msg && !this.finalizar" class="ml-1" mat-flat-button color="primary" (click)="onSave()" -->
                <button type="submit" (click)="onConsultar()">
                    Consultar
                </button>
            </div>
        </div>


    </form>

</p-sidebar>