import { Injectable } from "@angular/core";
import { HateoasBaseService } from "./hateoas-base.service";
import { HttpUtil } from "../utils/http-util";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { PageEvent } from "@angular/material/paginator";
import { ReportTypeEngineModelPaginated } from "../models/report-type-engine-model-paginated";

import { Observable } from "rxjs";
import { ReportEngineModel } from "../models/report-engine-model";
import { ReportReprocessList } from "../models/report-reprocess-list";
import { toSnake } from "snake-camel";
import { ParametersTypeModel } from "../models/parameters-type-model";
import { ReportingRuleModel } from "../models/reporting-rule-model";

@Injectable({
  providedIn: "root",
})
export class ReportEngineService extends HateoasBaseService {
  // tslint:disable-next-line:variable-name
  private _httpUtil: HttpUtil;
  private resourceNameReportType = "report-type";
  private resourceNameParametersType = "parameters-type";
  private resourceNameParameters = "parameters";
  private resourceNameReportingRule = "reporting-rule";
  private resourceNameReport = "report";

  constructor(http: HttpClient, httpUtil: HttpUtil) {
    super(http, httpUtil);
    this._httpUtil = httpUtil;
  }

  public getAllReportType(): Observable<ReportTypeEngineModelPaginated> {
    //TODO - Deixar essa paginação mais dinamica
    const pageEvent = new PageEvent();
    pageEvent.pageIndex = 0;
    pageEvent.pageSize = 50;

    const direction = "ASC";
    const params = this._httpUtil.buildPaginator(pageEvent, direction);

    return this.executeActionOnResource(
      this.resourceNameReportType,
      "get-all",
      null,
      null,
      params
    );
  }

  public getAllReportMonthly(
    page: PageEvent,
    name: string,
    field: string
  ): Observable<any> {
    //TODO - Deixar essa paginação mais dinamica
    const direction = "ASC";
    const params = this._httpUtil.buildPaginator(page, direction);
    if (name !== undefined && name !== null && name.length > 0) {
      params.set("name", name);
    }

    if (field !== undefined && name !== null && field.length > 0) {
      params.set("field", field);
    }


    return this.executeActionOnResource(
      this.resourceNameReport,
      "get-all-report-monthly",
      null,
      null,
      params
    );
  }

  public getAllReports(
    page: PageEvent,
    name: string,
    field: string
  ): Observable<any> {
    //TODO - Deixar essa paginação mais dinamica
    const direction = "ASC";
    const params = this._httpUtil.buildPaginator(page, direction);
    if (name !== undefined && name !== null && name.length > 0) {
      params.set("name", name);
    }

    if (field !== undefined && name !== null && field.length > 0) {
      params.set("field", field);
    }


    return this.executeActionOnResource(
      this.resourceNameReport,
      "get-all-report",
      null,
      null,
      params
    );
  }

  public getAllReport(
    page: PageEvent,
    requestOperator: string,
    name: string
  ): Observable<any> {
    //TODO - Deixar essa paginação mais dinamica
    const direction = "ASC";
    const params = this._httpUtil.buildPaginator(page, direction);
    if (name !== undefined && name !== null && name.length > 0) {
      params.set("name", name);
    }

    params.set("requestOperator", requestOperator);

    return this.executeActionOnResource(
      this.resourceNameReport,
      "get-all-reports",
      null,
      null,
      params
    );
  }

  public getMultiplesFiles(uuid: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameReport,
      "get-multiples-files",
      null,
      null,
      params
    );
  }

  public getDetailReport(uuid: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameReport,
      "get-detail-report",
      null,
      null,
      params
    );
  }

  public getMinioJsonTrip(uuid: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuidMinio", uuid);
    return this.executeActionOnResource(
      this.resourceNameReport,
      "get-file-minio-json-trip",
      null,
      null,
      params
    );
  }

  public getByUuidReport(uuid: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameReport,
      "get-by-uuid",
      null,
      null,
      params
    );
  }

  public updateReport(
    reportEnquine: ReportEngineModel,
    uuid: string
  ): Observable<any> {
    const reportEnquineSnake = toSnake(reportEnquine);
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameReport,
      "process-report-update",
      null,
      reportEnquineSnake,
      params
    );
  }

  public reprocessReport(
    request: ReportReprocessList,
    uuid: string
  ): Observable<any> {
    const requestSnake = toSnake(request);
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameReport,
      "run-process-report",
      null,
      requestSnake,
      params
    );
  }

  public saveReport(reportEnquine: ReportEngineModel): Observable<any> {
    let request = toSnake(reportEnquine);
    return this.executeActionOnResource(
      this.resourceNameReport,
      "save",
      null,
      request,
      null
    );
  }

  public saveReportingRule(reportingRuleModel: ReportingRuleModel): Observable<any> {
    let request = toSnake(reportingRuleModel);
    return this.executeActionOnResource(
      this.resourceNameReportingRule,
      "save",
      null,
      request,
      null
    );
  }

  public getAllReportingRule(
    page: PageEvent,
    name: string,
    field: string
  ): Observable<any> {
    //TODO - Deixar essa paginação mais dinamica
    const direction = "ASC";
    const params = this._httpUtil.buildPaginator(page, direction);
    if (name !== undefined && name !== null && name.length > 0) {
      params.set("name", name);
    }

    if (field !== undefined && name !== null && field.length > 0) {
      params.set("field", field);
    }

    return this.executeActionOnResource(
      this.resourceNameReportingRule,
      "get-all-reporting-rule",
      null,
      null,
      params
    );
  }

  public deleteReportingRule(uuid: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameReportingRule,
      "delete-by-uuid",
      null,
      null,
      params
    );
  }

  public getByUuidReportingRule(uuid: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameReportingRule,
      "get-reporting-by-uuid",
      null,
      null,
      params
    );
  }

  public updateReportingRule(
    parametersType: ReportingRuleModel,
    uuid: string
  ): Observable<any> {
    const parametersTypeSnake = toSnake(parametersType);
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameReportingRule,
      "update",
      null,
      parametersTypeSnake,
      params
    );
  }

  public saveParametersType(parametersType: ParametersTypeModel): Observable<any> {
    let request = toSnake(parametersType);
    return this.executeActionOnResource(
      this.resourceNameParametersType,
      "save",
      null,
      request,
      null
    );
  }

  public getAllParameterstype(
    page: PageEvent,
    name: string,
    field: string
  ): Observable<any> {
    //TODO - Deixar essa paginação mais dinamica
    const direction = "ASC";
    const params = this._httpUtil.buildPaginator(page, direction);
    if (name !== undefined && name !== null && name.length > 0) {
      params.set("name", name);
    }

    if (field !== undefined && name !== null && field.length > 0) {
      params.set("field", field);
    }

    return this.executeActionOnResource(
      this.resourceNameParametersType,
      "get-all-parameters-type",
      null,
      null,
      params
    );
  }

  public deleteParametersType(uuid: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameParametersType,
      "delete-by-uuid",
      null,
      null,
      params
    );
  }

  public updateParametersType(
    parametersType: ParametersTypeModel,
    uuid: string
  ): Observable<any> {
    const parametersTypeSnake = toSnake(parametersType);
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameParametersType,
      "update",
      null,
      parametersTypeSnake,
      params
    );
  }

  public createReport(reportEnquine: ReportEngineModel): Observable<any> {
    let request = toSnake(reportEnquine);
    return this.executeActionOnResource(
      this.resourceNameReport,
      "process-report",
      null,
      request,
      null
    );
  }

  public deleteReport(uuid: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameReport,
      "delete-by-uuid",
      null,
      null,
      params
    );
  }

  public exportReport(uuidMinio: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuidMinio", uuidMinio);
    return this.executeActionOnResource(
      this.resourceNameReport,
      "get-file-minio",
      null,
      null,
      params
    );
  }

  public exportJsonReport(uuidMinio: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuidMinio", uuidMinio);
    return this.executeActionOnResource(
      this.resourceNameReport,
      "get-file-minio-json",
      null,
      null,
      params
    );
  }

  public getByOperationValid(): Observable<any> {
    return this.executeActionOnResource(
      this.resourceNameParametersType,
      "get-by-operation-valid",
      null,
      null,
      null
    );
  }

  public getByFieldValid(): Observable<any> {
    return this.executeActionOnResource(
      this.resourceNameParametersType,
      "get-by-field-valid",
      null,
      null,
      null
    );
  }

  public getByModelVehicles(): Observable<any> {
    return this.executeActionOnResource(
      this.resourceNameParametersType,
      "get-by-model-vehicles",
      null,
      null,
      null
    );
  }

  public getByParameters(): Observable<any> {
    return this.executeActionOnResource(
      this.resourceNameParametersType,
      "get-by-parameters",
      null,
      null,
      null
    );
  }

  public getByReportTypes(): Observable<any> {
    return this.executeActionOnResource(
      this.resourceNameReportType,
      "get-by-report-types",
      null,
      null,
      null
    );
  }

  public getByTerminals(): Observable<any> {
    return this.executeActionOnResource(
      this.resourceNameReport,
      "get-by-terminals",
      null,
      null,
      null
    );
  }

  public getByUuidParametersField(uuid: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameParameters,
      "get-by-parameters",
      null,
      null,
      params
    );
  }

  public getByUuidParametersType(uuid: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameParametersType,
      "get-parameters-by-uuid",
      null,
      null,
      params
    );
  }


  protected getApiBaseUrl(): string {
    return environment.apisConfig.seteraReportEngine.apiBaseUrl;
  }

  protected getMicroServiceBaseUrl(): string {
    return environment.apisConfig.seteraReportEngine.microServiceBaseUrl;
  }
}
