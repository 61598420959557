import { MatStepper } from "@angular/material/stepper";
import { STORAGE_KEY_ID_PERFIL } from "./../../storageCore/constStorageKeys";
import { ToolsService } from "./../../services/migrate/tools.service";
import { Component, OnInit, AfterViewInit, Input } from "@angular/core";
import * as FileSaver from "file-saver";
import storageX from "../../storageCore/storageX";
import { Page } from "../../utils/paginate";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatAutocomplete } from "@angular/material/autocomplete";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CoreService } from "../../services/migrate/api.core.service";
import ApiService from "../../services/api.service";
import { Empresa } from "../../models/markerModel";
import { Params } from "../../services/migrate/params";
import { USER_DATA } from "../../utils/constants.util";
import { PermissionsService } from "../../helpers/permissions.service";
import { MatDialog } from "@angular/material/dialog";
import { DriverReportMapComponent } from "../driver-report/driver-report-map.component";
import { Console } from "console";


@Component({
  selector: 'app-ignition-report',
  templateUrl: './ignition-report.component.html',
  styleUrls: ['./ignition-report.component.scss']
})
export class IgnitionReportComponent implements OnInit {
  public controlEmpresa = new FormControl();
  public controlVeiculo = new FormControl();
  public controlMotorista = new FormControl();

  public colsHeader = new Array<any>();
  public companys = new Array<any>();
  public companysFiltered = new Observable<Array<any>>();
  public vehicles = new Array<any>();
  public vehiclesFiltered = new Observable<Array<any>>();
  public drivers = new Array<any>();
  public driversFiltered = new Observable<Array<any>>();

  private profileId = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get();
  public paramsLoad = {
    profileId: this.profileId,
    size: 9999,
    page: 0,
    sort: "nome",
    sortDirection: "ASC",
  };

  public consultaFormGroup: FormGroup;
  permissions: [];

  public serviceCore: CoreService;
  public serviceApi: ApiService;

  public pagination = new Page();
  public matStepper: MatStepper;
  public matAutocomplete: MatAutocomplete;
  public filterDialog = true;
  public items;

  public resultado = new Array<any>();
  public resultadoAnalitico = new Array<any>();
  public resultadoTotal = new Array<any>();

  public empresaFilterSelected;
  public companiesFilter: any = [];

  public companiesSelect: any = [];

  public tipoRelatorioSelecionado: string = '';

  constructor(
    private tools: ToolsService,
    apiserviceCore: CoreService,
    serviceApi: ApiService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public permissionService: PermissionsService,
  ) {
    this.serviceCore = apiserviceCore;
    this.serviceApi = serviceApi;
  }

  ngAfterViewInit(): void {
    this.filterDialog = true;
  }

  ngOnInit() {
    this.permissions = this.permissionService.getPermissions();
    this.pagination.currentPage = 0;
    this.pagination.size = 10;

    this.tools.builder().spinner.show();
    this.loadMenuItems();
    this.loadDataElements();

    this.consultaFormGroup = this.formBuilder.group({
      selecaoEmpresa: [""],
      dataInicio: ["", Validators.required],
      dataFim: ["", Validators.required],
      tipoRelatorio: ["", Validators.required],
    });

    this.companysFiltered = this.controlEmpresa.valueChanges.pipe(
      map((value) => (typeof value === "string" ? value : value.id)),
      map((name) =>
        name ? this.filterValuesEmpresa(name) : this.companys.slice()
      )
    );

    this.vehiclesFiltered = this.controlVeiculo.valueChanges.pipe(
      map((value) => (typeof value === "string" ? value : value.cdPlaca)),
      map((name) =>
        name ? this.filterValuesTerminal(name) : this.vehicles.slice()
      )
    );

    this.driversFiltered = this.controlMotorista.valueChanges.pipe(
      map((value) => (typeof value === "string" ? value : value.nmNome)),
      map((name) =>
        name ? this.filterValuesMotorista(name) : this.drivers.slice()
      )
    );

    this.controlMotorista.disable();
    this.controlVeiculo.disable();
  }

  onTipoRelatorioChange() {
    this.tipoRelatorioSelecionado = this.consultaFormGroup.get('tipoRelatorio')?.value;

    if (this.tipoRelatorioSelecionado === 'DRIVER') {
      // 🔹 Habilita motorista e reseta veículo
      this.controlMotorista.setValidators([Validators.required]);
      this.controlMotorista.enable();
      this.controlMotorista.setValue(null); // ✅ Zera o input do motorista

      this.controlVeiculo.clearValidators();
      this.controlVeiculo.disable();
      this.controlVeiculo.setValue(null); // ✅ Zera o input do veículo
      this.controlVeiculo.setValue(''); // 🔹 Força a atualização do campo
      this.controlVeiculo.markAsTouched(); // 🔹 Garante que o Angular detecte a mudança
    } else if (this.tipoRelatorioSelecionado === 'VEHICLE') {
      // 🔹 Habilita veículo e reseta motorista
      this.controlVeiculo.setValidators([Validators.required]);
      this.controlVeiculo.enable();
      this.controlVeiculo.setValue(null); // ✅ Zera o input do veículo
      this.controlVeiculo.setValue(''); // 🔹 Força a atualização do campo
      this.controlVeiculo.markAsTouched(); // 🔹 Garante que o Angular detecte a mudança

      this.controlMotorista.clearValidators();
      this.controlMotorista.disable();
      this.controlMotorista.setValue(null); // ✅ Zera o input do motorista
      this.controlMotorista.setValue(''); // 🔹 Força a atualização do campo
      this.controlMotorista.markAsTouched(); // 🔹 Garante que o Angular detecte a mudança
    }

    // 🔹 Atualiza validações dos formulários
    this.controlMotorista.updateValueAndValidity();
    this.controlVeiculo.updateValueAndValidity();
  }


  public filterValuesEmpresa(value: any): Array<any> {
    const filterValue = value;

    if (this.controlEmpresa.value.cnpj != null) {
      if (value != null) {
        this.loadFilterCompany(value);
      }
    } else {
      if (value != null) {
        this.loadFilterDivision(value);
      }
    }
    return this.companys
      .sort((a, b) => a.nmNome.localeCompare(b.nmNome))
      .filter((client) => client.nmNome.toLowerCase().includes(filterValue));
  }

  displayEmpresa(empresa: Empresa): string {
    return empresa && empresa.nmNome ? empresa.nmNome : "";
  }

  public filterValuesTerminal(value: string): Array<any> {
    const filterValue = value.toLowerCase();
    return this.vehicles
      .sort((a, b) => a.cdPlaca.localeCompare(b.cdPlaca))
      .filter((client) => client.cdPlaca.toLowerCase().includes(filterValue));
  }

  displayTerminal(terminal: any): string {
    var terminalPlaca = terminal.cdPlaca ? terminal.cdPlaca : "";
    var terminalFrota = terminal.frota ? terminal.frota : "";

    return terminal && terminal.cdPlaca
      ? terminalPlaca + "/" + terminalFrota
      : "";
  }

  public filterValuesMotorista(value: string): Array<any> {
    console.log("filterValuesMotorista");
    const filterValue = value.toLowerCase();
    return this.drivers
      .sort((a, b) => a.nmNome.localeCompare(b.nmNome))
      .filter((client) => client.nmNome.toLowerCase().includes(filterValue));
  }

  displayMotorista(motorista: any): string {
    return motorista && motorista.nmNome ? motorista.nmNome : "";
  }

  public paginate(event) {
    const params = this.tools.builder().params;

    this.pagination.currentPage = event.page;
    this.pagination.size = event.rows;
  }

  public onOpenDialogFilter() {
    this.filterDialog = true;
  }

  private loadMenuItems() {
    this.items = [
      {
        label: "Filtrar",
        icon: "pi pi-filter",
        command: () => {
          this.onOpenDialogFilter();
        },
      },
      {
        label: "Exportar",
        items: [
          {
            label: "Excel",
            icon: "pi pi-file-excel",
            command: () => {
              this.exportExcel();
            },
          },
          {
            label: "PDF",
            icon: "pi pi-file-pdf",
            command: () => {
              this.exportPdf();
            },
          },
        ],
      },
    ];
  }

  exportPdf() {
    // Função para carregar a imagem e converter para base64
    const loadImageAsBase64 = (url) => {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
      });
    };

    // Função para adicionar o cabeçalho ao PDF
    const addHeader = (doc, imgData, data) => {
      const pageWidth = doc.internal.pageSize.width;

      // Dimensões da imagem
      const imgWidth = 40;
      const imgHeight = 40;

      const marginLeft = 200;
      const marginTop = 10;

      // Adicionando a imagem
      doc.addImage(imgData, "PNG", marginLeft, marginTop, imgWidth, imgHeight);

      var text = "";

      if (this.resultadoAnalitico.length > 0) {
        text = "SETERA - RELATÓRIO ANALÍTICO DE TEMPO E MOVIMENTO";
      }

      const textX = marginLeft + imgWidth + 10;
      const textY = marginTop + imgHeight / 2 + 5;

      doc.setFontSize(18);
      doc.setTextColor("#0000FF");
      doc.setFont("helvetica", "bold");
      doc.text(text, textX, textY);
    };

    if (this.resultadoAnalitico.length > 0) {
      import("jspdf").then((jsPDF) => {
        import("jspdf-autotable").then(() => {
          const doc = new jsPDF.default("l", "pt");

          loadImageAsBase64("assets/img/logo-aba.png")
            .then((imgData) => {
              const header = (data) => {
                addHeader(doc, imgData, data);
              };

              doc.autoTable({
                startY: 60,
                html: ".tableAnalitico",
                useCss: true,
                didDrawPage: header,
                margin: { top: 60 },
              });

              addHeader(doc, imgData, { settings: { margin: { left: 40 } } });

              doc.save(`relatorio_ignicao.pdf`);
            })
            .catch((error) => {
              console.error("Erro ao carregar a imagem:", error);
            });
        });
      });
    }
  }

  exportExcel() {
    if (this.resultadoAnalitico.length > 0) {
      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.table_to_sheet(
          document.getElementsByClassName("tableAnalitico")[0],
          { dateNF: "mm/dd/yyyy hh:mm:ss", cellDates: true }
        );
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        this.saveAsExcelFile(excelBuffer, `relatorio_posicoes`);
      });
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      `relatorio_ignicao` + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  public openMap(lat, long) {
    console.log("openMap");
    const dialogRef = this.dialog.open(DriverReportMapComponent, {
      width: "450px",
      height: "450px",
      data: {
        lat: lat,
        lng: long,
      },
    });
  }

  public onConsultar() {
    if (
      this.consultaFormGroup.valid &&
      ((this.tipoRelatorioSelecionado === "VEHICLE" && this.controlVeiculo.value != null && this.controlVeiculo.value != "") ||
        (this.tipoRelatorioSelecionado === "DRIVER" && this.controlMotorista.value != null && this.controlMotorista.value != ""))
    ) {
      const params = new Params();

      console.log("CHAMEI")

      if (
        this.controlEmpresa.value != null &&
        this.controlEmpresa.value != ""
      ) {
        params.setParam("idEmpresa", this.controlEmpresa.value.id);
      }
      if (
        this.controlMotorista.value != null &&
        this.controlMotorista.value != ""
      ) {
        params.setParam("idMotorista", this.controlMotorista.value.id);
      }
      if (
        this.controlVeiculo.value != null &&
        this.controlVeiculo.value != ""
      ) {
        params.setParam("idTerminal", this.controlVeiculo.value.id);
      }
      params.setParam("tipoRelatorio", this.consultaFormGroup.value.tipoRelatorio);
      params.setParam(
        "startDate",
        this.consultaFormGroup.value.dataInicio + "T00:01:00"
      );
      params.setParam("endDate", this.consultaFormGroup.value.dataFim + "T23:59:00");

      this.tools.builder().spinner.show();
      this.tools
        .builder()
        .api.get(
          `${this.tools.builder().api.getApiUrl().reports}/relatorio-ignicao`,
          params.build()
        )
        .subscribe((r: any) => {

          this.resultadoAnalitico = r;
          this.resultado = new Array<any>();
          this.resultadoTotal = new Array<any>();

          this.pagination = r.pagination;
          this.tools.builder().spinner.hide();
          this.filterDialog = false;
          if (this.resultadoAnalitico.length == 0) {
            this.tools
              .builder()
              .openToast.emitInf("Nenhum resultado para exibir no momento.");
          }

        });
    }
  }

  public async loadDataElements() {
    try {
      this.tools.builder().spinner.show();

      const companys = await this.serviceCore.get<any>({
        url: "v2/companys",
        params: {
          profileId: USER_DATA().profileId,
          size: "5000",
          page: "0",
          sort: "nome",
          sortDirections: "ASC",
        },
      });

      this.companys = companys?.elements;
      this.companys = this.companys.sort((a, b) =>
        a.nmNome.localeCompare(b.nmNome)
      );
      this.controlEmpresa.setValue("");

      const terminals = await this.serviceCore.get<any>({
        url: "v2/terminals",
        params: {
          profileId: USER_DATA().profileId,
          size: "5000",
          page: "0",
          sortDirections: "ASC",
        },
      });

      this.vehicles = terminals.elements;
      this.vehicles = this.vehicles.sort((a, b) =>
        a.cdPlaca.localeCompare(b.cdPlaca)
      );
      this.controlVeiculo.setValue("");

      const drivers = await this.serviceCore.get<any>({
        url: "v2/drivers/getAll",
        params: {
          profileId: USER_DATA().profileId,
          size: "5000",
          page: "0",
          sort: "nome",
          sortDirections: "ASC",
        },
      });

      this.drivers = drivers.elements;
      this.drivers = this.drivers.sort((a, b) =>
        a.nmNome.localeCompare(b.nmNome)
      );

      this.controlMotorista.setValue("");

      this.companiesSelect = [
        { name: "Divisão", value: 1 },
        { name: "Matriz", value: 2 },
        { name: "Filial", value: 3 },
      ];

      this.tools.builder().spinner.hide();
    } catch (error) {
      this.tools.builder().spinner.hide();
      throw new EvalError(error);
    }
  }

  public async loadFilterCompany(value: any) {
    try {
      this.tools.builder().spinner.show();

      const terminals = await this.serviceCore.get<any>({
        url: "v2/terminals/findByCompany",
        params: {
          profileId: USER_DATA().profileId,
          idEmpresa: value,
          size: "5000",
          page: "0",
          sortDirections: "ASC",
        },
      });

      this.vehicles = terminals.elements;
      this.vehicles = this.vehicles.sort((a, b) =>
        a.cdPlaca.localeCompare(b.cdPlaca)
      );

      this.controlVeiculo.setValue("");

      const drivers = await this.serviceCore.get<any>({
        url: "v2/drivers/findByCompany",
        params: {
          profileId: USER_DATA().profileId,
          idEmpresa: value,
          size: "5000",
          page: "0",
          sort: "nome",
          sortDirections: "ASC",
        },
      });

      this.drivers = drivers.elements;
      this.drivers = this.drivers.sort((a, b) =>
        a.nmNome.localeCompare(b.nmNome)
      );

      this.controlMotorista.setValue("");

      this.tools.builder().spinner.hide();
    } catch (error) {
      this.tools.builder().spinner.hide();
      throw new EvalError(error);
    }
  }

  public async loadFilterDivision(value: any) {
    try {
      this.tools.builder().spinner.show();

      const terminals = await this.serviceCore.get<any>({
        url: "v2/terminals/findByDivision",
        params: {
          profileId: USER_DATA().profileId,
          idDivisao: value,
          size: "5000",
          page: "0",
          sortDirections: "ASC",
        },
      });

      console.log(terminals);

      this.vehicles = terminals.elements;
      this.vehicles = this.vehicles.sort((a, b) =>
        a.cdPlaca.localeCompare(b.cdPlaca)
      );

      this.controlVeiculo.setValue("");

      const drivers = await this.serviceCore.get<any>({
        url: "v2/drivers/findByDivision",
        params: {
          profileId: USER_DATA().profileId,
          idDivisao: value,
          size: "5000",
          page: "0",
          sort: "nome",
          sortDirections: "ASC",
        },
      });

      console.log(drivers);

      this.drivers = drivers.elements;
      this.drivers = this.drivers.sort((a, b) =>
        a.nmNome.localeCompare(b.nmNome)
      );

      this.controlMotorista.setValue("");

      this.tools.builder().spinner.hide();
    } catch (error) {
      this.tools.builder().spinner.hide();
      throw new EvalError(error);
    }
  }

  public async filterCompanyReports() {
    this.controlEmpresa.setValue("");
    var idEmpresa = this.empresaFilterSelected;
    var empresas = [];

    console.log(this.companysFiltered);

    if (idEmpresa == "Divisão") {
      this.companys.forEach((element) => {
        if (element.cnpj == null) {
          empresas.push(element);
        }
      });
    }

    if (idEmpresa == "Matriz") {
      this.companys.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz == null) {
          empresas.push(element);
        }
      });
    }

    if (idEmpresa == "Filial") {
      this.companys.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz != null) {
          empresas.push(element);
        }
      });
    }

    this.companiesFilter = empresas;

    this.companysFiltered.subscribe(this.companiesFilter);
  }
}
