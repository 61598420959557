<div class="main-page consultas">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-12 my-2">
                <h1>
                    <a class="btn btn-voltar text-center mr-2" (click)="this.router.navigate(['/consultations'])"
                        data-title="Voltar"><i class="fas fa-arrow-left"></i></a>
                    Consulta de Solicitação de Monitoramento
                </h1>
            </div>
        </div>
        <div *ngIf="analyzeReportOperatorsPage?.page.totalElements === 0">
            <div class="row no-gutters mt-3">
                <p>Nenhum registro até o momento...</p>
            </div>
            <div class="row no-gutters mt-3">
                <a (click)="this.router.navigate(['/solicitacao-monitoramento/create'])" mat-button color="primary"
                    class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
                    Gerar uma nova Solicitação de Monitoramento
                </a>
            </div>
        </div>
        <div class="row no-gutters mt-3">
            <div class="mat-elevation table-content">
                <div fxLayout fxLayoutAlign="center center">
                    <mat-form-field fxFlex="10%" class="ml-2">
                        <mat-label>Campo Filtro</mat-label>
                        <mat-select (selectionChange)="this.fieldFilter()" [(value)]="typeFieldFilterSelected"
                            [(ngModel)]="typeFieldFilterSelected">
                            <mat-option *ngFor="let type of typeFieldSelect" [value]="type.value">
                                {{ type.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="40%" class="ml-2">
                        <mat-label>Filtrar por pacote</mat-label>
                        <input matInput autocomplete="off" (keyup)="this.applyFilter()" [(ngModel)]="filter"
                            style="text-transform: uppercase;" #input>
                    </mat-form-field>
                </div>
                <table mat-table [dataSource]="analyzesOperators" matSort class="mat-elevation-z8">
                    <ng-container matColumnDef="dateStart">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Inicio</th>
                        <td mat-cell *matCellDef="let element">{{ element.formatted_monitoring_start_date}}</td>
                    </ng-container>
                    <ng-container matColumnDef="plate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Placa</th>
                        <td mat-cell *matCellDef="let element" style="text-transform: uppercase">{{ element.plate }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="driverName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Motorista</th>
                        <td mat-cell *matCellDef="let element" style="text-transform: uppercase">{{ element.driver_name
                            }}</td>
                    </ng-container>

                    <ng-container matColumnDef="companyName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome Empresa</th>
                        <td mat-cell *matCellDef="let element" style="text-transform: uppercase">
                            {{ element.name_company != null ? element.name_company : element.name_division }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="responsible">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Responsável</th>
                        <td mat-cell *matCellDef="let element" style="text-transform: uppercase">{{ element.responsible
                            }}</td>
                    </ng-container>

                    <ng-container matColumnDef="">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                        <td mat-cell *matCellDef="let element" style="text-transform: uppercase">{{ element.responsible
                            }}</td>
                    </ng-container>

                    <ng-container matColumnDef="statusMonitoring">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                        <td mat-cell *matCellDef="let element" [ngStyle]="getStatusStyle(element.status_monitoring)">
                            <span *ngIf="element.status_report === 'ERROR'; else successProcessing">
                                <span style="color: red;">ERRO</span>
                            </span>
                            <ng-template #successProcessing>
                                <span *ngIf="element.status_monitoring === 'O'" style="color: green;">ENVIADO PARA
                                    OPERADOR</span>
                                <span *ngIf="element.status_monitoring === 'M'" style="color: blue;">EM
                                    MONITORAMENTO</span>
                            </ng-template>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="packageNumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Núm. Viagem</th>
                        <td mat-cell *matCellDef="let element" style="text-transform: uppercase"> {{ element.trip_number
                            }} </td>
                    </ng-container>

                    <ng-container matColumnDef="viewOrigin">
                        <th mat-header-cell *matHeaderCellDef> Origem</th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Visualizar Endereços"
                                matTooltip="Visualizar Endereços">
                                <a (click)="openOriginPopup(element.uuid)" class="btn btn-link">
                                    <img height="20" src="assets/img/view.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="viewOperatorHandling">
                        <th mat-header-cell *matHeaderCellDef> Endereços</th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Visualizar Endereços"
                                matTooltip="Visualizar Endereços">
                                <a (click)="openAddressPopup(element.uuid)" class="btn btn-link">
                                    <img height="20" src="assets/img/view.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="sentManager">
                        <th mat-header-cell *matHeaderCellDef> Env. Operador</th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container *ngIf="element.status_monitoring !== 'O'">
                                <span class="alerta mr-2" data-title="Enviar" matTooltip="Enviar">
                                    <a (click)="handleSent(element.uuid)" class="btn btn-link">
                                        <img height="20" src="assets/img/sent-manager.svg" alt="" />
                                    </a>
                                </span>
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="update">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Editar" matTooltip="Editar">
                                <a (click)="openToUpdate(element.uuid)" class="btn btn-link" target="_blank">
                                    <img height="20" src="assets/img/edit.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button (click)="handleDelete(element)" class="btn btn-link" matTooltip="Apagar">
                                <img height="20" src="assets/img/trash-cadastro.svg" alt="" />
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[10, 20, 40]" [pageSize]="analyzeReportOperatorsPage.page.size"
                    (page)="onChangePage($event)" [length]="analyzeReportOperatorsPage.page.totalElements"
                    showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</div>