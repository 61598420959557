<div class="main-page consultas">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-12 my-2">
                <h1>
                    <a class="btn btn-voltar text-center mr-2" (click)="this.router.navigate(['/consultations'])"
                        data-title="Voltar"><i class="fas fa-arrow-left"></i></a>
                    Consulta de Relatorios
                </h1>
            </div>
        </div>
        <div *ngIf="analyzeReportOperatorsPage?.page.totalElements === 0">
            <div class="row no-gutters mt-3">
                <p>Nenhum registro até o momento...</p>
            </div>
            <div class="row no-gutters mt-3">
                <a (click)="this.router.navigate(['/relatorio-analitico-mensal'])" mat-button color="primary"
                    class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
                    Gerar um novo Relatorio
                </a>
            </div>
        </div>
        <div class="row no-gutters mt-3">
            <div class="mat-elevation table-content">
                <div fxLayout fxLayoutAlign="center center">
                    <mat-form-field fxFlex="10%" class="ml-2">
                        <mat-label>Campo Filtro</mat-label>
                        <mat-select (selectionChange)="this.fieldFilter()" [(value)]="typeFieldFilterSelected"
                            [(ngModel)]="typeFieldFilterSelected">
                            <mat-option *ngFor="let type of typeFieldSelect" [value]="type.value">
                                {{ type.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="40%" class="ml-2">
                        <mat-label>Filtrar</mat-label>
                        <input matInput autocomplete="off" (keyup)="this.applyFilter()" [(ngModel)]="filter"
                            style="text-transform: uppercase;" #input>
                    </mat-form-field>
                </div>
                <table mat-table [dataSource]="analyzesOperators" matSort class="mat-elevation-z8">
                    <ng-container matColumnDef="dateStart">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Inicio Processamento</th>
                        <td mat-cell *matCellDef="let element">{{ element.processing_start}}</td>
                    </ng-container>
                    <ng-container matColumnDef="dateEnd">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Fim Processamento</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.processing_end }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="NameReportType">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo do Relatorio</th>
                        <td mat-cell *matCellDef="let element" style="text-transform: uppercase;">
                            {{ element.name_type_report }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="multipleFiles">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Multiplos Arquivos</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.multiple_files ? 'SIM' : 'NÃO' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="nmUser">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario Reponsavel</th>
                        <td mat-cell *matCellDef="let element" style="text-transform: uppercase;">
                            {{ element.nm_user }}
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="pdfMinio">
                        <th mat-header-cell *matHeaderCellDef>Ref. PDF</th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.minio_pdf && element.status_report === 'FINISHED'" class="alerta mr-2"
                                data-title="Arquivo PDF" matTooltip="Arquivo PDF">
                                <a (click)="exportReport(element.minio_pdf)" class="btn btn-link">
                                    <img height="20" src="assets/img/file-pdf.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="xlsxMinio">
                        <th mat-header-cell *matHeaderCellDef>Ref. EXCEL</th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.minio_xlsx && element.status_report === 'FINISHED'" class="alerta mr-2"
                                data-title="Arquivo EXCEL" matTooltip="Arquivo EXCEL">
                                <a (click)="exportReport(element.minio_xlsx)" class="btn btn-link">
                                    <img height="20" src="assets/img/file-xlxs.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="jsonMinio">
                        <th mat-header-cell *matHeaderCellDef>Ref. JSON</th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.minio_json && element.status_report === 'FINISHED'" class="alerta mr-2"
                                data-title="Arquivo JSON" matTooltip="Arquivo JSON">
                                <a (click)="exportReport(element.minio_json)" class="btn btn-link">
                                    <img height="20" src="assets/img/file-json.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="parameters">
                        <th mat-header-cell *matHeaderCellDef> Parâmetros</th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Parâmetros do relatório"
                                matTooltip="Parâmetros do relatório">
                                <a (click)="openParameterModal(element.parameter)" class="btn btn-link">
                                    <img height="20" src="assets/img/view.svg" alt="Ver parâmetros" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="viewMultiples">
                        <th mat-header-cell *matHeaderCellDef>Visualizar Multiplos Arquivos</th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Visualizar" matTooltip="Visualizar"
                                *ngIf="element.multiple_files">
                                <a (click)="openToMultiples(element.uuid)" class="btn btn-link">
                                    <img height="20" src="assets/img/operator-handling.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button (click)="handleDelete(element)" class="btn btn-link" matTooltip="Apagar">
                                <img height="20" src="assets/img/trash-cadastro.svg" alt="" />
                            </button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="statusReport">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status Relatorio</th>
                        <td mat-cell *matCellDef="let element" [ngStyle]="getStatusStyle(element.status_report)">
                            <span *ngIf="element.status_report === 'ERROR'; else successProcessing">
                                <span style="color: red;">ERRO</span>
                            </span>
                            <ng-template #successProcessing>
                                <span *ngIf="element.status_report === 'FINISHED'" style="color: green;">SUCESSO</span>
                                <span *ngIf="element.status_report === 'PROCESSING'" style="color: blue;">EM
                                    PROCESSAMENTO</span>
                            </ng-template>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[10, 20, 40]" [pageSize]="analyzeReportOperatorsPage.page.size"
                    (page)="onChangePage($event)" [length]="analyzeReportOperatorsPage.page.totalElements"
                    showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</div>