import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

import { ReportEngineService } from "../../services/report-engine.service";
import { toCamel } from "snake-camel";
import { ReportTypeEngineModelPaginated } from "src/app/models/report-type-engine-model-paginated";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CoreService } from "src/app/services/migrate/api.core.service";
import { USER_DATA } from "src/app/utils/constants.util";
import { ToolsService } from "src/app/services/migrate/tools.service";
import { Empresa } from "src/app/models/markerModel";
import { ReportEngineModel } from "src/app/models/report-engine-model";
import { STORAGE_KEY_CURRENT_USER } from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";

import Swal from "sweetalert2";
@Component({
  selector: 'app-monthly-report',
  templateUrl: './monthly-report.component.html',
  styleUrls: ['./monthly-report.component.scss']
})
export class MonthlyReportComponent implements OnInit {
  public reportEngineService: ReportEngineService;
  public serviceCore: CoreService;

  public reportTypeEngineModelsPaginated: ReportTypeEngineModelPaginated;
  public reportEngineModel: ReportEngineModel;

  public controlCompany = new FormControl();

  public companys = new Array<any>();
  public companysFiltered = new Observable<Array<any>>();

  public companiesFilter: any = [];
  public companiesSelect: any = [];

  public typesReport: any = [
    { name: "RELATORIO POR MOTORISTA", value: "MONTHLY_DRIVER" },
    { name: "RELATORIO POR VEICULO", value: "MONTHLY_VEHICLE" },
  ];

  public ReportOperationalForm: FormGroup;

  public companyFilterSelected;
  public typesReportSelected;

  public errorMessage;

  ngOnInit(): void {
    this.loadDataElements();

    this.ReportOperationalForm = this.formBuilder.group(
      {
        selectCompany: ["", Validators.required],
        dateStart: ["", Validators.required],
        dateEnd: ["", Validators.required],
        modelReport: ["", Validators.required],
        gpsJumps: [false],
        removeKm: [false],
      },
      { updateOn: "blur" }
    );

    this.companysFiltered = this.controlCompany.valueChanges.pipe(
      map((value) => (typeof value === "string" ? value : value.id)),
      map((name) =>
        name ? this.filterValuesCompany(name) : this.companys.slice()
      )
    );
  }

  constructor(
    private tools: ToolsService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    reportEngineService: ReportEngineService,
    apiserviceCore: CoreService
  ) {
    this.serviceCore = apiserviceCore;
    this.reportEngineService = reportEngineService;
  }

  public async loadDataElements() {
    try {
      this.tools.builder().spinner.show();

      const companys = await this.serviceCore.get<any>({
        url: "v2/companys",
        params: {
          profileId: USER_DATA().profileId,
          size: "5000",
          page: "0",
          sort: "nome",
          sortDirections: "ASC",
        },
      });

      this.companys = companys?.elements;
      this.companys = this.companys.sort((a, b) =>
        a.nmNome.localeCompare(b.nmNome)
      );
      this.controlCompany.setValue("");

      this.companiesSelect = [
        { name: "Divisão", value: 1 },
        { name: "Matriz", value: 2 },
        { name: "Filial", value: 3 },
      ];

      this.tools.builder().spinner.hide();
    } catch (error) {
      this.tools.builder().spinner.hide();
      throw new EvalError(error);
    }
  }



  displayCompany(empresa: Empresa): string {
    return empresa && empresa.nmNome ? empresa.nmNome : "";
  }


  public filterCompany() {
    this.controlCompany.setValue("");
    var companyModel = this.companyFilterSelected;
    var empresas = [];

    if (companyModel == "Divisão") {
      this.companys.forEach((element) => {
        if (element.cnpj == null) {
          empresas.push(element);
        }
      });
    }

    if (companyModel == "Matriz") {
      this.companys.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz == null) {
          empresas.push(element);
        }
      });
    }

    if (companyModel == "Filial") {
      this.companys.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz != null) {
          empresas.push(element);
        }
      });
    }

    this.companiesFilter = empresas;

    this.companysFiltered.subscribe(this.companiesFilter);
  }

  public filterValuesCompany(value: any): Array<any> {
    const filterValue = value;

    return this.companys
      .sort((a, b) => a.nmNome.localeCompare(b.nmNome))
      .filter((client) => client.nmNome.toLowerCase().includes(filterValue));
  }

  public async submitForm(): Promise<void> {
    this.spinner.show();

    const values = this.ReportOperationalForm.value;

    let dateStart: String = values.dateStart + "T00:00:01";
    let dateEnd: String = values.dateEnd + "T23:59:59";
    let gpsJump: String = values.gpsJumps;
    let removeKm: String = values.removeKm;
    let companyId: string = "NULL";
    let divisionId: string = "NULL";

    if (this.controlCompany.value && typeof this.controlCompany.value === 'object') {
      if (values.selectCompany === "Filial" || values.selectCompany === "Matriz") {
        companyId = this.controlCompany.value.id;
      }
      if (values.selectCompany === "Divisão") {
        divisionId = this.controlCompany.value.id;
      }
    }

    let reportEngine: ReportEngineModel = new ReportEngineModel();

    reportEngine.reportTypeUuid = "280f51f6-4d94-4d84-9ec7-f8c1bf05d4ce";
    reportEngine.parameter = `{
          "IDEMPRESA": "${companyId}",
          "IDDIVISAO": "${divisionId}",
          "MODELORELATORIO": "${values.modelReport}",
          "DATAINICIO": "${dateStart}",
          "DATAFIM": "${dateEnd}",
          "XLSX": false,
          "PDF": true,
          "SALTOGPS": "${gpsJump}",
          "REMOVERKM": "${removeKm}"
        }`;

    reportEngine.requestOperator = storageX
      .whereKeyIs(STORAGE_KEY_CURRENT_USER)
      .get()?.id;

    this.reportEngineService.saveReport(reportEngine).subscribe((t) => {
      this.reportEngineModel = toCamel(t) as ReportEngineModel;
    });

    this.spinner.hide();
    this.handleReport();
  }

  public async handleReport(): Promise<void> {
    Swal.fire({
      icon: "success",
      title: "Rélatorio está em processamento!",
      html: `<h6>Deseja ir para tela de consulta de relatorios mensais?</h6>`,
      showCloseButton: false,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: "Não",
      showConfirmButton: true,
      confirmButtonText: "Sim",
      focusConfirm: false,
    }).then((action) => {
      if (action.isConfirmed) {
        this.router.navigate(["/relatorio-analitico-mensal/consulta"]);
      }
    });
  }
}

