<div class="main-page consultas">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-12 my-2">
                <h1>
                    <a class="btn btn-voltar text-center mr-2" (click)="this.router.navigate(['/consultations'])"
                       data-title="Voltar"><i class="fas fa-arrow-left"></i></a>
                    Consulta de Vídeos e Fotos
                </h1>
            </div>
        </div>
        <div class="row no-gutters mt-3">
            <div class="mat-elevation table-content">
                <div fxLayout fxLayoutAlign="center center">
                    <mat-form-field fxFlex="30%" class="ml-2">
                        <mat-label>Filtrar por Terminal</mat-label>
                        <input matInput autocomplete="off"
                               [matAutocomplete]="auto"
                               [formControl]="myControl"
                               style="text-transform: uppercase;">
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setVehicle($event)"
                                          [displayWith]="getFilterDescription">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                {{option.placa}}
                            </mat-option>

                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field fxFlex="30%" class="ml-2">
                        <mat-label>Data Inicial</mat-label>
                        <mat-datetimepicker-toggle matSuffix [for]="initialDatePicker">
                        </mat-datetimepicker-toggle>
                        <mat-datetimepicker #initialDatePicker type="datetime" openOnFocus="true" timeInterval="5"></mat-datetimepicker>
                        <input matInput autocomplete="off" [matDatetimepicker]="initialDatePicker" [(ngModel)]="filter.initialDate">
                    </mat-form-field>
                    <mat-form-field fxFlex="30%" class="ml-2">
                        <mat-label>Data Final</mat-label>
                        <mat-datetimepicker-toggle matSuffix [for]="finalDatePicker">
                        </mat-datetimepicker-toggle>
                        <mat-datetimepicker #finalDatePicker type="datetime" openOnFocus="true" timeInterval="5"></mat-datetimepicker>
                        <input matInput autocomplete="off" [matDatetimepicker]="finalDatePicker" [(ngModel)]="filter.finalDate">
                    </mat-form-field>
                    <mat-form-field fxFlex="10%" class="ml-2">
                        <mat-label> Câmera </mat-label>
                        <mat-select [(value)]="filter.sourceDevice" placeholder="Câmera">
                            <mat-option *ngFor="let device of sourceDevices" [value]="device.key">
                                {{ device.value }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="10%" class="ml-2">
                        <mat-select [(value)]="filter.fileType" placeholder="Tipo de arquivo">
                            <mat-option *ngFor="let fileType of fileTypes" [value]="fileType.key">
                                {{ fileType.value }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div fxFlex="10%" class="ml-2">
                        <button pButton label="Buscar" (click)="this.applyFilter()">
                        </button>
                    </div>

                </div>
                <table mat-table [dataSource]="trackerFilesDataSource" matSort apptableresponsive class="mat-elevation-z8" (matSortChange)="applySort($event)">
                    <ng-container matColumnDef="fileName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome do Arquivo</th>
                        <td mat-cell *matCellDef="let element">{{ element.fileName}}</td>
                    </ng-container>

                    <ng-container matColumnDef="sourceEvent">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Evento</th>
                        <td mat-cell *matCellDef="let element">{{ this.resolveSourceEvent(element.sourceEvent) }}</td>
                    </ng-container>

                    <ng-container matColumnDef="positionDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Data de geração</th>
                        <td mat-cell *matCellDef="let element">{{ element.positionDate | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                    </ng-container>

                    <ng-container matColumnDef="onlineDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Data de recebimento</th>
                        <td mat-cell *matCellDef="let element">{{ element.onlineDate | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                    </ng-container>

                    <ng-container matColumnDef="download">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Download" matTooltip="Download">
                                <a (click)="downloadFile(element)"
                                   class="btn btn-link">
                                    <img height="20" src="assets/img/download.svg" alt="download" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="visualizar">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button (click)="viewFile(element)" class="btn btn-link" matTooltip="Visualizar">
                                <img height="20" src="assets/img/view.svg" alt="visualizar" />
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
                    <!-- Linha mostrada quando não há dados correspondentes. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">
                            Sem arquivos disponíveis para esse veículo nesse período
                        </td>
                    </tr>
                </table>
                <mat-paginator
                        [pageSizeOptions]="[10, 25, 50, 100]"
                        [pageIndex]="trackerFilesPage?.page.number"
                        [pageSize]="trackerFilesPage?.page.size"
                        [length]="trackerFilesPage?.page.totalElements"
                        (page)="applyPagination($event)"
                        class="mat-elevation-z8"
                        aria-label="Selecionar páginas"
                ></mat-paginator>
            </div>
        </div>
    </div>
</div>

