
import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

import { MatTableDataSource } from "@angular/material/table";
import { PageEvent } from "@angular/material/paginator";
import { AnalyzeReportSupervisorModelPaginated } from "src/app/models/analyze-report-supervisor-model-paginated";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { ManagerViewModel } from "src/app/models/manager-view.model";
import Swal from "sweetalert2";
import { ReportEngineService } from "src/app/services/report-engine.service";

@Component({
  selector: 'app-parameters-type',
  templateUrl: './parameters-type.component.html',
  styleUrls: ['./parameters-type.component.scss']
})
export class ParametersTypeComponent implements OnInit {
  public reportEngineService: ReportEngineService;
  public datePipe: DatePipe;

  public managerViewModelModel: ManagerViewModel;
  public errorMessage;
  public parametersModelPage: AnalyzeReportSupervisorModelPaginated;
  public parametersTypes: MatTableDataSource<any> =
    new MatTableDataSource<any>();
  public tableColumns: string[];
  public typeFieldSelect = [
    { name: "Descrição do Parâmetro", value: "NAME" },
  ];

  operationTypeMap: { [key: string]: string } = {
    'B': 'MAIOR',
    'S': 'MENOR',
    'E': 'IGUAL',
    'D': 'DIFERENTE'
  };

  public actualPage: PageEvent;
  public permissions: [];
  public filter = "";
  public field = "";
  public typeFieldFilterSelected;

  constructor(
    datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    public router: Router,
    reportEngineService: ReportEngineService,
    public dialog: MatDialog
  ) {
    this.datePipe = datePipe;
    this.reportEngineService = reportEngineService;
  }

  ngOnInit(): void {
    this.typeFieldFilterSelected = this.typeFieldSelect[0].value;
    this.field = this.typeFieldFilterSelected;
    this.initialPage();
    this.loadReports(this.actualPage, this.filter, this.field);
    this.loadTableDefinitions();
  }

  // Método para retornar a descrição correta
  getOperationTypeDescription(operationType: string): string {
    return this.operationTypeMap[operationType] || operationType; // Retorna o valor mapeado ou o valor original se não houver mapeamento
  }


  private loadReports(page: PageEvent, name: string, field: string): void {
    this.spinner.show();

    this.reportEngineService
      .getAllParameterstype(page, name, field)
      .subscribe(
        (t) => {
          this.parametersModelPage = t;
          this.parametersTypes =
            new MatTableDataSource<any>(
              this.parametersModelPage.content
            );
          this.spinner.hide();
        },
        (error) => {
          console.log("Erro ao obter os parâmetros");
          this.spinner.hide();
        }
      );
  }

  applyFilter(): void {
    this.loadReports(this.actualPage, this.filter, this.field);
  }

  public async handleDelete(element): Promise<void> {
    console.log(element)
    Swal.fire({
      icon: "warning",
      title: "Excluir Parâmetro",
      html: `<h6>Tem certeza que você deseja excluir o Parâmetro
       <small><b> ${element.name}</b>? </h6>`,
      showCloseButton: false,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: "Cancelar",
      showConfirmButton: true,
      confirmButtonText: "Confirmar",
      focusConfirm: false,
    }).then((action) => {
      if (action.isConfirmed) {
        this.deleteParameters(element.uuid);
      }
    });
  }

  public async deleteParameters(uuid: string): Promise<void> {

    this.spinner.show();

    this.reportEngineService
      .deleteParametersType(uuid)
      .subscribe(
        (t) => {
          this.loadReports(this.actualPage, this.filter, this.field);
          this.spinner.hide();
        },
        (error) => {
          console.log("Erro ao obter os parâmetros");
          this.spinner.hide();
        }
      );
  }


  fieldFilter(): void {
    this.field = this.typeFieldFilterSelected;
    this.filter = "";
  }

  private loadTableDefinitions(): void {
    this.tableColumns = [
      "name",
      "operationType",
      "fieldName",
      "ruleModelContains",
      "update",
      "delete"
    ];
  }

  onChangePage(event: PageEvent): void {
    this.actualPage = event;
    this.loadReports(this.actualPage, this.filter, this.field);
  }

  private initialPage(): void {
    this.actualPage = new PageEvent();
    this.actualPage.pageIndex = 0;
    this.actualPage.pageSize = 20;
  }

}
