<div class="main-page cadastros journey-main">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-12 my-2">
                <h1>Tempo e Movimento</h1>
            </div>
        </div>
        <div style="margin-bottom: 45px;">
            <p-menubar #menu [model]="items"></p-menubar>
        </div>

        <mat-horizontal-stepper [linear]="true" #stepper>
            <mat-step>
                <!-- COMECA -->
                <p-table #journeyheader [value]="this.resultadoAnalitico" tableStyleClass="tableAnalitico">
                    <ng-template pTemplate="header" let-columns>
                        <tr [hidden]="this.resultadoAnalitico.length == 0" id="tabela-analitico"
                            [ngClass]="this.filterDialog == true ? '' : 'sticky-top'" style='top:65px;'>
                            <th style="text-align:center !important;border:none;width: 10%;" class="linha-analitico">
                                Data</th>
                            <th style="text-align:center !important;border:none;width: 7%;" class="linha-analitico">
                                Placa</th>
                            <th style="text-align:center !important;border:none;width: 5%;" class="linha-analitico">
                                Frota</th>
                            <th style="text-align:center !important;border:none" class="linha-analitico">Motorista</th>
                            <th style="text-align:center !important;border:none" class="linha-analitico">Status Ignição
                            </th>
                            <th style="text-align:center !important;border:none" class="linha-analitico">KM Percorrido
                            </th>
                            <th style="text-align:center !important;border:none" class="linha-analitico">Tempo
                            </th>
                        </tr>
                    </ng-template>

                    <br>
                    <ng-template pTemplate="body" let-rowdata>
                        <tr>
                            <td style="text-align:center !important;    border-bottom: 1px solid; padding: 1% !important;"
                                align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["dia"]}}</td>
                            <td style="text-align:center !important;    border-bottom: 1px solid ; " align="center"
                                valign=middle [pRowToggler]="rowdata">{{rowdata["placa"]}}</td>
                            <td style="text-align:center !important;    border-bottom: 1px solid; " align="center"
                                valign=middle [pRowToggler]="rowdata">{{rowdata["frota"]}}</td>
                            <td style="text-align:center !important;    border-bottom: 1px solid; " align="center"
                                valign=middle [pRowToggler]="rowdata">{{rowdata["motoristaNome"]}}</td>
                            <td *ngIf="rowdata['statusIgnicao'] == 'PARADO'"
                                style="text-align:center !important; color: #f51515;     border-bottom: 1px solid; "
                                align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["statusIgnicao"]}}</td>
                            <td *ngIf="rowdata['statusIgnicao'] == 'MOVIMENTO'"
                                style="text-align:center !important;color: #05d338;    border-bottom: 1px solid; "
                                align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["statusIgnicao"]}}</td>
                            <td style="text-align:center !important;    border-bottom: 1px solid; " align="center"
                                valign=middle [pRowToggler]="rowdata">{{rowdata["kmPercorridoDia"]}} KM</td>
                            <td style="text-align:center !important;    border-bottom: 1px solid; " align="center"
                                valign=middle [pRowToggler]="rowdata">{{rowdata["tempoIgnicao"]}}</td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- FIM-->
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</div>

<p-sidebar [(visible)]="this.filterDialog" position="right">
    <div style="margin-bottom: 20px;">
        <span>Filtros</span>
    </div>
    <form [formGroup]="this.consultaFormGroup">
        <div class="form-row">
            <div class="form-group col-lg-12">
                <mat-form-field appearance="outline">
                    <mat-label>Tipo de Relatório</mat-label>
                    <mat-select formControlName="tipoRelatorio" (selectionChange)="onTipoRelatorioChange()">
                        <mat-option value="DRIVER">Por Motorista</mat-option>
                        <mat-option value="VEHICLE">Por Veículo</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-lg-12">
                <mat-form-field appearance="outline">
                    <mat-label>Data Início</mat-label>
                    <input formControlName="dataInicio" type="date" matInput placeholder="Inicio" required />
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-lg-12">
                <mat-form-field appearance="outline">
                    <mat-label>Data Fim</mat-label>
                    <input formControlName="dataFim" type="date" matInput placeholder="Fim" required />
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-8">
                <mat-form-field appearance="outline">
                    <mat-label>Tipo de Empresa</mat-label>
                    <mat-select formControlName="selecaoEmpresa" [(value)]="empresaFilterSelected"
                        [(ngModel)]="empresaFilterSelected" (selectionChange)="this.filterCompanyReports()">
                        <mat-option *ngFor="let type of companiesSelect" [value]="type.name">
                            {{ type.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-8">
                <mat-form-field appearance="outline">
                    <mat-label>Empresa</mat-label>
                    <input type="text" matInput [formControl]="controlEmpresa" [matAutocomplete]="autoEmpresa" required>
                    <mat-autocomplete #autoEmpresa="matAutocomplete" [displayWith]="displayEmpresa">
                        <mat-option *ngFor="let type of this.companiesFilter" [value]="type">
                            {{type.nmNome}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-8">
                <mat-form-field appearance="outline" *ngIf="controlEmpresa.value != ''">
                    <mat-label>Motorista</mat-label>
                    <input type="text" matInput [formControl]="controlMotorista" [matAutocomplete]="autoMotorista"
                        [disabled]="tipoRelatorioSelecionado !== 'DRIVER'">
                    <mat-autocomplete #autoMotorista="matAutocomplete" [displayWith]="displayMotorista">
                        <mat-option *ngFor="let type of this.driversFiltered | async" [value]="type">
                            {{type.nmNome}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-8">
                <mat-form-field appearance="outline" *ngIf="controlEmpresa.value != ''">
                    <mat-label>Placa / Frota</mat-label>
                    <input type="text" matInput [formControl]="controlVeiculo" [matAutocomplete]="autoTerminal"
                        [disabled]="tipoRelatorioSelecionado !== 'VEHICLE'">
                    <mat-autocomplete #autoTerminal="matAutocomplete" [displayWith]="displayTerminal">
                        <mat-option *ngFor="let type of this.vehiclesFiltered | async" [value]="type">
                            {{type.cdPlaca}}/{{type.frota}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-8">
                <button type="submit" (click)="onConsultar()">Consultar</button>
            </div>
        </div>
    </form>
</p-sidebar>