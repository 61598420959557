import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {TrackerFilePaginatedModel} from '../../models/tracker-file-paginated-model';
import {MatTableDataSource} from '@angular/material/table';
import {TrackerFileModel} from '../../models/tracker-file-model';
import {TrackerFileFilter} from './tracker-file-filter';
import {TrackerFileService} from '../../services/tracker-file.service';
import {toCamel} from 'snake-camel';
import {MatDialog} from '@angular/material/dialog';
import {ViewTrackerFileComponent} from './view-tracker-file/view-tracker-file.component';
import {GeneralFilterOption} from '../../components/maps/general-filter/general-filter-option';
import {Observable} from 'rxjs';
import {VehiclesMenuService} from '../../services/vehicles-menu.service';
import storageX from '../../storageCore/storageX';
import {STORAGE_KEY_CURRENT_PERFIL} from '../../storageCore/constStorageKeys';
import {debounceTime, distinctUntilChanged, filter, switchMap} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatSort, Sort} from '@angular/material/sort';
import {DatePipe} from '@angular/common';
import {TrackerFileSourceEventEnum} from '../../enums/tracker-file-source-event-enum.enum';

@Component({
  selector: 'app-tracker-file',
  templateUrl: './tracker-file.component.html',
  styleUrls: ['./tracker-file.component.scss']
})
export class TrackerFileComponent implements OnInit, AfterViewInit {

  public actualSort: Sort;
  public trackerFilesPage: TrackerFilePaginatedModel;
  public trackerFilesDataSource: MatTableDataSource<TrackerFileModel> = new MatTableDataSource();
  public filter: TrackerFileFilter = new TrackerFileFilter();
  public tableColumns: string[] = [];
  filteredOptions: Observable<GeneralFilterOption[]>;
  selectedOption: GeneralFilterOption = null;
  myControl = new FormControl('');
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  //Constants for selects
  sourceDevices: { key: string, value: string} [] = [
    {key: 'MC', value: 'Camera Principal'},
    {key: 'AC', value: 'Camera Auxiliar'},
    {key: 'BOTH', value: 'Todos'}
  ];

  fileTypes: { key: string, value: string} [] = [
    {key: 'V', value: 'Video'},
    {key: 'I', value: 'Foto'},
    {key: 'BOTH', value: 'Todos'},
  ];

  constructor(private spinner: NgxSpinnerService,
              public router: Router,
              private trackerFileService: TrackerFileService,
              private vehicleMenuService: VehiclesMenuService,
              public dialog: MatDialog,
              private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.loadTableDefinitions();
    this.filteredOptions = this.myControl.valueChanges.pipe(
        distinctUntilChanged(),
        debounceTime(300),
        filter((value) => !!value),
        switchMap((value) => {
          const placa = typeof value === 'string' ? value : value?.placa;
          return this.vehicleMenuService.getVehiclesToSelectByProfileAndSearch(
              this.getIdPerfil(),
              placa
          );
        })
    );
  }

  applyFilter(): void {

    this.loadData(this.paginator, this.filter, this.actualSort);
  }

  loadData(page: PageEvent, f: TrackerFileFilter, sort: Sort): void {
    this.spinner.show();
    let sortDirection = 'ASC';
    if (sort) {
      sortDirection = sort.active + ',' + sort.direction;
    }

    this.trackerFileService.getPaginated(page, sortDirection, f).subscribe(
        (response) => {
          this.trackerFilesPage = toCamel(response) as TrackerFilePaginatedModel;
          this.trackerFilesDataSource = new MatTableDataSource(this.trackerFilesPage.content);
          this.trackerFilesDataSource._updateChangeSubscription();
          this.spinner.hide();
        },
        (error) => {
          console.error('Erro ao carregar dados');
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
    );
  }

  private loadTableDefinitions(): void {
    this.tableColumns = [
      'fileName',
      'sourceEvent',
      'positionDate',
      'onlineDate',
      'download',
      'visualizar'
    ];
  }

  viewFile(element: TrackerFileModel): void {
    this.spinner.show();
    this.trackerFileService.getLink(element).subscribe({
      next: (response) => {
        element = toCamel(response) as TrackerFileModel;
        const dialogRef = this.dialog.open(ViewTrackerFileComponent, {
          data: element
        });

        dialogRef.afterClosed().subscribe(result => {
        });
        this.spinner.hide();
      },
      error: (error) => {
        console.error('Erro ao carregar dados');
        this.spinner.hide();
      },
      complete: () => {}
    });

  }

  downloadFile(element: TrackerFileModel): void {
    this.spinner.show();
    this.trackerFileService.getLink(element).subscribe({
      next: (response) => {
        element = toCamel(response) as TrackerFileModel;
        window.open(element.fileLink, '_blank');
        this.spinner.hide();
      },
      error: (error) => {},
      complete: () => {}
      });

  }

  getFilterDescription(option: GeneralFilterOption): string {
    return option.placa;
  }

  private getIdPerfil(): number {
    const profile = storageX.whereKeyIs(STORAGE_KEY_CURRENT_PERFIL).get();
    return profile.id;
  }

  setVehicle($event: MatAutocompleteSelectedEvent): void {
    this.selectedOption = $event.option.value;
    this.filter.terminalId = this.selectedOption.id_terminal;
  }

  applyPagination(page: PageEvent): void {
    this.loadData(page, this.filter, this.actualSort);
  }

  applySort(sort: Sort): void {
    this.actualSort = sort;
    this.loadData(this.paginator, this.filter, sort);
  }

  ngAfterViewInit(): void {
    this.paginator._intl.itemsPerPageLabel = 'Itens por página';
  }

  resolveSourceEvent(sourceEvent: string): string {
    return TrackerFileSourceEventEnum[sourceEvent];
  }
}
