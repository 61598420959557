import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

import { ReportEngineService } from "../../../services/report-engine.service";
import { ParametersTypeModel } from "src/app/models/parameters-type-model";
import { toCamel } from "snake-camel";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: 'app-parameters-edit',
  templateUrl: './parameters-edit.component.html',
  styleUrls: ['./parameters-edit.component.scss']
})
export class ParametersEditComponent implements OnInit {
  public reportEngineService: ReportEngineService;

  public parametersTypeUuid = "";
  public parametersTypeOld: any;

  public parametersTypeModel: ParametersTypeModel;

  public operationValids: any = [];
  public fieldValids: any = [];
  models: string[] = [];

  modelFilterControl = new FormControl('');
  filteredModels: Observable<string[]>;

  public parametersTypeForm: FormGroup;

  public operationValidSelected
  public fieldValidSelected
  public modelSelected

  public errorMessage;

  ngOnInit(): void {
    this.parametersTypeForm = this.formBuilder.group(
      {
        validOperation: ["", Validators.required],
        validFields: ["", Validators.required],
        description: ["", Validators.required],
        modelRule: [""],
      },
      { updateOn: "blur" }
    );

    this.parametersTypeUuid = this.activatedRoute.snapshot.params.uuid;
    this.loadDataElements(this.parametersTypeUuid);

    // Configurar filtro dinâmico baseado nos modelos carregados
    this.filteredModels = this.modelFilterControl.valueChanges.pipe(
      map(value => this._filterModels(value || ''))
    );
  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    reportEngineService: ReportEngineService
  ) {
    this.reportEngineService = reportEngineService;
  }

  public async loadDataElements(parametersTypeUuid: string) {
    try {
      this.spinner.show();

      this.reportEngineService
        .getByOperationValid()
        .subscribe(
          (t) => {
            this.operationValids = t;
            this.spinner.hide();
          },
          (error) => {
            console.log("Erro ao obter as informações");
            this.spinner.hide();
          }
        );

      this.reportEngineService
        .getByFieldValid()
        .subscribe(
          (t) => {
            this.fieldValids = t;
            this.spinner.hide();
          },
          (error) => {
            console.log("Erro ao obter as informações");
            this.spinner.hide();
          }
        );

      this.reportEngineService
        .getByModelVehicles()
        .subscribe(
          (t) => {
            this.models = t;
            this.spinner.hide();
          },
          (error) => {
            console.log("Erro ao obter as informações");
            this.spinner.hide();
          }
        );

      this.reportEngineService
        .getByUuidParametersType(parametersTypeUuid)
        .subscribe((resp) => {
          this.parametersTypeOld = toCamel(resp);
          this.fillTransporterData();
        });

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  private _filterModels(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.models.filter(model => model.toLowerCase().includes(filterValue));
  }

  onModelSelected(event: any) {
    const selectedModel = event.option.value;
    console.log('Modelo selecionado:', selectedModel);
  }

  private fillTransporterData(): void {
    console.log(this.parametersTypeOld);

    this.parametersTypeForm
      .get("validOperation")
      .setValue(this.parametersTypeOld.operationType);
    this.parametersTypeForm
      .get("validFields")
      .setValue(this.parametersTypeOld.fieldName);

    this.operationValidSelected = this.parametersTypeOld.operationType;
    this.fieldValidSelected = this.parametersTypeOld.fieldName;
    this.modelSelected = this.parametersTypeOld.ruleModelContains;
    this.parametersTypeForm
      .get("description")
      .setValue(this.parametersTypeOld.name);
    this.parametersTypeForm
      .get("modelRule")
      .setValue(this.parametersTypeOld.ruleModelContains);
  }


  public async submitForm(): Promise<void> {
    this.spinner.show();

    const values = this.parametersTypeForm.value;
    let parametersType: ParametersTypeModel = new ParametersTypeModel();

    parametersType.name = values.description;
    parametersType.fieldName = this.fieldValidSelected;
    parametersType.operationType = this.operationValidSelected;
    parametersType.ruleModelContains = values.modelRule;

    console.log(parametersType)

    this.reportEngineService
      .updateParametersType(parametersType, this.parametersTypeUuid)
      .subscribe((t) => {
        this.parametersTypeModel = toCamel(t) as ParametersTypeModel;
        this.router.navigate(["/tipo-parametro"]);
      });

    this.spinner.hide();
  }

}

