<mat-dialog-content class="mat-typography">
  <div class="container">
    <h2>Buscar um endereço</h2>

    <div class="form-group">
      <label>Endereço ou Local:</label>
      <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()"
        placeholder="Digite um endereço ou localidade..." autocorrect="off" autocapitalize="off" spellcheck="off"
        type="text" #search />
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="close()">Fechar</button>
  <button *ngIf="data?.mode !== 'fill_address'" [disabled]="!latitude || !longitude" mat-button (click)="submit()">
    Ver no mapa
  </button>
  <button *ngIf="data?.mode === 'fill_address'" [disabled]="!latitude || !longitude" mat-button (click)="fillAddress()">
    Preencher Endereço
  </button>
</mat-dialog-actions>