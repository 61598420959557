import { Component, ElementRef, NgZone, OnInit, ViewChild } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

import { ReportEngineService } from "../../../services/report-engine.service";
import { ReportingRuleModel } from "src/app/models/reporting-rule-model";
import { Observable } from "rxjs";
import { CoreService } from "src/app/services/migrate/api.core.service";
import { USER_DATA } from "src/app/utils/constants.util";
import { Empresa } from "src/app/models/markerModel";
import { map } from "rxjs/operators";
import Swal from "sweetalert2";
import { ReportEngineModel } from "src/app/models/report-engine-model";
import { STORAGE_KEY_CURRENT_USER } from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";
import { toCamel } from "snake-camel";
import { MapsAPILoader } from "@agm/core";

@Component({
  selector: 'app-reporting-mechanism-create',
  templateUrl: './reporting-mechanism-create.component.html',
  styleUrls: ['./reporting-mechanism-create.component.scss']
})
export class ReportingMechanismCreateComponent implements OnInit {
  public reportEngineService: ReportEngineService;
  public serviceCore: CoreService;

  latitude: number;
  longitude: number;
  private geoCoder;
  @ViewChild('search') searchElementRef: ElementRef;


  public reportingRuleModelModel: ReportingRuleModel;

  public controlCompany = new FormControl();

  public companys = new Array<any>();
  public companysFiltered = new Observable<Array<any>>();

  public reportTypes: any = [];
  public terminals: any = [];
  public companiesFilter: any = [];
  public companiesSelect: any = [];

  public validFieldsReport = [
    { name: "IDTERMINAL", active: false, optional: false },
    { name: "IDMOTORISTA", active: false, optional: false },
    { name: "IDEMPRESA", active: false, optional: false },
    { name: "IDDIVISAO", active: false, optional: false },
    { name: "MODELORELATORIO", active: false, optional: false },
    { name: "DATAINICIO", active: false, optional: false },
    { name: "DATAFIM", active: false, optional: false },
    { name: "XLSX", active: false, optional: false },
    { name: "PDF", active: false, optional: false },
    { name: "SALTOGPS", active: false, optional: false },
    { name: "REMOVERKM", active: false, optional: false },
    { name: "LATITUDE", active: false, optional: false },
    { name: "LONGITUDE", active: false, optional: false },
    { name: "RAIO", active: false, optional: false },
    { name: "IDTERMINALS", active: false, optional: false },
    { name: "VELOCIDADE", active: false, optional: false },
  ];


  public typesReport: any = [
    { name: "RELATORIO POR MOTORISTA", value: "MONTHLY_DRIVER" },
    { name: "RELATORIO POR VEICULO", value: "MONTHLY_VEHICLE" },
  ];

  public reportingRuleForm: FormGroup;

  public typeReportSelected
  public typeParametersSelected
  public modelReportSelected;
  public companyFilterSelected;

  public reportEngineModel: ReportEngineModel;

  public errorMessage;

  ngOnInit(): void {

    this.loadDataElements();

    this.reportingRuleForm = this.formBuilder.group(
      {
        typeReport: ["", Validators.required],
        selectCompany: [""],
        dateStart: [""],
        dateEnd: [""],
        modelReport: [""],
        latitude: [""],
        longitude: [""],
        radius: [""],
        velocity: [""],
        search: [""],
        filePdf: [false],
        fileExcel: [false],
        gpsJumps: [false],
        removeKm: [false],
        terminals: [[]],
      },
      { updateOn: "blur" }
    );

    this.companysFiltered = this.controlCompany.valueChanges.pipe(
      map((value) => (typeof value === "string" ? value : value.id)),
      map((name) =>
        name ? this.filterValuesCompany(name) : this.companys.slice()
      )
    );

    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();

          // Define os valores no formulário
          this.reportingRuleForm.get('latitude').setValue(this.latitude);
          // Adicione longitude se houver campo
          this.reportingRuleForm.get('longitude')?.setValue(this.longitude);
        });
      });
    });

  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    reportEngineService: ReportEngineService,
    apiserviceCore: CoreService
  ) {
    this.reportEngineService = reportEngineService;
    this.serviceCore = apiserviceCore;
  }

  onTypeReportChange(event: any): void {
    this.spinner.show();
    const selectedTypeUuid = event.value;

    this.reportEngineService.getByUuidParametersField(selectedTypeUuid).subscribe(
      (response) => {
        console.log('Dados recebidos:', response);
        this.validateFields(response)
        this.spinner.hide();
      },
      (error) => {
        console.error('Erro ao buscar o relatório:', error);
        this.spinner.hide();
      }
    );
  }

  public filterValuesCompany(value: any): Array<any> {
    const filterValue = value;

    return this.companys
      .sort((a, b) => a.nmNome.localeCompare(b.nmNome))
      .filter((client) => client.nmNome.toLowerCase().includes(filterValue));
  }

  displayCompany(empresa: Empresa): string {
    return empresa && empresa.nmNome ? empresa.nmNome : "";
  }

  validateFields(response: any): void {
    const currentTypeReportValue = this.reportingRuleForm.get('typeReport')?.value;
    this.reportingRuleForm.reset({ typeReport: currentTypeReportValue });
    this.resetFields();

    response.forEach((field: any) => {
      const matchingField = this.validFieldsReport.find(
        (item) => item.name === field.field_name
      );

      if (matchingField) {
        matchingField.active = true;
        matchingField.optional = field.field_optional ?? matchingField.optional;

        // Configura valor true e desabilita se optional for false
        if (field.field_name === "PDF") {
          this.reportingRuleForm.get('filePdf')?.setValue(!field.field_optional);
        }
        if (field.field_name === "XLSX") {
          this.reportingRuleForm.get('fileExcel')?.setValue(!field.field_optional);
        }
        if (field.field_name === "SALTOGPS") {
          this.reportingRuleForm.get('gpsJumps')?.setValue(!field.field_optional);
        }
        if (field.field_name === "REMOVERKM") {
          this.reportingRuleForm.get('removeKm')?.setValue(!field.field_optional);
        }
      }
    });
  }


  public resetFields() {
    this.validFieldsReport = [
      { name: "IDTERMINAL", active: false, optional: false },
      { name: "IDMOTORISTA", active: false, optional: false },
      { name: "IDEMPRESA", active: false, optional: false },
      { name: "IDDIVISAO", active: false, optional: false },
      { name: "MODELORELATORIO", active: false, optional: false },
      { name: "DATAINICIO", active: false, optional: false },
      { name: "DATAFIM", active: false, optional: false },
      { name: "XLSX", active: false, optional: false },
      { name: "PDF", active: false, optional: false },
      { name: "SALTOGPS", active: false, optional: false },
      { name: "REMOVERKM", active: false, optional: false },
      { name: "LATITUDE", active: false, optional: false },
      { name: "LONGITUDE", active: false, optional: false },
      { name: "RAIO", active: false, optional: false },
      { name: "IDTERMINALS", active: false, optional: false },
      { name: "VELOCIDADE", active: false, optional: false },
    ];
  }

  public async loadDataElements() {
    try {
      this.spinner.show();

      this.reportEngineService
        .getByReportTypes()
        .subscribe(
          (t) => {
            this.reportTypes = t;
            this.spinner.hide();
          },
          (error) => {
            console.log("Erro ao obter as informações");
            this.spinner.hide();
          }
        );

      this.reportEngineService
        .getByTerminals()
        .subscribe(
          (t) => {
            this.terminals = t;
            this.spinner.hide();
          },
          (error) => {
            console.log("Erro ao obter as informações");
            this.spinner.hide();
          }
        );

      const companys = await this.serviceCore.get<any>({
        url: "v2/companys",
        params: {
          profileId: USER_DATA().profileId,
          size: "5000",
          page: "0",
          sort: "nome",
          sortDirections: "ASC",
        },
      });

      this.companys = companys?.elements;
      this.companys = this.companys.sort((a, b) =>
        a.nmNome.localeCompare(b.nmNome)
      );
      this.controlCompany.setValue("");

      this.companiesSelect = [
        { name: "Divisão", value: 1 },
        { name: "Matriz", value: 2 },
        { name: "Filial", value: 3 },
      ];

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  public filterCompany() {
    this.controlCompany.setValue("");
    var companyModel = this.companyFilterSelected;
    var empresas = [];

    if (companyModel == "Divisão") {
      this.companys.forEach((element) => {
        if (element.cnpj == null) {
          empresas.push(element);
        }
      });
    }

    if (companyModel == "Matriz") {
      this.companys.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz == null) {
          empresas.push(element);
        }
      });
    }

    if (companyModel == "Filial") {
      this.companys.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz != null) {
          empresas.push(element);
        }
      });
    }

    this.companiesFilter = empresas;

    this.companysFiltered.subscribe(this.companiesFilter);
  }


  public async submitForm(): Promise<void> {
    this.spinner.show();

    const values = this.reportingRuleForm.value;

    var dateStart: string;
    var dateEnd: string;


    let selectedReportType = this.reportTypes.find((report) => report.uuid === this.typeReportSelected);
    console.log(selectedReportType);
    if (selectedReportType.report_template == "ANALYTICAL_MONTHLY") {
      let dateStartFormatted = values.dateStart.split('T')[0];
      let dateEndFormatted = values.dateEnd.split('T')[0];
      dateStart = dateStartFormatted + "T00:00:01";
      dateEnd = dateEndFormatted + "T23:59:59";
    } else {
      dateStart = values.dateStart + ":01";
      dateEnd = values.dateEnd + ":59";
    }

    let companyId: string = "NULL";
    let divisionId: string = "NULL";
    let gpsJump: String = values.gpsJumps;
    let removeKm: String = values.removeKm;
    let filePdf: String = values.filePdf;
    let fileExcel: String = values.fileExcel;
    let latitude: String = values.latitude;
    let longitude: String = values.longitude;
    let radius: String = values.radius;
    let velocity: String = values.velocity;
    let terminals: String = values.terminals;

    if (this.controlCompany.value && typeof this.controlCompany.value === 'object') {
      if (values.selectCompany === "Filial" || values.selectCompany === "Matriz") {
        companyId = this.controlCompany.value.id;
      }
      if (values.selectCompany === "Divisão") {
        divisionId = this.controlCompany.value.id;
      }
    }

    let reportEngine: ReportEngineModel = new ReportEngineModel();

    reportEngine.reportTypeUuid = values.typeReport;
    reportEngine.parameter = `{
          "IDEMPRESA": "${companyId}",
          "IDDIVISAO": "${divisionId}",
          "MODELORELATORIO": "${values.modelReport}",
          "DATAINICIO": "${dateStart}",
          "DATAFIM": "${dateEnd}",
          "XLSX": "${fileExcel}",
          "PDF": "${filePdf}",
          "SALTOGPS": "${gpsJump}",
          "REMOVERKM": "${removeKm}",
          "LATITUDE": "${latitude}",
          "LONGITUDE": "${longitude}",
          "RAIO": "${radius}",
          "VELOCIDADE": "${velocity}",
          "IDTERMINALS": "${terminals}"
        }`;

    reportEngine.requestOperator = storageX
      .whereKeyIs(STORAGE_KEY_CURRENT_USER)
      .get()?.id;

    console.log(reportEngine)

    this.reportEngineService.saveReport(reportEngine).subscribe((t) => {
      this.reportEngineModel = toCamel(t) as ReportEngineModel;
    });

    this.spinner.hide();
    this.handleReport();
  }

  public async handleReport(): Promise<void> {
    Swal.fire({
      icon: "success",
      title: "Rélatorio está em Processamento!",
      html: `<h6>Deseja ir para tela de consulta de relatorios?</h6>`,
      showCloseButton: false,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: "Não",
      showConfirmButton: true,
      confirmButtonText: "Sim",
      focusConfirm: false,
    }).then((action) => {
      if (action.isConfirmed) {
        this.router.navigate(["/relatorios"]);
      }
    });
  }

  // Métodos para verificar a visibilidade e obrigatoriedade dos campos
  isDateStartVisible(): boolean {
    return this.validFieldsReport.find(field => field.name === "DATAINICIO")?.active || false;
  }

  isDateEndVisible(): boolean {
    return this.validFieldsReport.find(field => field.name === "DATAFIM")?.active || false;
  }

  isReportModelVisible(): boolean {
    return this.validFieldsReport.find(field => field.name === "MODELORELATORIO")?.active || false;
  }

  isCompanyOrDivisionVisible(): boolean {
    return this.validFieldsReport.some(
      field => (field.name === "IDEMPRESA" || field.name === "IDDIVISAO") && field.active
    );
  }

  isRemoveKmVisible(): boolean {
    return this.validFieldsReport.find(field => field.name === "REMOVERKM")?.active || false;
  }

  isGpsJumpsVisible(): boolean {
    return this.validFieldsReport.find(field => field.name === "SALTOGPS")?.active || false;
  }

  isFilePdfVisible(): boolean {
    return this.validFieldsReport.find(field => field.name === "PDF")?.active || false;
  }

  isFileExcelVisible(): boolean {
    return this.validFieldsReport.find(field => field.name === "XLSX")?.active || false;
  }

  isLatitudeVisible(): boolean {
    return this.validFieldsReport.find(field => field.name === "LATITUDE")?.active || false;
  }

  isLongitudeVisible(): boolean {
    return this.validFieldsReport.find(field => field.name === "LONGITUDE")?.active || false;
  }

  isRadiusVisible(): boolean {
    return this.validFieldsReport.find(field => field.name === "RAIO")?.active || false;
  }

  isVelocityVisible(): boolean {
    return this.validFieldsReport.find(field => field.name === "VELOCIDADE")?.active || false;
  }

  isTerminalsVisible(): boolean {
    return this.validFieldsReport.find(field => field.name === "IDTERMINALS")?.active || false;
  }

  isDateStartRequired(): boolean {
    return !this.validFieldsReport.find(field => field.name === "DATAINICIO")?.optional;
  }

  isDateEndRequired(): boolean {
    return !this.validFieldsReport.find(field => field.name === "DATAFIM")?.optional;
  }

  isReportModelRequired(): boolean {
    return !this.validFieldsReport.find(field => field.name === "MODELORELATORIO")?.optional;
  }

  isLatitudeRequired(): boolean {
    return !this.validFieldsReport.find(field => field.name === "LATITUDE")?.optional;
  }

  isLongitudeRequired(): boolean {
    return !this.validFieldsReport.find(field => field.name === "LONGITUDE")?.optional;
  }

  isRadiusRequired(): boolean {
    return !this.validFieldsReport.find(field => field.name === "RAIO")?.optional;
  }

  isVelocityRequired(): boolean {
    return !this.validFieldsReport.find(field => field.name === "VELOCIDADE")?.optional;
  }

  isTerminalsRequired(): boolean {
    return !this.validFieldsReport.find(field => field.name === "IDTERMINALS")?.optional;
  }

  isCompanyOrDivisionRequired(): boolean {
    return this.validFieldsReport.some(
      field => (field.name === "IDEMPRESA" || field.name === "IDDIVISAO") && !field.optional
    );
  }

  isRemoveKmDisable(): boolean {
    return !this.validFieldsReport.find(field => field.name === "REMOVERKM")?.optional;
  }

  isGpsJumpsDisable(): boolean {
    return !this.validFieldsReport.find(field => field.name === "SALTOGPS")?.optional;
  }

  isFilePdfDisable(): boolean {
    return !this.validFieldsReport.find(field => field.name === "PDF")?.optional;
  }

  isFileExcelDisable(): boolean {
    return !this.validFieldsReport.find(field => field.name === "XLSX")?.optional;
  }

}
