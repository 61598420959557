import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

import { ManagerViewModel } from "src/app/models/manager-view.model";

import { MatTableDataSource } from "@angular/material/table";
import { PageEvent } from "@angular/material/paginator";
import { AnalyzeReportSupervisorModelPaginated } from "src/app/models/analyze-report-supervisor-model-paginated";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";

import { AnalyzeTripService } from "src/app/services/analyze-trip.service";
import { MatDialog } from "@angular/material/dialog";
import Swal from "sweetalert2";

@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.scss']
})
export class ManagerComponent implements OnInit {
  public analyzeTripService: AnalyzeTripService;
  public datePipe: DatePipe;

  public analyzeReportSupervisorModelsPaginated: AnalyzeReportSupervisorModelPaginated;
  public managerViewModelModel: ManagerViewModel;
  public errorMessage;
  public analyzeReportSupervisorsPage: AnalyzeReportSupervisorModelPaginated;
  public managersView: MatTableDataSource<any> =
    new MatTableDataSource<any>();
  public tableColumns: string[];
  public typeFieldSelect = [
    { name: "Nome", value: "NAME" },
  ];

  public actualPage: PageEvent;
  public permissions: [];
  public filter = "";
  public field = "";
  public typeFieldFilterSelected;

  constructor(
    datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    public router: Router,
    analyzeTripService: AnalyzeTripService,
    public dialog: MatDialog
  ) {
    this.datePipe = datePipe;
    this.analyzeTripService = analyzeTripService;
  }

  ngOnInit(): void {
    this.typeFieldFilterSelected = this.typeFieldSelect[0].value;
    this.field = this.typeFieldFilterSelected;
    this.initialPage();
    this.loadReports(this.actualPage, this.filter, this.field);
    this.loadTableDefinitions();
  }


  private loadReports(page: PageEvent, name: string, field: string): void {
    this.spinner.show();

    this.analyzeTripService
      .getAllManager(page, name, field)
      .subscribe(
        (t) => {
          this.analyzeReportSupervisorsPage = t;
          this.managersView =
            new MatTableDataSource<ManagerViewModel>(
              this.analyzeReportSupervisorsPage.content
            );
          this.spinner.hide();
        },
        (error) => {
          console.log("Erro ao obter os gestores");
          this.spinner.hide();
        }
      );
  }

  applyFilter(): void {
    this.loadReports(this.actualPage, this.filter, this.field);
  }

  public async handleDelete(element): Promise<void> {
    console.log(element)
    Swal.fire({
      icon: "warning",
      title: "Excluir Gestor",
      html: `<h6>Tem certeza que você deseja excluir o gestor
       <small><b> ${element.name}</b>? </h6>`,
      showCloseButton: false,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: "Cancelar",
      showConfirmButton: true,
      confirmButtonText: "Confirmar",
      focusConfirm: false,
    }).then((action) => {
      if (action.isConfirmed) {
        this.deleteManager(element.uuid);
      }
    });
  }

  public async deleteManager(uuid: string): Promise<void> {

    this.spinner.show();

    this.analyzeTripService
      .deleteManager(uuid)
      .subscribe(
        (t) => {
          this.loadReports(this.actualPage, this.filter, this.field);
          this.spinner.hide();
        },
        (error) => {
          console.log("Erro ao obter os gestores");
          this.spinner.hide();
        }
      );
  }


  fieldFilter(): void {
    this.field = this.typeFieldFilterSelected;
    this.filter = "";
  }

  private loadTableDefinitions(): void {
    this.tableColumns = [
      "name",
      "nameUser",
      "update",
      "delete"
    ];
  }

  onChangePage(event: PageEvent): void {
    this.actualPage = event;
    this.loadReports(this.actualPage, this.filter, this.field);
  }

  private initialPage(): void {
    this.actualPage = new PageEvent();
    this.actualPage.pageIndex = 0;
    this.actualPage.pageSize = 20;
  }

}

