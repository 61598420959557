<div class="main-page consultas">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-12 my-2">
                <h1>
                    <a class="btn btn-voltar text-center mr-2" (click)="this.router.navigate(['/consultations'])"
                        data-title="Voltar"><i class="fas fa-arrow-left"></i></a>
                    Consulta de análise do operador
                </h1>
            </div>
        </div>
        <div *ngIf="analyzeReportOperatorsPage?.page.totalElements === 0">
            <div class="row no-gutters mt-3">
                <p>Nenhum registro até o momento...</p>
            </div>
            <div class="row no-gutters mt-3">
                <a (click)="this.router.navigate(['/analise-operacional/create'])" mat-button color="primary"
                    class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
                    Gerar uma nova análise
                </a>
            </div>
        </div>
        <div class="row no-gutters mt-3">
            <div class="mat-elevation table-content">
                <div fxLayout fxLayoutAlign="center center">
                    <mat-form-field fxFlex="10%" class="ml-2">
                        <mat-label>Campo Filtro</mat-label>
                        <mat-select (selectionChange)="this.fieldFilter()" [(value)]="typeFieldFilterSelected"
                            [(ngModel)]="typeFieldFilterSelected">
                            <mat-option *ngFor="let type of typeFieldSelect" [value]="type.value">
                                {{ type.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="40%" class="ml-2">
                        <mat-label>Filtrar por pacote</mat-label>
                        <input matInput autocomplete="off" (keyup)="this.applyFilter()" [(ngModel)]="filter"
                            style="text-transform: uppercase;" #input>
                    </mat-form-field>
                </div>
                <table mat-table [dataSource]="analyzesOperators" matSort class="mat-elevation-z8">
                    <ng-container matColumnDef="dateStart">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Inicio Viagem</th>
                        <td mat-cell *matCellDef="let element">{{ element.formatted_analyze_start_date}}</td>
                    </ng-container>
                    <ng-container matColumnDef="dateEnd">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Fim Viagem.</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.formatted_analyze_end_date }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="plate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Placa</th>
                        <td mat-cell *matCellDef="let element">{{ element.plate }}</td>
                    </ng-container>
                    <ng-container matColumnDef="operationalBase">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Base Oper.</th>
                        <td mat-cell *matCellDef="let element">{{ element.name_base }}</td>
                    </ng-container>
                    <ng-container matColumnDef="driverName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Motorista</th>
                        <td mat-cell *matCellDef="let element">{{ element.name_driver ? element.name_driver :
                            element.name_driver_manual }}</td>
                    </ng-container>

                    <ng-container matColumnDef="packageNumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Núm. Pacote</th>
                        <td mat-cell *matCellDef="let element"> {{ element.package_number }} </td>
                    </ng-container>

                    <ng-container matColumnDef="state">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
                        <td mat-cell *matCellDef="let element"> {{ element.operator_analyze_state }} </td>
                    </ng-container>

                    <ng-container matColumnDef="filePdfAnalytical">
                        <th mat-header-cell *matHeaderCellDef>Ref. Analitico</th>
                        <td mat-cell *matCellDef="let element">
                            <span
                                *ngIf="element.minio_analytic_pdf && element.status_report_analyze_enum === 'FINISHED'"
                                class="alerta mr-2" data-title="Arquivo PDF" matTooltip="Arquivo PDF">
                                <a (click)="exportReport(element.minio_analytic_pdf)" class="btn btn-link">
                                    <img height="20" src="assets/img/file-pdf.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="filePdfSynthetic">
                        <th mat-header-cell *matHeaderCellDef>Ref. Sintetico</th>
                        <td mat-cell *matCellDef="let element">
                            <span
                                *ngIf="element.minio_synthetic_pdf && element.status_report_analyze_enum === 'FINISHED'"
                                class="alerta mr-2" data-title="Arquivo PDF" matTooltip="Arquivo PDF">
                                <a (click)="exportReport(element.minio_synthetic_pdf)" class="btn btn-link">
                                    <img height="20" src="assets/img/file-pdf.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="statusReport">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status Relatorio</th>
                        <td mat-cell *matCellDef="let element"
                            [ngStyle]="getStatusStyle(element.status_report_analyze_enum)">
                            <span *ngIf="element.status_report_analyze_enum === 'ERROR'; else successProcessing">
                                <span style="color: red;">ERRO</span>
                            </span>
                            <ng-template #successProcessing>
                                <span *ngIf="element.status_report_analyze_enum === 'FINISHED'"
                                    style="color: green;">SUCESSO</span>
                                <span *ngIf="element.status_report_analyze_enum === 'PROCESSING'"
                                    style="color: blue;">EM PROCESSAMENTO</span>
                                <span *ngIf="element.status_report_analyze_enum === 'REPORT_EMPTY'"
                                    style="color: purple;">SEM INFRAÇÕES</span>
                            </ng-template>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="operatorHandling">
                        <th mat-header-cell *matHeaderCellDef>Trat. Operador</th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Tratar" matTooltip="Tratar" *ngIf="element.status_report_analyze_enum === 'FINISHED' ||
                            element.status_report_analyze_enum === 'REPORT_EMPTY'">
                                <a (click)="openToTreatment(element.uuid)" class="btn btn-link" target="_blank">
                                    <img height="20" src="assets/img/operator-handling.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="viewOperatorHandling">
                        <th mat-header-cell *matHeaderCellDef> Visu. Trat.</th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Tratativa operador" matTooltip="Tratativa operador">
                                <a (click)="openInNewTab(element.uuid)" class="btn btn-link">
                                    <img height="20" src="assets/img/view.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="viewOperatorMonitoring">
                        <th mat-header-cell *matHeaderCellDef> Solicitação de Monitoramento</th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.uuid_monitoring != null" class="alerta mr-2"
                                data-title="Solicitação de Monitoramento" matTooltip="Solicitação de Monitoramento">
                                <a (click)="openMonitoringRequestPopup(element.uuid_monitoring)" class="btn btn-link">
                                    <img height="20" src="assets/img/view.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="sentManager">
                        <th mat-header-cell *matHeaderCellDef> Env. Gestor</th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Enviar" matTooltip="Enviar" *ngIf="element.status_report_analyze_enum === 'FINISHED' ||
                            element.status_report_analyze_enum === 'REPORT_EMPTY'">
                                <a (click)="handleSentManager(element.uuid)" class="btn btn-link">
                                    <img height="20" src="assets/img/sent-manager.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="finishReport">
                        <th mat-header-cell *matHeaderCellDef> Finalizar Sem Infração</th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Finalizar" matTooltip="Finalizar" *ngIf="element.status_report_analyze_enum === 'FINISHED' ||
                            element.status_report_analyze_enum === 'REPORT_EMPTY'">
                                <a (click)="handleSentFinishReporth(element)" class="btn btn-link">
                                    <img height="20" src="assets/img/sent-operator-finish.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="update">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Editar" matTooltip="Editar">
                                <a (click)="openToUpdate(element.uuid)" class="btn btn-link" target="_blank">
                                    <img height="20" src="assets/img/edit.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button (click)="handleDelete(element)" class="btn btn-link" matTooltip="Apagar">
                                <img height="20" src="assets/img/trash-cadastro.svg" alt="" />
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[10, 20, 40]" [pageSize]="analyzeReportOperatorsPage.page.size"
                    (page)="onChangePage($event)" [length]="analyzeReportOperatorsPage.page.totalElements"
                    showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</div>