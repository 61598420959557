import { BaseModel } from "./hateoas/base-model";
import { MonitoringDestinationsModel } from "./monitoring-destinations-model";

export class MonitoringModel extends BaseModel {
  monitoringStartDate?: string;
  idDriver?: number;
  idTerminal?: number;
  idCompany?: number;
  idDivision?: number;
  tripNumber?: string;
  userId?: number;
  driverName?: string;
  expectedDepartureDate?: string;
  address?: string;
  longitude?: string;
  latitude?: string;
  monitoringDestinations?: MonitoringDestinationsModel[];
}
