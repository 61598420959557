<div class="main-page cadastros">
    <div class="container-fluid">
        <app-title>Geração de Relatório</app-title>

        <div class="row mt-3 no-gutters p-4 row-form">
            <div class="col-12">
                <form [formGroup]="reportingRuleForm" (ngSubmit)="submitForm()">
                    <h2 class="mb-3">Dados do Relatório</h2>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Tipo do Relatorio</mat-label>
                                <mat-select formControlName="typeReport" [(value)]="typeReportSelected"
                                    (selectionChange)="onTypeReportChange($event)" required>
                                    <mat-option *ngFor="let type of reportTypes" [value]="type.uuid"
                                        style="text-transform: uppercase;">
                                        {{ type.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf="isReportModelVisible()" class="form-group col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Modelo de Relatório</mat-label>
                                <mat-select formControlName="modelReport" [(value)]="modelReportSelected"
                                    [(value)]="modelReportSelected" [required]="isReportModelRequired()">
                                    <mat-option *ngFor="let type of typesReport" [value]="type.value">
                                        {{ type.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-6">
                            <input type="text" class="form-control" placeholder="Digite um endereço ou localidade..."
                                autocorrect="off" autocapitalize="off" spellcheck="off" #search
                                (keydown.enter)="$event.preventDefault()" [disabled]="!isLatitudeVisible()" />
                        </div>
                    </div>

                    <div class="form-row">
                        <div *ngIf="isDateStartVisible()" class="form-group col-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Data Início</mat-label>
                                <input formControlName="dateStart" type="datetime-local" matInput
                                    placeholder="Data Início" [required]="isDateStartRequired()" />
                            </mat-form-field>
                        </div>

                        <div *ngIf="isDateEndVisible()" class="form-group col-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Data Fim</mat-label>
                                <input formControlName="dateEnd" type="datetime-local" matInput placeholder="Data Fim"
                                    [required]="isDateEndRequired()" />
                            </mat-form-field>
                        </div>


                        <div *ngIf="isCompanyOrDivisionVisible()" class="form-group col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Modelo de Empresa</mat-label>
                                <mat-select formControlName="selectCompany" [(value)]="companyFilterSelected"
                                    [(ngModel)]="companyFilterSelected" (selectionChange)="this.filterCompany()"
                                    [required]="isCompanyOrDivisionRequired()">
                                    <mat-option *ngFor="let type of companiesSelect" [value]="type.name">
                                        {{ type.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div *ngIf="isCompanyOrDivisionVisible()" class="form-group col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Empresa</mat-label>
                                <input type="text" matInput [formControl]="controlCompany"
                                    [matAutocomplete]="autoEmpresa" [required]="isCompanyOrDivisionRequired()">
                                <mat-autocomplete #autoEmpresa="matAutocomplete" [displayWith]="displayCompany">
                                    <mat-option *ngFor="let type of companiesFilter" [value]="type">
                                        {{ type.nmNome }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="form-row">
                        <div *ngIf="isLatitudeVisible()" class="form-group col-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Latitude</mat-label>
                                <input formControlName="latitude" matInput placeholder="Latitude"
                                    [required]="isLatitudeRequired()" inputmode="numeric" min="0" />
                            </mat-form-field>
                        </div>

                        <div *ngIf="isLongitudeVisible()" class="form-group col-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Longitude</mat-label>
                                <input formControlName="longitude" matInput placeholder="Longitude"
                                    [required]="isLongitudeRequired()" inputmode="numeric" min="0" />
                            </mat-form-field>
                        </div>

                        <div *ngIf="isRadiusVisible()" class="form-group col-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Raio</mat-label>
                                <input formControlName="radius" matInput placeholder="Raio"
                                    [required]="isRadiusRequired()" inputmode="numeric" min="0" />
                            </mat-form-field>
                        </div>

                        <div *ngIf="isVelocityVisible()" class="form-group col-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Velocidade</mat-label>
                                <input formControlName="velocity" matInput placeholder="Velocidade"
                                    [required]="isVelocityRequired()" inputmode="numeric" min="0" />
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="form-row">

                        <div *ngIf="isTerminalsVisible()" class="form-group col-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Terminais</mat-label>
                                <mat-select formControlName="terminals" placeholder="Selecione Terminais"
                                    [required]="isTerminalsRequired()" multiple>
                                    <mat-option *ngFor="let terminal of terminals" [value]="terminal.id">
                                        {{ terminal.cdPlaca }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                    </div>

                    <div class="form-row">
                        <div *ngIf="isFilePdfVisible()" class="form-group col-3" style="margin-top: 15px;">
                            <mat-checkbox formControlName="filePdf" [disabled]="isFilePdfDisable()"
                                [checked]="isFilePdfDisable() ? true : null" color="primary">
                                Gerar Arquivo PDF ?
                            </mat-checkbox>
                        </div>

                        <div *ngIf="isFileExcelVisible()" class="form-group col-3" style="margin-top: 15px;">
                            <mat-checkbox formControlName="fileExcel" [disabled]="isFileExcelDisable()"
                                [checked]="isFileExcelDisable() ? true : null" color="primary">
                                Gerar Arquivo EXCEL ?
                            </mat-checkbox>
                        </div>

                        <div *ngIf="isGpsJumpsVisible()" class="form-group col-3" style="margin-top: 15px;">
                            <mat-checkbox formControlName="gpsJumps" [disabled]="isGpsJumpsDisable()"
                                [checked]="isGpsJumpsDisable() ? true : null" color="primary">
                                Remover o salto GPS ?
                            </mat-checkbox>
                        </div>

                        <div *ngIf="isRemoveKmVisible()" class="form-group col-3" style="margin-top: 15px;">
                            <mat-checkbox formControlName="removeKm" [disabled]="isRemoveKmDisable()"
                                [checked]="isRemoveKmDisable() ? true : null" color="primary">
                                Remover dados com KM inferior a 40 no mês ?
                            </mat-checkbox>
                        </div>
                    </div>



                    <div *ngIf="errorMessage" class="alert alert-danger">
                        {{ errorMessage }}
                    </div>

                    <div class="form-group mt-2 mb-0 text-center">
                        <back-button></back-button>
                        <button type="submit" [disabled]="!reportingRuleForm.valid"
                            class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
                            Gerar Relatório
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>