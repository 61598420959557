import {BaseModel} from './hateoas/base-model';

export class TrackerFileModel extends BaseModel {
    uuid?: string;
    terminalId?: string;
    fileName?: string;
    uploadStatus?: string;
    objectName?: string;
    positionDate?: Date;
    onlineDate?: Date;
    messageUuid?: string;
    fileLink?: string;
}
