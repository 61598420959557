<div class="main-page cadastros">
    <div class="container-fluid">
        <app-title>Criar Solicitação de Monitoramento</app-title>

        <div class="row mt-3 no-gutters p-4 row-form">
            <div class="col-12">
                <form [formGroup]="reportOperationalForm" (ngSubmit)="submitForm()">
                    <h2 class="mb-3">Dados da Solicitação de Monitoramento</h2>
                    <div class="form-row">
                        <div class="form-group col-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Data Inicio</mat-label>
                                <input formControlName="dateStart" type="datetime-local" matInput
                                    placeholder="Data Inicio" required />
                            </mat-form-field>
                        </div>
                        <div class="form-group col-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Núm. Viagem</mat-label>
                                <input formControlName="tripNumber" style="text-transform: uppercase" matInput
                                    placeholder="Núm. Viagem" required />
                            </mat-form-field>
                        </div>
                        <div class="form-group col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Tipo de Empresa</mat-label>
                                <mat-select formControlName="selectCompany" [(value)]="companyFilterSelected"
                                    [(ngModel)]="companyFilterSelected" (selectionChange)="this.filterCompany()">
                                    <mat-option *ngFor="let type of companiesSelect" [value]="type.name">
                                        {{ type.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="form-group col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Empresa</mat-label>
                                <input type="text" matInput [formControl]="controlCompany"
                                    [matAutocomplete]="autoEmpresa" required>
                                <mat-autocomplete #autoEmpresa="matAutocomplete" [displayWith]="displayCompany">
                                    <mat-option *ngFor="let type of this.companiesFilter" [value]="type">
                                        {{type.nmNome}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="form-row">

                        <div class="form-group col-md-6">
                            <mat-form-field appearance="outline" *ngIf="controlCompany.value != ''">
                                <mat-label>Placa / Frota</mat-label>
                                <input type="text" matInput [formControl]="controlVehicle"
                                    [matAutocomplete]="autoTerminal" required />
                                <mat-autocomplete #autoTerminal="matAutocomplete" [displayWith]="displayTerminal">
                                    <mat-option *ngFor="let type of this.vehiclesFiltered | async" [value]="type">
                                        {{type.cdPlaca}}/{{type.frota}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>


                        <div class="form-group col-md-3">
                            <mat-form-field appearance="outline" *ngIf="controlCompany.value != ''">
                                <mat-label>Nome Motorista</mat-label>
                                <input formControlName="driverName" style="text-transform: uppercase" matInput
                                    placeholder="Nome Motorista" required />
                            </mat-form-field>
                        </div>


                    </div>

                    <h2 class="mb-3">Origem da Monitoração</h2>

                    <div class="form-row">
                        
                        <!-- Campo: Data prevista da origem -->
                        <div class="form-group col-3">
                            <mat-form-field appearance="outline">
                            <mat-label>Data Prevista para Viagem</mat-label>
                            <input formControlName="expectedDepartureDate" type="datetime-local" matInput required />
                            </mat-form-field>
                        </div>
                        
                        <!-- Campo: Endereço da origem (abertura de popup ao clicar) -->
                        <div class="form-group col-lg-3">
                            <mat-form-field appearance="outline">
                            <mat-label>Origem</mat-label>
                            <input formControlName="address" style="text-transform: uppercase"
                                    matInput readonly
                                    (click)="openOriginAddressPopup()" placeholder="Clique para selecionar a origem" required />
                            </mat-form-field>
                        </div>
                        
                        <!-- Latitude da origem -->
                        <div class="form-group col-md-3">
                            <mat-form-field appearance="outline">
                            <mat-label>Latitude</mat-label>
                            <input formControlName="latitude" matInput readonly placeholder="Latitude" />
                            </mat-form-field>
                        </div>
                        
                        <!-- Longitude da origem -->
                        <div class="form-group col-md-3">
                            <mat-form-field appearance="outline">
                            <mat-label>Longitude</mat-label>
                            <input formControlName="longitude" matInput readonly placeholder="Longitude" />
                            </mat-form-field>
                        </div>
  
                    </div>

                    <div formArrayName="listDestination" *ngIf="controlCompany.value != ''">
                        <div *ngFor="let item of destinationItems.controls; let itemIndex = index"
                            [formGroupName]="itemIndex">
                            <input *ngIf="companyId" type="hidden" formControlName="destinationId" />

                            <div class="form-row">
                                <!-- Endereço -->
                                <div class="form-group col-lg-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Destino</mat-label>
                                        <input formControlName="address" style="text-transform: uppercase"
                                            [disabled]="true" readonly matInput placeholder="Destino" required />
                                    </mat-form-field>
                                </div>

                                <!-- Latitude -->
                                <div class="form-group col-lg-3">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Latitude</mat-label>
                                        <input formControlName="latitude" [disabled]="true" matInput
                                            placeholder="Latitude" readonly />
                                    </mat-form-field>
                                </div>

                                <!-- Longitude -->
                                <div class="form-group col-lg-3">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Longitude</mat-label>
                                        <input formControlName="longitude" [disabled]="true" matInput
                                            placeholder="Longitude" readonly />
                                    </mat-form-field>
                                </div>

                                <!-- Botão de excluir -->
                                <div class="form-group col-lg-2" style="padding-top: 1%;">
                                    <a (click)="deleteDestinationItem(itemIndex)">
                                        Excluir Destino <img src="assets/img/trash.svg" class="mb-1" height="20"
                                            alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="form-group col-12 text-center" *ngIf="controlCompany.value != ''">
                        <a class="repeat-line" (click)="addDestination()">
                            <img src="assets/img/plus.svg" class="mb-0" height="20" alt="" />
                            Adicionar novo Destino
                        </a>

                    </div>

                    <div *ngIf="errorMessage" class="alert alert-danger">
                        {{ errorMessage }}
                    </div>

                    <div class="form-group mt-2 mb-0 text-center">
                        <back-button></back-button>
                        <button type="submit" [disabled]="!reportOperationalForm.valid"
                            class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
                            Criar Solicitação
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>