import {Injectable} from '@angular/core';
import {HateoasBaseService} from './hateoas-base.service';
import {HttpUtil} from '../utils/http-util';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {TrackerFilePaginatedModel} from '../models/tracker-file-paginated-model';
import {PageEvent} from '@angular/material/paginator';
import {TrackerFileFilter} from '../pages/tracker-file/tracker-file-filter';
import {TrackerFileModel} from '../models/tracker-file-model';
import {TrackerModel} from '../models/tracker-model';

@Injectable({
  providedIn: 'root'
})
export class TrackerFileService extends HateoasBaseService {

  // tslint:disable-next-line:variable-name
  private _httpUtil: HttpUtil;
  private resourceName = 'tracker-files';

  constructor(http: HttpClient,
              httpUtil: HttpUtil) {
    super(http, httpUtil);
    this._httpUtil = httpUtil;
  }

  public getPaginated(page: PageEvent, direction: string, filter: TrackerFileFilter): Observable<TrackerFilePaginatedModel> {
    const params = this._httpUtil.buildPaginator(page, direction);
    if (filter) {
      if ( filter.terminalId && filter.terminalId > 0) {
        params.set('terminalId', filter.terminalId.toString());
      }
      if ( filter.initialDate ) {
        params.set('initialDate', this.convertUTCtoLocal(filter.initialDate));
      }
      if ( filter.finalDate ) {
        params.set('finalDate', this.convertUTCtoLocal(filter.finalDate));
      }
      if (filter.fileType !== undefined && filter.fileType !== null && filter.fileType !== 'BOTH'){
        params.set('fileType', filter.fileType);
      }
      if (filter.sourceDevice !== undefined && filter.sourceDevice !== null && filter.sourceDevice !== 'BOTH'){
        params.set('sourceDevice', filter.sourceDevice);
      }

    }

    return this.executeActionOnResource(this.resourceName, 'get-all', null, null, params);
  }

  public getLink(trackerFile: TrackerFileModel): Observable<TrackerModel> {
    return this.executeActionOnResource(this.resourceName, 'get-link', trackerFile, null, null);
  }

  protected getApiBaseUrl(): string {
    return environment.apisConfig.seteraTrackingHardwareApi.apiBaseUrl;
  }

  protected getMicroServiceBaseUrl(): string {
    return environment.apisConfig.seteraTrackingHardwareApi.microServiceBaseUrl;
  }

  convertUTCtoLocal(utcDate: Date): string {
    const tzoffset = utcDate.getTimezoneOffset() * 60000; //offset in milliseconds
    return new Date(utcDate.valueOf() - tzoffset)
        .toISOString()
        .slice(0, -1);
  }
}
