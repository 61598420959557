
import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

import { MatTableDataSource } from "@angular/material/table";
import { PageEvent } from "@angular/material/paginator";
import { AnalyzeReportSupervisorModelPaginated } from "src/app/models/analyze-report-supervisor-model-paginated";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { ManagerViewModel } from "src/app/models/manager-view.model";
import Swal from "sweetalert2";
import { ReportEngineService } from "src/app/services/report-engine.service";

@Component({
  selector: 'app-reporting-rule',
  templateUrl: './reporting-rule.component.html',
  styleUrls: ['./reporting-rule.component.scss']
})
export class ReportingRuleComponent implements OnInit {
  public reportEngineService: ReportEngineService;
  public datePipe: DatePipe;

  public managerViewModelModel: ManagerViewModel;
  public errorMessage;
  public parametersModelPage: AnalyzeReportSupervisorModelPaginated;
  public parametersTypes: MatTableDataSource<any> =
    new MatTableDataSource<any>();
  public tableColumns: string[];
  public typeFieldSelect = [
    { name: "Tipo de Relatorio", value: "REPORT_TYPE_NAME" },
    { name: "Descrição da Regra", value: "PARAMETERS_TYPE_NAME" },
  ];

  public actualPage: PageEvent;
  public permissions: [];
  public filter = "";
  public field = "";
  public typeFieldFilterSelected;

  constructor(
    datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    public router: Router,
    reportEngineService: ReportEngineService,
    public dialog: MatDialog
  ) {
    this.datePipe = datePipe;
    this.reportEngineService = reportEngineService;
  }

  ngOnInit(): void {
    this.typeFieldFilterSelected = this.typeFieldSelect[0].value;
    this.field = this.typeFieldFilterSelected;
    this.initialPage();
    this.loadReports(this.actualPage, this.filter, this.field);
    this.loadTableDefinitions();
  }

  private loadReports(page: PageEvent, name: string, field: string): void {
    this.spinner.show();

    this.reportEngineService
      .getAllReportingRule(page, name, field)
      .subscribe(
        (t) => {
          this.parametersModelPage = t;
          this.parametersTypes =
            new MatTableDataSource<any>(
              this.parametersModelPage.content
            );
          this.spinner.hide();
        },
        (error) => {
          console.log("Erro ao obter os parâmetros");
          this.spinner.hide();
        }
      );
  }

  applyFilter(): void {
    this.loadReports(this.actualPage, this.filter, this.field);
  }

  public async handleDelete(element): Promise<void> {
    console.log(element)
    Swal.fire({
      icon: "warning",
      title: "Excluir Regra do Relatorio",
      html: `<h6>Tem certeza que você deseja excluir a regra
       <small><b> ${element.parameters_type_name}</b>? </h6>`,
      showCloseButton: false,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: "Cancelar",
      showConfirmButton: true,
      confirmButtonText: "Confirmar",
      focusConfirm: false,
    }).then((action) => {
      if (action.isConfirmed) {
        this.deleteParameters(element.uuid);
      }
    });
  }

  public async deleteParameters(uuid: string): Promise<void> {

    this.spinner.show();

    this.reportEngineService
      .deleteReportingRule(uuid)
      .subscribe(
        (t) => {
          this.loadReports(this.actualPage, this.filter, this.field);
          this.spinner.hide();
        },
        (error) => {
          console.log("Erro ao obter os parâmetros");
          this.spinner.hide();
        }
      );
  }


  fieldFilter(): void {
    this.field = this.typeFieldFilterSelected;
    this.filter = "";
  }

  private loadTableDefinitions(): void {
    this.tableColumns = [
      "reportTypeName",
      "parametersTypeName",
      "valueRule",
      "update",
      "delete"
    ];
  }

  onChangePage(event: PageEvent): void {
    this.actualPage = event;
    this.loadReports(this.actualPage, this.filter, this.field);
  }

  private initialPage(): void {
    this.actualPage = new PageEvent();
    this.actualPage.pageIndex = 0;
    this.actualPage.pageSize = 20;
  }

}
