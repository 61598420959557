import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-parameter-modal',
  templateUrl: './parameter-modal.component.html',
  styleUrls: ['./parameter-modal.component.scss']
})
export class ParameterModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { formattedParameters: string[] }) { }

  ngOnInit(): void {
  }

}
