import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AnalyzeTripService } from "../../../services/analyze-trip.service";
import { toCamel } from "snake-camel";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CoreService } from "../../../services/migrate/api.core.service";
import { USER_DATA } from "../../../utils/constants.util";
import { ToolsService } from "../../../services/migrate/tools.service";
import { Empresa } from "../../../models/markerModel";
import { STORAGE_KEY_CURRENT_USER } from "../../../storageCore/constStorageKeys";
import storageX from "../../../storageCore/storageX";

import { SearchComponent } from "../../../components/maps/search/search.component";
import { MatDialog } from "@angular/material/dialog";
import { MonitoringModel } from "../../../models/monitoring-model";
import { MonitoringDestinationsModel } from "../../../models/monitoring-destinations-model";

@Component({
  selector: 'app-monitoring-request-edit',
  templateUrl: './monitoring-request-edit.component.html',
  styleUrls: ['./monitoring-request-edit.component.scss']
})
export class MonitoringRequestEditComponent implements OnInit {
  public analyzeTripService: AnalyzeTripService;
  public serviceCore: CoreService;

  public monitoringModel: MonitoringModel;

  public controlCompany = new FormControl();
  public controlVehicle = new FormControl();

  public companys = new Array<any>();
  public companysFiltered = new Observable<Array<any>>();
  public vehicles = new Array<any>();
  public vehiclesFiltered = new Observable<Array<any>>();

  public companiesFilter: any = [];
  public companiesSelect: any = [];

  public reportOperationalForm: FormGroup;

  public monitoringOld: any;
  public monitoringUuid = "";

  public companyFilterSelected;

  public errorMessage;

  ngOnInit(): void {


    this.reportOperationalForm = this.formBuilder.group(
      {
        selectCompany: [""],
        dateStart: ["", Validators.required],
        tripNumber: ["", Validators.required],
        driverName: ["", Validators.required],
        expectedDepartureDate: ["", Validators.required],
        address: ["", Validators.required],
        latitude: ["", Validators.required],
        longitude: ["", Validators.required],
        listDestination: this.formBuilder.array([]),
      },
      { updateOn: "blur" }
    );

    this.companysFiltered = this.controlCompany.valueChanges.pipe(
      map((value) => (typeof value === "string" ? value : value.id)),
      map((name) =>
        name ? this.filterValuesCompany(name) : this.companys.slice()
      )
    );

    this.vehiclesFiltered = this.controlVehicle.valueChanges.pipe(
      map((value) => (typeof value === 'string' ? value : value.cdPlaca)),
      map((name) =>
        name
          ? this.filterValuesTerminal(name)
          : this.vehicles.slice()
      )
    );

    this.monitoringUuid = this.activatedRoute.snapshot.params.uuid;

    this.loadDataElements(this.monitoringUuid);
  }

  constructor(
    private tools: ToolsService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    analyzeTripService: AnalyzeTripService,
    apiserviceCore: CoreService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {
    this.serviceCore = apiserviceCore;
    this.analyzeTripService = analyzeTripService;
  }

  public async loadDataElements(monitoringUuid: string) {
    try {
      this.tools.builder().spinner.show();

      const companys = await this.serviceCore.get<any>({
        url: "v2/companys",
        params: {
          profileId: USER_DATA().profileId,
          size: "5000",
          page: "0",
          sort: "nome",
          sortDirections: "ASC",
        },
      });

      this.companys = companys?.elements;
      this.companys = this.companys.sort((a, b) =>
        a.nmNome.localeCompare(b.nmNome)
      );
      this.controlCompany.setValue("");

      const terminals = await this.serviceCore.get<any>({
        url: "v2/terminals",
        params: {
          profileId: USER_DATA().profileId,
          size: "5000",
          page: "0",
          sortDirections: "ASC",
        },
      });

      this.vehicles = terminals.elements;
      this.vehicles = this.vehicles.sort((a, b) =>
        a.cdPlaca.localeCompare(b.cdPlaca)
      );
      this.controlVehicle.setValue("");

      this.companiesSelect = [
        { name: "Divisão", value: 1 },
        { name: "Matriz", value: 2 },
        { name: "Filial", value: 3 },
      ];

      this.analyzeTripService
        .getByUuidMonitoring(monitoringUuid)
        .subscribe((resp) => {
          this.monitoringOld = toCamel(resp);
          this.fillTransporterData();
        });

      this.tools.builder().spinner.hide();
    } catch (error) {
      this.tools.builder().spinner.hide();
      throw new EvalError(error);
    }
  }

  private fillTransporterData(): void {
    // Preenche campos principais
    this.reportOperationalForm.patchValue({
      tripNumber: this.monitoringOld.tripNumber,
      driverName: this.monitoringOld.driverName.toUpperCase(),
      dateStart: this.monitoringOld.monitoringStartDate,
      expectedDepartureDate: this.monitoringOld.expectedDepartureDate,
      address: this.monitoringOld.address,
      latitude: this.monitoringOld.latitude,
      longitude: this.monitoringOld.longitude
    });

    const selectedCompany = this.companys.find(
      c => c.id === this.monitoringOld.idCompany || c.id === this.monitoringOld.idDivision
    );

    if (selectedCompany) {
      if (selectedCompany.cnpj == null) {
        this.companyFilterSelected = "Divisão";
      } else if (selectedCompany.idEmpresaMatriz == null) {
        this.companyFilterSelected = "Matriz";
      } else {
        this.companyFilterSelected = "Filial";
      }

      this.reportOperationalForm.get("selectCompany")?.patchValue(this.companyFilterSelected);
      this.filterCompany();

      setTimeout(() => {
        this.controlCompany.setValue(selectedCompany);

        setTimeout(() => {
          const selectedVehicle = this.vehicles.find(v => v.id === this.monitoringOld.idTerminal);
          if (selectedVehicle) {
            this.controlVehicle.setValue(selectedVehicle);
          }
        }, 200);
      }, 200);
    }

    // 🔽 Preencher destinos no FormArray
    const destinos = this.reportOperationalForm.get('listDestination') as FormArray;
    destinos.clear(); // Limpa qualquer dado anterior

    if (this.monitoringOld.monitoringDestinations && this.monitoringOld.monitoringDestinations.length > 0) {
      this.monitoringOld.monitoringDestinations.forEach((dest: any) => {
        destinos.push(this.formBuilder.group({
          address: [dest.addressname, Validators.required],
          latitude: [dest.latitude, Validators.required],
          longitude: [dest.longitude, Validators.required]
        }));
      });
    }
  }

  openOriginAddressPopup(): void {
    const dialogRef = this.dialog.open(SearchComponent, {
      width: '400px',
      data: { mode: 'fill_address' }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reportOperationalForm.patchValue({
          address: result.address,
          latitude: result.latitude,
          longitude: result.longitude
        });
      }
    });
  }


  addDestination(): void {
    const dialogRef = this.dialog.open(SearchComponent, {
      width: '400px',
      data: { mode: 'fill_address' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Adiciona o novo destino ao formArray
        const destinos = this.reportOperationalForm.get('listDestination') as FormArray;
        destinos.push(this.formBuilder.group({
          address: [result.address, Validators.required],
          latitude: [result.latitude, Validators.required],
          longitude: [result.longitude, Validators.required]
        }));
      }
    });
  }

  someComplete(task: any): boolean {
    // Implemente a lógica para verificar se alguma subtarefa está completa
    return task.subtasks.some((subtask: any) => subtask.completed);
  }

  displayCompany(empresa: Empresa): string {
    return empresa && empresa.nmNome ? empresa.nmNome : "";
  }

  displayDriver(motorista: any): string {
    return motorista && motorista.nmNome ? motorista.nmNome : "";
  }

  displayTerminal(terminal: any): string {
    var terminalPlaca = terminal.cdPlaca ? terminal.cdPlaca : "";
    var terminalFrota = terminal.frota ? terminal.frota : "";

    return terminal && terminal.cdPlaca
      ? terminalPlaca + "/" + terminalFrota
      : "";
  }

  get destinationItems() {
    return this.reportOperationalForm.get('listDestination') as FormArray;
  }

  // Método para adicionar um novo endereço ao FormArray
  addDestinationItem(): void {
    this.destinationItems.push(this.formBuilder.group({
      destinationId: [null],  // Campo oculto
      address: ['', Validators.required],  // Campo de endereço obrigatório
    }));
  }

  // Método para remover um endereço do FormArray
  deleteDestinationItem(index: number): void {
    const destinos = this.reportOperationalForm.get('listDestination') as FormArray;
    destinos.removeAt(index);
  }

  public filterValuesTerminal(value: string): Array<any> {
    const filterValue = value.toLowerCase();
    return this.vehicles
      .sort((a, b) => a.cdPlaca.localeCompare(b.cdPlaca))
      .filter((client) => client.cdPlaca.toLowerCase().includes(filterValue));
  }

  public filterCompany() {
    this.controlCompany.setValue("");
    var companyModel = this.companyFilterSelected;
    var empresas: any = [];

    if (companyModel == "Divisão") {
      this.companys.forEach((element) => {
        if (element.cnpj == null) {
          empresas.push(element);
        }
      });
    }

    if (companyModel == "Matriz") {
      this.companys.forEach((element: any) => {
        if (element.cnpj != null && element.idEmpresaMatriz == null) {
          empresas.push(element);
        }
      });
    }

    if (companyModel == "Filial") {
      this.companys.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz != null) {
          empresas.push(element);
        }
      });
    }

    this.companiesFilter = empresas;

    this.companysFiltered.subscribe(this.companiesFilter);
  }

  public filterValuesCompany(value: any): Array<any> {
    const filterValue = value;

    if (this.controlCompany.value.cnpj != null) {
      if (value != null) {
        this.loadFilterCompany(value);
      }
    } else {
      if (value != null) {
        this.loadFilterDivision(value);
      }
    }

    return this.companys
      .sort((a, b) => a.nmNome.localeCompare(b.nmNome))
      .filter((client) => client.nmNome.toLowerCase().includes(filterValue));
  }

  public async loadFilterCompany(value: any) {
    try {
      this.tools.builder().spinner.show();

      const terminals = await this.serviceCore.get<any>({
        url: "v2/terminals/findByCompany",
        params: {
          profileId: USER_DATA().profileId,
          idEmpresa: value,
          size: "5000",
          page: "0",
          sortDirections: "ASC",
        },
      });

      console.log(terminals);

      this.vehicles = terminals.elements;
      this.vehicles = this.vehicles.sort((a, b) =>
        a.cdPlaca.localeCompare(b.cdPlaca)
      );

      this.controlVehicle.setValue("");

      this.tools.builder().spinner.hide();
    } catch (error) {
      this.tools.builder().spinner.hide();
      throw new EvalError(error);
    }
  }

  public async loadFilterDivision(value: any) {
    try {
      this.tools.builder().spinner.show();

      const terminals = await this.serviceCore.get<any>({
        url: "v2/terminals/findByCompany",
        params: {
          profileId: USER_DATA().profileId,
          idEmpresa: value,
          size: "5000",
          page: "0",
          sortDirections: "ASC",
        },
      });

      console.log(terminals);

      this.vehicles = terminals.elements;
      this.vehicles = this.vehicles.sort((a, b) =>
        a.cdPlaca.localeCompare(b.cdPlaca)
      );

      this.controlVehicle.setValue("");

      this.tools.builder().spinner.hide();
    } catch (error) {
      this.tools.builder().spinner.hide();
      throw new EvalError(error);
    }
  }


  public async submitForm(): Promise<void> {
    this.spinner.show();

    const values = this.reportOperationalForm.value;

    var dateStart: string = values.dateStart + ":00";
    var expectedDepartureDate: string = values.expectedDepartureDate + ":00";

    var monitoringModel: MonitoringModel = new MonitoringModel();

    //Origem da monitoração
    monitoringModel.expectedDepartureDate = expectedDepartureDate;
    monitoringModel.address = values.address;
    monitoringModel.longitude = values.longitude;
    monitoringModel.latitude = values.latitude;

    monitoringModel.monitoringStartDate = dateStart;
    monitoringModel.tripNumber = values.tripNumber;
    monitoringModel.driverName = values.driverName;

    if (this.controlCompany.value.cnpj != null) {
      monitoringModel.idCompany = this.controlCompany.value.id;
      monitoringModel.idDivision = 0;
    } else {
      monitoringModel.idDivision = this.controlCompany.value.id;
      monitoringModel.idCompany = 0;
    }
    monitoringModel.userId = storageX
      .whereKeyIs(STORAGE_KEY_CURRENT_USER)
      .get()?.id;

    // Preenche destinos com base no FormArray
    const destinosFormArray = this.reportOperationalForm.get('listDestination') as FormArray;
    const monitoringDestinations: MonitoringDestinationsModel[] = destinosFormArray.controls.map(control => {
      return {
        address: control.get('address')?.value,
        latitude: control.get('latitude')?.value,
        longitude: control.get('longitude')?.value
      } as MonitoringDestinationsModel;
    });
    monitoringModel.monitoringDestinations = monitoringDestinations;

    if (this.controlVehicle.value != "") {
      console.log(this.controlVehicle.value);
      monitoringModel.idTerminal = this.controlVehicle.value.id;
    }

    console.log(monitoringModel);

    this.analyzeTripService
      .updateMonitoring(monitoringModel, this.monitoringUuid)
      .subscribe((t) => {
        this.monitoringModel = toCamel(t) as MonitoringModel;
      });

    // Inicia o temporizador de 3 segundos
    setTimeout(() => {
      // Esconde o spinner
      this.spinner.hide();

      // Navega para a rota desejada
      this.router.navigate(["/solicitacao-monitoramento"]);
    }, 3000); // 3000 milissegundos = 3 segundos

  }

}
