<!-- Modal de visualização do arquivo-->
<div mat-dialog-title class="dialog-title">
    <h1>Visualizar Mídia</h1>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<p>Arquivo: {{trackerFile.fileName}}</p>
<div mat-dialog-content *ngIf="trackerFile.fileName.includes('.mp4')" class="tracker-video-container">
    <video controls class="tracker-video" autoplay>
        <source [src]="trackerFile.fileLink" type="video/mp4"/>
    </video>
</div>
<div mat-dialog-content *ngIf="trackerFile.fileName.includes('.jpg')" class="tracker-image-container">
    <img src="{{trackerFile.fileLink}}" alt="{{trackerFile.fileName}}" class="tracker-image"/>
</div>

