import { LoginComponent } from "./login/login.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";

// PAG.

import { MainComponent } from "./components/main/main.component";
import { RegistrationsComponent } from "./pages/registrations/registrations.component";
import { ConsultationsComponent } from "./pages/consultations/consultations.component";
import { ReportsComponent } from "./pages/reports/reports.component";
// users
import { CreateUserComponent } from "./pages/users/create.component";
import { EditUserComponent } from "./pages/users/edit.component";
import { CloneUserComponent } from "./pages/users/clone.component";
import { ViewUserComponent } from "./pages/users/view.component";

// companies
import { CompanyCreateComponent } from "./pages/company/form/create.component";
import { CompanyEditComponent } from "./pages/company/form/edit.component";
import { CompanyCloneComponent } from "./pages/company/form/clone.component";
import { CompanyViewComponent } from "./pages/company/form/view.component";

// activities
import { CreateAtividadeComponent } from "./pages/atividade/create.component";
import { EditAtividadeComponent } from "./pages/atividade/edit.component";
import { CloneAtividadeComponent } from "./pages/atividade/clone.component";
import { ViewAtividadeComponent } from "./pages/atividade/view.component";
import { AtividadeComponent } from "./pages/atividade/atividade.component";
import { DriverReportComponent } from "./pages/driver-report/driver-report.component";
import { DriverReportMapComponent } from "./pages/driver-report/driver-report-map.component";
import { VehicleReportComponent } from "./pages/vehicle-report/vehicle-report.component";
import { CommandReportComponent } from "./pages/command-report/command-report.component";

import { AuthGuard } from "./helpers/auth.guard";
import { TerminalAlarmeComponent } from "./pages/terminal-alarme/terminal-alarme.component";
import { CreateTerminalAlarmeComponent } from "./pages/terminal-alarme/create.component";
import { EditTerminalAlarmeComponent } from "./pages/terminal-alarme/edit.component";
import { CloneTerminalAlarmeComponent } from "./pages/terminal-alarme/clone.component";
import { ViewTerminalAlarmeComponent } from "./pages/terminal-alarme/view.component";
import { VehicleTypesComponent } from "./pages/vehicle-types/vehicle-types.component";
import { ConsultaComponent } from "./pages/consulta/consulta.component";
import { DriversComponent } from "./pages/driver/list/drivers.component";
import { RoutesComponent } from "./pages/route/routes.component";
import { EletronicFencesComponent } from "./pages/eletronic-fence/eletronic-fences.component";
import { ControlItemComponent } from "./pages/control-item/control-item.component";
import { MaintenanceComponent } from "./pages/maintenance/maintenance.component";
import { VehiclesComponent } from "./pages/vehicles/vehicles.component";
import { CreateVehicleComponent } from "./pages/vehicles/create.component";
import { EditVehicleComponent } from "./pages/vehicles/edit.component";
import { CloneVehicleComponent } from "./pages/vehicles/clone.component";
import { ViewVehicleComponent } from "./pages/vehicles/view.component";
import { CreateDriverComponent } from "./pages/driver/form/create.component";
import { EditDriverComponent } from "./pages/driver/form/edit.component";
import { CloneDriverComponent } from "./pages/driver/form/clone.component";
import { ViewDriverComponent } from "./pages/driver/form/view.component";
import { TerminalComponentTerminal } from "./pages/terminal/terminal.component";
import { CreateTerminalComponent } from "./pages/terminal/create.component";
import { EditTerminalComponent } from "./pages/terminal/edit.component";
import { EditRouteComponent } from "./pages/route/edit.component";
import { EditEletronicFenceComponent } from "./pages/eletronic-fence/edit.component";
import { CloneTerminalComponent } from "./pages/terminal/clone.component";
import { ViewTerminalComponent } from "./pages/terminal/view.component";
import { CreateMaintenanceComponent } from "./pages/maintenance/create.component";
import { EditMaintenanceComponent } from "./pages/maintenance/edit.component";
import { CloneMaintenanceComponent } from "./pages/maintenance/clone.component";
import { ViewMaintenanceComponent } from "./pages/maintenance/view.component";
import { CockpitComponent } from "./pages/cockpit/cockpit.component";
import { SettingsComponent } from "./pages/setting/settings.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ModuloComponent } from "./pages/modulo/modulo.component";
import { CreateModuloComponent } from "./pages/modulo/create.component";
import { EditModuloComponent } from "./pages/modulo/edit.component";
import { CloneModuloComponent } from "./pages/modulo/clone.component";
import { ViewModuloComponent } from "./pages/modulo/view.component";
import { PerfilComponent } from "./pages/perfil/perfil.component";
import { CreatePerfilComponent } from "./pages/perfil/create.component";
import { EditPerfilComponent } from "./pages/perfil/edit.component";
import { ClonePerfilComponent } from "./pages/perfil/clone.component";
import { ViewPerfilComponent } from "./pages/perfil/view.component";
import { FuncionalidadeComponent } from "./pages/funcionalidade/funcionalidade.component";
import { CreateFuncionalidadeComponent } from "./pages/funcionalidade/create.component";
import { EditFuncionalidadeComponent } from "./pages/funcionalidade/edit.component";
import { CloneFuncionalidadeComponent } from "./pages/funcionalidade/clone.component";
import { ViewFuncionalidadeComponent } from "./pages/funcionalidade/view.component";
import { FuncionalidadeTipoComponent } from "./pages/funcionalidade-tipo/funcionalidade-tipo.component";
import { EditFuncionalidadeTipoComponent } from "./pages/funcionalidade-tipo/edit.component";
import { CloneFuncionalidadeTipoComponent } from "./pages/funcionalidade-tipo/clone.component";
import { ViewFuncionalidadeTipoComponent } from "./pages/funcionalidade-tipo/view.component";
import { CreateFuncionalidadeTipoComponent } from "./pages/funcionalidade-tipo/create.component";
import { PerfilTipoComponent } from "./pages/perfil-tipo/perfil-tipo.component";
import { CreatePerfilTipoComponent } from "./pages/perfil-tipo/create.component";
import { EditPerfilTipoComponent } from "./pages/perfil-tipo/edit.component";
import { ClonePerfilTipoComponent } from "./pages/perfil-tipo/clone.component";
import { ViewPerfilTipoComponent } from "./pages/perfil-tipo/view.component";
import { EventsAlarmComponent } from "./pages/events-alarm/events-alarm.component";
import { StatusComponent } from "./pages/status/status.component";
import { CreateNewPasswordWithCodeComponent } from "./create-new-password-with-code/create-new-password-with-code.component";
import { ValidateCodeComponent } from "./components/validate-code/validate-code.component";
import { RedirectLoadUserComponent } from "./login/redirect-load-user/redirect-load-user.component";
import { ListaTerminalComponent } from "./lista-terminal/lista-terminal.component";
import { StopReportComponent } from "./pages/stop-report/stop-report.component";
import { StopReportMapComponent } from "./pages/stop-report/stop-report-map.component";
import { EventsReportComponent } from "./pages/events-report/events-report.component";
import { CockpitGraphicComponent } from "./pages/cockpit-graphic/cockpit-graphic.component";
import { TransporterComponent } from "./pages/transporter/transporter.component";
import { CreateTransporterComponent } from "./pages/transporter/create-transporter.component";
import { EditTransporterComponent } from "./pages/transporter/edit-transporter.component";
import { CloneTransporterComponent } from "./pages/transporter/clone-transporter.component";
import { ViewTransporterComponent } from "./pages/transporter/view-transporter.component";
import { SensorComponent } from "./pages/sensor/sensor.component";
import { CreateSensorComponent } from "./pages/sensor/create.component";
import { EditSensorComponent } from "./pages/sensor/edit.component";
import { CloneSensorComponent } from "./pages/sensor/clone.component";
import { ViewSensorComponent } from "./pages/sensor/view.component";
import { PneuComponent } from "./pages/pneu/pneu.component";
import { CreatePneuComponent } from "./pages/pneu/create.component";
import { EditPneuComponent } from "./pages/pneu/edit.component";
import { ClonePneuComponent } from "./pages/pneu/clone.component";
import { ViewPneuComponent } from "./pages/pneu/view.component";
import { ReportOperationalAsyncComponent } from "./pages/report-operational-async/report-operational-async.component";
import { ReportAsyncDetailComponent } from "./pages/report-operational-async/report-async-detail/report-async-detail.component";
import { ReportOperationalAsyncEditComponent } from "./pages/report-operational-async/report-operational-async-edit.component";
import { ReportAsyncViewComponent } from "./pages/report-operational-async/report-async-view/report-async-view.component";
import { CreateAnalysisComponent } from "./pages/analyze-report-operator/create-analysis/create-analysis.component";
import { AnalyzeReportOperatorComponent } from "./pages/analyze-report-operator/analyze-report-operator.component";
import { TreatAnalysisComponent } from "./pages/analyze-report-operator/treat-analysis/treat-analysis.component";
import { ManagerAnalysisComponent } from "./pages/manager-analysis/manager-analysis.component";
import { ManagerTreatmentComponent } from "./pages/manager-analysis/manager-treatment/manager-treatment.component";
import { EditAnalysisComponent } from "./pages/analyze-report-operator/edit-analysis/edit-analysis.component";
import { ViewReportOperatorComponent } from "./pages/analyze-report-operator/view-report-operator/view-report-operator.component";
import { SupervisorAnalysisComponent } from "./pages/supervisor-analysis/supervisor-analysis.component";
import { SupervisorViewTreatComponent } from "./pages/supervisor-analysis/supervisor-view-treat/supervisor-view-treat.component";
import { RejectionSupervisorComponent } from "./pages/supervisor-analysis/rejection-supervisor/rejection-supervisor.component";
import { AnalyzeReportHistoricComponent } from "./pages/analyze-report-historic/analyze-report-historic.component";
import { ViewTreatAnalysisComponent } from "./pages/analyze-report-operator/view-treat-analysis/view-treat-analysis.component";
import { TreatAnalysisSelectComponent } from "./pages/analyze-report-operator/treat-analysis-select/treat-analysis-select.component";
import { NewAnalysisComponent } from "./pages/analyze-report-operator/new-analysis/new-analysis.component";
import { TreatAnalysisEditComponent } from "./pages/analyze-report-operator/treat-analysis-edit/treat-analysis-edit.component";
import { PenaltyFormComponent } from "./pages/supervisor-analysis/penalty-form/penalty-form.component";
import { FinalizeAnalysisComponent } from "./pages/analyze-report-operator/finalize-analysis/finalize-analysis.component";
import { MonthlyReportComponent } from "./pages/monthly-report/monthly-report.component";
import { MonthlyReportAllComponent } from "./pages/monthly-report/monthly-report-all/monthly-report-all.component";
import { ManagerComponent } from "./pages/manager/manager.component";
import { CreateManagerComponent } from "./pages/manager/create-manager/create-manager.component";
import { SupervisorComponent } from "./pages/supervisor/supervisor.component";
import { CreateSupervisorComponent } from "./pages/supervisor/create-supervisor/create-supervisor.component";
import { CambusComponent } from "./pages/cambus/cambus.component";
import { CompanySupervisorComponent } from "./pages/supervisor/company-supervisor/company-supervisor.component";
import { CompanyManagerComponent } from "./pages/manager/company-manager/company-manager.component";
import { DetailedAnalysisComponent } from "./pages/monthly-report/detailed-analysis/detailed-analysis.component";
import { ParametersTypeComponent } from "./pages/parameters-type/parameters-type.component";
import { ParametersCreateComponent } from "./pages/parameters-type/parameters-create/parameters-create.component";
import { ParametersEditComponent } from "./pages/parameters-type/parameters-edit/parameters-edit.component";
import { ReportingRuleCreateComponent } from "./pages/reporting-rule/reporting-rule-create/reporting-rule-create.component";
import { ReportingRuleEditComponent } from "./pages/reporting-rule/reporting-rule-edit/reporting-rule-edit.component";
import { ReportingRuleComponent } from "./pages/reporting-rule/reporting-rule.component";
import { ReportingMechanismComponent } from "./pages/reporting-mechanism/reporting-mechanism.component";
import { ReportingMechanismCreateComponent } from "./pages/reporting-mechanism/reporting-mechanism-create/reporting-mechanism-create.component";
import { MultipleFilesReportComponent } from "./pages/reporting-mechanism/multiple-files-report/multiple-files-report.component";
import { TrackerFileComponent } from './pages/tracker-file/tracker-file.component';

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import("./module/map.module").then((m) => m.MapModule),
  },

  {
    path: "journey-control",
    loadChildren: () =>
      import("./journey-control/journey-control.module").then(
        (m) => m.JourneyControlModule
      ),
  },
  {
    path: "lista-terminal",
    component: ListaTerminalComponent,
  },
  {
    path: "report-operational",
    loadChildren: () =>
      import("./reports/operational-filter/operational-filter.module").then(
        (m) => m.OperationalFilterModule
      ),
  },
  { path: "electric-fence-view/:id", component: MainComponent },
  { path: "route-view/:id", component: MainComponent },
  { path: "electronic-fence-view/:id", component: MainComponent },
  { path: "login", component: LoginComponent },
  {
    path: "registrations",
    component: RegistrationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "consultations",
    component: ConsultationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "reports",
    component: ReportsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "driver-report",
    component: DriverReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "driver-report-map",
    component: DriverReportMapComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "vehicle-report",
    component: VehicleReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "command-report",
    component: CommandReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "stop-report",
    component: StopReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "stop-report-map",
    component: StopReportMapComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "events-report",
    component: EventsReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "routes",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: RoutesComponent },
      { path: "edit/:id", component: EditRouteComponent },
    ],
  },
  {
    path: "eletronic-fences",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: EletronicFencesComponent },
      { path: "edit/:id", component: EditEletronicFenceComponent },
    ],
  },
  { path: "control-item", component: ControlItemComponent },
  { path: "painel-evento", component: CockpitComponent },
  { path: "painel-evento-grafico", component: CockpitGraphicComponent },

  // relatorio

  { path: "settings", component: SettingsComponent },
  { path: "vehicle-types", component: VehicleTypesComponent },
  { path: "events/:terminalId", component: EventsAlarmComponent },
  { path: "status/:status", component: StatusComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "validate-token/:token", component: ForgotPasswordComponent },
  { path: "validate-code", component: ValidateCodeComponent },
  {
    path: "create-new-password/:id",
    component: CreateNewPasswordWithCodeComponent,
  },

  { path: "loading", component: RedirectLoadUserComponent },
  {
    path: "atividades",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: AtividadeComponent },
      { path: "create", component: CreateAtividadeComponent },
      { path: "edit/:id", component: EditAtividadeComponent },
      { path: "clone/:id", component: CloneAtividadeComponent },
      { path: "view/:id", component: ViewAtividadeComponent },
    ],
  },

  {
    path: "users",
    canActivate: [AuthGuard],
    children: [
      { path: "create", component: CreateUserComponent },
      { path: "edit/:id", component: EditUserComponent },
      { path: "clone/:id", component: CloneUserComponent },
      { path: "view/:id", component: ViewUserComponent },
    ],
  },

  {
    path: "terminal-alarme",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: TerminalAlarmeComponent },
      { path: "create", component: CreateTerminalAlarmeComponent },
      { path: "edit/:id", component: EditTerminalAlarmeComponent },
      { path: "clone/:id", component: CloneTerminalAlarmeComponent },
      { path: "view/:id", component: ViewTerminalAlarmeComponent },
    ],
  },
  {
    path: "consulta",
    canActivate: [AuthGuard],
    children: [{ path: ":pesquisa", component: ConsultaComponent }],
  },
  {
    path: "drivers",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: DriversComponent },
      { path: "create", component: CreateDriverComponent },
      { path: "edit/:id", component: EditDriverComponent },
      { path: "clone/:id", component: CloneDriverComponent },
      { path: "view/:id", component: ViewDriverComponent },
    ],
  },
  {
    path: "maintenance",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: MaintenanceComponent },
      { path: "create", component: CreateMaintenanceComponent },
      { path: "edit/:id", component: EditMaintenanceComponent },
      { path: "clone/:id", component: CloneMaintenanceComponent },
      { path: "view/:id", component: ViewMaintenanceComponent },
    ],
  },

  {
    path: "vehicles",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: VehiclesComponent },
      { path: "create", component: CreateVehicleComponent },
      { path: "edit/:id", component: EditVehicleComponent },
      { path: "clone/:id", component: CloneVehicleComponent },
      { path: "view/:id", component: ViewVehicleComponent },
    ],
  },

  {
    path: "companies",
    canActivate: [AuthGuard],
    children: [
      { path: "create", component: CompanyCreateComponent },
      { path: "edit/:id", component: CompanyEditComponent },
      { path: "clone/:id", component: CompanyCloneComponent },
      { path: "view/:id", component: CompanyViewComponent },
    ],
  },

  {
    path: "terminal",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: TerminalComponentTerminal },
      { path: "create", component: CreateTerminalComponent },
      { path: "edit/:id", component: EditTerminalComponent },
      { path: "clone/:id", component: CloneTerminalComponent },
      { path: "view/:id", component: ViewTerminalComponent },
    ],
  },

  {
    path: "modulo",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: ModuloComponent },
      { path: "create", component: CreateModuloComponent },
      { path: "edit/:id", component: EditModuloComponent },
      { path: "clone/:id", component: CloneModuloComponent },
      { path: "view/:id", component: ViewModuloComponent },
    ],
  },

  {
    path: "perfil",
    // canActivate: [AuthGuard],
    children: [
      { path: "", component: PerfilComponent },
      { path: "create", component: CreatePerfilComponent },
      { path: "edit/:id", component: EditPerfilComponent },
      { path: "clone/:id", component: ClonePerfilComponent },
      { path: "view/:id", component: ViewPerfilComponent },
    ],
  },

  {
    path: "funcionalidade",
    // canActivate: [AuthGuard],
    children: [
      { path: "", component: FuncionalidadeComponent },
      { path: "create", component: CreateFuncionalidadeComponent },
      { path: "edit/:id", component: EditFuncionalidadeComponent },
      { path: "clone/:id", component: CloneFuncionalidadeComponent },
      { path: "view/:id", component: ViewFuncionalidadeComponent },
    ],
  },

  {
    path: "funcionalidade-tipo",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: FuncionalidadeTipoComponent },
      { path: "create", component: CreateFuncionalidadeTipoComponent },
      { path: "edit/:id", component: EditFuncionalidadeTipoComponent },
      { path: "clone/:id", component: CloneFuncionalidadeTipoComponent },
      { path: "view/:id", component: ViewFuncionalidadeTipoComponent },
    ],
  },

  {
    path: "perfil-tipo",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: PerfilTipoComponent },
      { path: "create", component: CreatePerfilTipoComponent },
      { path: "edit/:id", component: EditPerfilTipoComponent },
      { path: "clone/:id", component: ClonePerfilTipoComponent },
      { path: "view/:id", component: ViewPerfilTipoComponent },
    ],
  },
  {
    path: "sensor",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: SensorComponent },
      { path: "create", component: CreateSensorComponent },
      { path: "edit/:id", component: EditSensorComponent },
      { path: "clone/:id", component: CloneSensorComponent },
      { path: "view/:id", component: ViewSensorComponent },
    ],
  },
  {
    path: "pneu",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: PneuComponent },
      { path: "create", component: CreatePneuComponent },
      { path: "edit/:id", component: EditPneuComponent },
      { path: "clone/:id", component: ClonePneuComponent },
      { path: "view/:id", component: ViewPneuComponent },
    ],
  },
  {
    path: "relatorio-operacional-assincrono",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: ReportOperationalAsyncComponent },
      { path: "detail", component: ReportAsyncDetailComponent },
      { path: "edit/:uuid", component: ReportOperationalAsyncEditComponent },
      { path: "view/:uuid", component: ReportAsyncViewComponent },
    ],
  },
  {
    path: "relatorio-analitico-mensal",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: MonthlyReportComponent },
      { path: "consulta", component: MonthlyReportAllComponent },
      { path: "analise-detalhada/:uuid/:uuidMinio", component: DetailedAnalysisComponent },
    ],
  },
  {
    path: "tipo-parametro",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: ParametersTypeComponent },
      { path: "create", component: ParametersCreateComponent },
      { path: "edit/:uuid", component: ParametersEditComponent },
    ],
  },
  {
    path: "regra-relatorio",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: ReportingRuleComponent },
      { path: "create", component: ReportingRuleCreateComponent },
      { path: "edit/:uuid", component: ReportingRuleEditComponent },
    ],
  },
  {
    path: "relatorios",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: ReportingMechanismComponent },
      { path: "create", component: ReportingMechanismCreateComponent },
      { path: "multiples-files/:uuid", component: MultipleFilesReportComponent },
    ],
  },
  {
    path: "analise-operacional",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: AnalyzeReportOperatorComponent },
      { path: "create", component: CreateAnalysisComponent },
      { path: "edit/:uuid", component: EditAnalysisComponent },
      { path: "view/:uuid", component: ViewReportOperatorComponent },
      {
        path: "treatment/:uuid/:uuidMinio/:dateAnalyze",
        component: TreatAnalysisComponent,
      },
      { path: "new-analysis/:uuid", component: NewAnalysisComponent },
      {
        path: "treatment-select/:uuid",
        component: TreatAnalysisSelectComponent,
      },
      {
        path: "view-tratamento-operador/:uuid",
        component: ViewTreatAnalysisComponent,
      },
      {
        path: "tratamento-operador-edit/:uuid",
        component: TreatAnalysisEditComponent,
      },
      {
        path: "finalize-analysis/:uuid",
        component: FinalizeAnalysisComponent,
      },
    ],
  },
  {
    path: "analise-gestor",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: ManagerAnalysisComponent },
      {
        path: "treatment/:uuid",
        component: ManagerTreatmentComponent,
      },
    ],
  },

  {
    path: "analise-supervisor",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: SupervisorAnalysisComponent },
      {
        path: "tratamento-operador/:uuid",
        component: SupervisorViewTreatComponent,
      },
      {
        path: "rejection-supervisor/:uuid",
        component: RejectionSupervisorComponent,
      },
      {
        path: "form-penalties/:uuid",
        component: PenaltyFormComponent,
      },
    ],
  },

  {
    path: "analise-historico",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: AnalyzeReportHistoricComponent },
      {
        path: "tratamento-operador/:uuid",
        component: SupervisorViewTreatComponent,
      },
    ],
  },

  {
    path: "gestor",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: ManagerComponent },
      { path: "create", component: CreateManagerComponent },
      { path: "company-manager/:uuid", component: CompanyManagerComponent },
    ],
  },

  {
    path: "supervisor",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: SupervisorComponent },
      { path: "create", component: CreateSupervisorComponent },
      { path: "company-supervisor/:uuid", component: CompanySupervisorComponent },
    ]
  },
  {
    path: "cambus-dados",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: CambusComponent },
    ],
  },

  {
    path: "operational-filter",
    loadChildren: () =>
      import("./reports/operational-filter/operational-filter.module").then(
        (m) => m.OperationalFilterModule
      ),
  },
  {
    path: "transporters",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: TransporterComponent },
      { path: "create", component: CreateTransporterComponent },
      { path: "edit/:uuid", component: EditTransporterComponent },
      { path: "clone/:uuid", component: CloneTransporterComponent },
      { path: "view/:uuid", component: ViewTransporterComponent },
    ],
  },
  {
    path: "tracker-files",
    canActivate: [AuthGuard],
    component: TrackerFileComponent
  },

  { path: "**", redirectTo: "" },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor() { }
}
