<div class="main-page consultas">
    <div class="container-fluid">
        <div class="row no-gutters mt-3">
            <div class="mat-elevation table-content">
                <table mat-table [dataSource]="excludedDice" matSort class="mat-elevation-z8">

                    <!-- Coluna para o checkbox -->
                    <ng-container matColumnDef="eliminar">
                        <th mat-header-cell *matHeaderCellDef>Eliminar Linha do Relatorio Base</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-checkbox [(ngModel)]="element.eliminar" color="primary"></mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="tipoVeiculo">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo Veículo</th>
                        <td mat-cell *matCellDef="let element">{{ element['TIPO VEICULO'] }}</td>
                    </ng-container>

                    <ng-container matColumnDef="motorista">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Motorista</th>
                        <td mat-cell *matCellDef="let element">{{ element['MOTORISTA'] }}</td>
                    </ng-container>

                    <ng-container matColumnDef="placa">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Placa</th>
                        <td mat-cell *matCellDef="let element">{{ element['PLACA'] }}</td>
                    </ng-container>

                    <ng-container matColumnDef="kmTotal">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>KM Total</th>
                        <td mat-cell *matCellDef="let element">{{ element['KM TOTAL'] }}</td>
                    </ng-container>

                    <ng-container matColumnDef="consumoTotal">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Consumo Total (L)</th>
                        <td mat-cell *matCellDef="let element">{{ element['CONSUMO TOTAL (L)'] }}</td>
                    </ng-container>

                    <ng-container matColumnDef="mediaKmL">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Média KM/L</th>
                        <td mat-cell *matCellDef="let element">{{ element['MÉDIA KM/L'] }}</td>
                    </ng-container>

                    <ng-container matColumnDef="consumoMovimento">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Consumo Movimento (L)</th>
                        <td mat-cell *matCellDef="let element">{{ element['CONSUMO MOVIMENTO (L)'] }}</td>
                    </ng-container>

                    <ng-container matColumnDef="consumoParado">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Consumo Parado (L)</th>
                        <td mat-cell *matCellDef="let element">{{ element['CONSUMO PARADO (L)'] }}</td>
                    </ng-container>

                    <ng-container matColumnDef="rpmVerde">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>RPM Verde</th>
                        <td mat-cell *matCellDef="let element">{{ element['RPM VERDE (%)'] }}</td>
                    </ng-container>

                    <ng-container matColumnDef="rpmAmarelo">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>RPM Amarelo</th>
                        <td mat-cell *matCellDef="let element">{{ element['RPM AMARELO (%)'] }}</td>
                    </ng-container>

                    <ng-container matColumnDef="rpmVermelho">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>RPM Vermelho</th>
                        <td mat-cell *matCellDef="let element">{{ element['RPM VERMELHO (%)'] }}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
                </table>
            </div>
        </div>

        <!-- Centralizar o botão -->
        <div class="row mt-3 justify-content-center" style="padding: 20px;">
            <div class="col-auto">
                <button mat-raised-button color="primary" (click)="sendSelectedRows()">
                    Eliminar Dados Selecionados
                </button>
            </div>
        </div>

    </div>
</div>