<div class="main-page cadastros">
    <div class="container-fluid">
        <app-title>Tipo de parâmetros</app-title>

        <div class="row mt-3 no-gutters p-4 row-form">
            <div class="col-12">
                <form [formGroup]="parametersTypeForm" (ngSubmit)="submitForm()">
                    <h2 class="mb-3">Dados do Parâmetro</h2>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Operações Válidas</mat-label>
                                <mat-select formControlName="validOperation" [(value)]="operationValidSelected"
                                    required>
                                    <mat-option *ngFor="let type of operationValids" [value]="type.valueEnum">
                                        {{ type.operationName }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="form-group col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Campos Válidos</mat-label>
                                <mat-select formControlName="validFields" [(value)]="fieldValidSelected" required>
                                    <mat-option *ngFor="let type of fieldValids" [value]="type.valueEnum">
                                        {{ type.operationName }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="form-group col-lg-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Modelo do Veículo para Regra (OPCIONAL)</mat-label>
                                <input type="text" matInput placeholder="Filtrar modelos"
                                    [formControl]="modelFilterControl" [matAutocomplete]="auto"
                                    [(ngModel)]="modelSelected" style="text-transform: uppercase;">
                                <mat-icon matPrefix>search</mat-icon>
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onModelSelected($event)">
                                    <mat-option *ngFor="let type of filteredModels | async" [value]="type">
                                        {{ type }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-lg-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Descrição</mat-label>
                                <input formControlName="description" matInput placeholder="Descrição"
                                    style="text-transform: uppercase;">
                            </mat-form-field>
                        </div>
                    </div>


                    <div *ngIf="errorMessage" class="alert alert-danger">
                        {{ errorMessage }}
                    </div>

                    <div class="form-group mt-2 mb-0 text-center">
                        <back-button></back-button>
                        <button type="submit" [disabled]="!parametersTypeForm.valid"
                            class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
                            Criar Tipo de Parâmetro
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>