<div class="main-page consultas">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-12 my-2">
                <h1>
                    <a class="btn btn-voltar text-center mr-2" (click)="this.router.navigate(['/consultations'])"
                        data-title="Voltar"><i class="fas fa-arrow-left"></i></a>
                    Consulta de empresas vinculadas ao gestor
                </h1>
            </div>
        </div>
        <div *ngIf="analyzeReportSupervisorsPage?.page.totalElements === 0">
            <div class="row no-gutters mt-3">
                <p>Nenhum registro até o momento...</p>
            </div>
        </div>
        <div class="row no-gutters mt-3">
            <div class="mat-elevation table-content">
                <div fxLayout fxLayoutAlign="center center">
                    <mat-form-field fxFlex="10%" class="ml-2">
                        <mat-label>Campo Filtro</mat-label>
                        <mat-select (selectionChange)="this.fieldFilter()" [(value)]="typeFieldFilterSelected"
                            [(ngModel)]="typeFieldFilterSelected">
                            <mat-option *ngFor="let type of typeFieldSelect" [value]="type.value">
                                {{ type.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="40%" class="ml-2">
                        <mat-label>Filtrar por nome de empresa</mat-label>
                        <input matInput autocomplete="off" (keyup)="this.applyFilter()" [(ngModel)]="filter"
                            style="text-transform: uppercase;" #input>
                    </mat-form-field>
                </div>
                <table mat-table [dataSource]="managersView" matSort class="mat-elevation-z8">

                    <ng-container matColumnDef="nameCompany">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome Empresa</th>
                        <td mat-cell *matCellDef="let element">{{ element.name_company}}</td>
                    </ng-container>

                    <!-- Primeira Flag Column -->
                    <ng-container matColumnDef="companyBond">
                        <th mat-header-cell *matHeaderCellDef>Vincular</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-slide-toggle (change)="onToggleAndCheckboxChange(element, 'company_bond')"
                                [(ngModel)]="element.company_bond" color="primary"></mat-slide-toggle>
                        </td>
                    </ng-container>

                    <!-- Segunda Flag Column -->
                    <ng-container matColumnDef="automaticShipping">
                        <th mat-header-cell *matHeaderCellDef>Envio Automático</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-slide-toggle (change)="onToggleAndCheckboxChange(element, 'automatic_shipping')"
                                [(ngModel)]="element.automatic_shipping" color="primary"
                                [disabled]="!element.company_bond"></mat-slide-toggle>
                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[10, 20, 40]" [pageSize]="analyzeReportSupervisorsPage.page.size"
                    (page)="onChangePage($event)" [length]="analyzeReportSupervisorsPage.page.totalElements"
                    showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</div>