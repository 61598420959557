import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AnalyzeTripService } from "../../../services/analyze-trip.service";
import { toCamel } from "snake-camel";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CoreService } from "../../../services/migrate/api.core.service";
import { USER_DATA } from "../../../utils/constants.util";
import { ToolsService } from "../../../services/migrate/tools.service";
import { Empresa } from "../../../models/markerModel";
import { STORAGE_KEY_CURRENT_USER } from "../../../storageCore/constStorageKeys";
import storageX from "../../../storageCore/storageX";

import Swal from "sweetalert2";
import { AnalyzeReportModel } from "../../../models/analyze-report-model";
import { DestinyAnalysisModel } from "../../../models/destiny-analysis-model";

@Component({
  selector: 'app-monitoring-sent',
  templateUrl: './monitoring-sent.component.html',
  styleUrls: ['./monitoring-sent.component.scss']
})
export class MonitoringSentComponent implements OnInit {

  public analyzeTripService: AnalyzeTripService;
  public serviceCore: CoreService;

  public analyzeReportModel: AnalyzeReportModel;

  public controlCompanyName = new FormControl({ value: '', disabled: true });

  public controlPlaca = new FormControl({ value: '', disabled: true });

  public controlCompany = new FormControl();
  public controlDriver = new FormControl();
  public controlVehicle = new FormControl();

  public companys = new Array<any>();
  public companysFiltered = new Observable<Array<any>>();
  public drivers = new Array<any>();
  public driversFiltered = new Observable<Array<any>>();
  public vehicles = new Array<any>();
  public vehiclesFiltered = new Observable<Array<any>>();

  public companiesFilter: any = [];
  public companiesSelect: any = [];
  public typeAnalyzeSelect: any = [];
  public operationalBases: any = [];
  public supervisorAvailable: any[] = [];
  public selectedSupervisor: any[] = [];
  public managerAvailable: any[] = [];
  public selectedManager: any[] = [];

  public monitoringUuid = "";
  public monitoringOld: any;

  public reportOperationalForm: FormGroup;

  public companyFilterSelected;
  public typeAnalyzeFilterSelected;
  public typesReportSelected;

  public errorMessage;

  ngOnInit(): void {


    // Definir o valor padrão da análise
    this.typeAnalyzeFilterSelected = "VEHICLE_OPERATIONAL";

    this.reportOperationalForm = this.formBuilder.group(
      {
        selectCompany: [""],
        selectTypeAnalyze: [{ value: this.typeAnalyzeFilterSelected, disabled: true }, Validators.required], // Preenchido e desabilitado
        selectOperationaBase: ["", Validators.required],
        dateStart: [{ value: "", disabled: true }, Validators.required], // Preenchido e desabilitado
        dateEnd: ["", Validators.required],
        packageNumber: [{ value: "", disabled: true },],
        driverName: [{ value: "", disabled: true }],
        description: ["", Validators.required],
        gpsJumps: [false],
      },
      { validators: this.validateDateRange.bind(this) }
    );

    this.controlVehicle.disable();


    this.companysFiltered = this.controlCompany.valueChanges.pipe(
      map((value) => (typeof value === "string" ? value : value.id)),
      map((name) =>
        name ? this.filterValuesCompany(name) : this.companys.slice()
      )
    );

    this.vehiclesFiltered = this.controlVehicle.valueChanges.pipe(
      map((value) => (typeof value === 'string' ? value : value.cdPlaca)),
      map((name) =>
        name ? this.filterValuesTerminal(name) : this.vehicles.slice()
      )
    );

    this.driversFiltered = this.controlDriver.valueChanges.pipe(
      map((value) => (typeof value === "string" ? value : value.nmNome)),
      map((name) =>
        name ? this.filterValuesDriver(name) : this.drivers.slice()
      )
    );

    this.monitoringUuid = this.activatedRoute.snapshot.params.uuid;

    this.loadDataElements();
  }


  validateDateRange(formGroup: FormGroup) {
    const dateStartControl = formGroup.get("dateStart");
    const dateEndControl = formGroup.get("dateEnd");

    if (!dateStartControl || !dateEndControl) return;

    const startDate = new Date(dateStartControl.value);
    const endDate = new Date(dateEndControl.value);
    const now = new Date();

    const invalidFutureDateError = startDate > now || endDate > now;
    const invalidDateRangeError = endDate < startDate;

    if (invalidFutureDateError) {
      dateStartControl.setErrors({ invalidFutureDate: true });
      dateEndControl.setErrors({ invalidFutureDate: true });
    } else {
      dateStartControl.setErrors(null);
      dateEndControl.setErrors(null);
    }

    if (invalidDateRangeError) {
      dateEndControl.setErrors({ invalidDateRange: true });
    } else if (!invalidFutureDateError) {
      dateEndControl.setErrors(null);
    }

    if (!invalidFutureDateError && !invalidDateRangeError) {
      formGroup.setErrors(null);
    } else {
      formGroup.setErrors({
        ...(invalidFutureDateError && { invalidFutureDate: true }),
        ...(invalidDateRangeError && { invalidDateRange: true })
      });
    }
  }


  constructor(
    private tools: ToolsService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    analyzeTripService: AnalyzeTripService,
    private activatedRoute: ActivatedRoute,
    apiserviceCore: CoreService
  ) {
    this.serviceCore = apiserviceCore;
    this.analyzeTripService = analyzeTripService;
  }

  onVehicleSelected(selectedVehicle: any): void {
    if (selectedVehicle && selectedVehicle.cdPlaca) {
      console.log('Placa selecionada:', selectedVehicle);

      // Preenche o nome da empresa no campo correspondente
      if (selectedVehicle.nmempresa) {
        this.controlCompanyName.setValue(selectedVehicle.nmempresa);
        console.log('Nome da empresa:', selectedVehicle.nmempresa);
        this.getManagerAndSupervisorVehicle(selectedVehicle.nmempresa, null)
      } else {
        this.controlCompanyName.setValue(selectedVehicle.nmdivisao);
        console.log('Nome da divisao:', selectedVehicle.nmdivisao);
        this.getManagerAndSupervisorVehicle(null, selectedVehicle.nmdivisao)
      }
    } else {
      console.log('Nenhuma placa válida foi selecionada ou o valor é inválido.');
      this.controlCompanyName.setValue('');
    }
  }

  public async loadDataElements() {
    try {
      this.tools.builder().spinner.show();

      const companys = await this.serviceCore.get<any>({
        url: "v2/companys",
        params: {
          profileId: USER_DATA().profileId,
          size: "5000",
          page: "0",
          sort: "nome",
          sortDirections: "ASC",
        },
      });

      this.companys = companys?.elements;
      this.companys = this.companys.sort((a, b) =>
        a.nmNome.localeCompare(b.nmNome)
      );
      this.controlCompany.setValue("");

      const drivers = await this.serviceCore.get<any>({
        url: "v2/drivers/getAll",
        params: {
          profileId: USER_DATA().profileId,
          size: "5000",
          page: "0",
          sort: "nome",
          sortDirections: "ASC",
        },
      });

      this.drivers = drivers.elements;
      this.drivers = this.drivers.sort((a, b) =>
        a.nmNome.localeCompare(b.nmNome)
      );
      this.controlDriver.setValue("");

      const terminals = await this.serviceCore.get<any>({
        url: "v2/terminals",
        params: {
          profileId: USER_DATA().profileId,
          size: "5000",
          page: "0",
          sortDirections: "ASC",
        },
      });

      this.vehicles = terminals.elements;
      this.vehicles = this.vehicles.sort((a, b) =>
        a.cdPlaca.localeCompare(b.cdPlaca)
      );
      this.controlVehicle.setValue("");

      this.companiesSelect = [
        { name: "Divisão", value: 1 },
        { name: "Matriz", value: 2 },
        { name: "Filial", value: 3 },
      ];

      this.typeAnalyzeSelect = [
        { name: "Análise Operacional Motorista", value: "DRIVER_OPERATIONAL" },
        { name: "Análise Operacional Veiculo", value: "VEHICLE_OPERATIONAL" },
      ];

      this.analyzeTripService.getAllOperationaBase().subscribe((t) => {
        this.operationalBases.push(t);
      });

      this.analyzeTripService
        .getByUuidMonitoring(this.monitoringUuid)
        .subscribe((resp) => {
          this.monitoringOld = toCamel(resp);
          this.fillTransporterData();
        });

      this.tools.builder().spinner.hide();
    } catch (error) {
      this.tools.builder().spinner.hide();
      throw new EvalError(error);
    }
  }

  private fillTransporterData(): void {
    const monitoramento = this.monitoringOld;

    // Data de Início
    if (monitoramento.monitoringStartDate) {
      this.reportOperationalForm.get("dateStart")?.setValue(monitoramento.monitoringStartDate);
    }

    // Número do Pacote
    if (monitoramento.tripNumber) {
      this.reportOperationalForm.get("packageNumber")?.setValue(monitoramento.tripNumber);
    }

    // Nome do Motorista
    if (monitoramento.driverName) {
      this.reportOperationalForm.get("driverName")?.setValue(monitoramento.driverName.toUpperCase());
    }

    // Placa / Frota
    const terminal = this.vehicles.find((v) => v.id === monitoramento.idTerminal);
    if (terminal) {
      this.controlVehicle.setValue(terminal);
      const displayPlaca = terminal.cdPlaca + (terminal.frota ? "/" + terminal.frota : "");
      this.controlPlaca.setValue(displayPlaca);
    }

    // Nome da Empresa ou Divisão
    if (monitoramento.idCompany) {
      const empresa = this.companys.find((c) => c.id === monitoramento.idCompany);
      if (empresa) {
        this.controlCompanyName.setValue(empresa.nmNome);
        this.getManagerAndSupervisorVehicle(empresa.nmNome, null);
      }
    } else if (monitoramento.idDivision) {
      const divisao = this.companys.find((c) => c.id === monitoramento.idDivision);
      if (divisao) {
        this.controlCompanyName.setValue(divisao.nmNome);
        this.getManagerAndSupervisorVehicle(null, divisao.nmNome);
      }
    }
  }


  isAllSelectedSupervisor(): boolean {
    if (this.supervisorAvailable.length === 0) {
      return false;
    }
    return this.supervisorAvailable.every((task) => task.allComplete);
  }

  isAllSelectedManager(): boolean {
    if (this.managerAvailable.length === 0) {
      return false;
    }
    return this.managerAvailable.every((task) => task.allComplete);
  }

  someComplete(task: any): boolean {
    // Implemente a lógica para verificar se alguma subtarefa está completa
    return task.subtasks.some((subtask: any) => subtask.completed);
  }

  setAllSupervisor(task: any, checked: boolean): void {
    if (task && task.subtasks) {
      task.subtasks.forEach((subtask: any) => (subtask.completed = checked));
      this.updateAllCompleteSupervisor(task);
    }
  }

  setAllManager(task: any, checked: boolean): void {
    if (task && task.subtasks) {
      task.subtasks.forEach((subtask: any) => (subtask.completed = checked));
      this.updateAllCompleteManager(task);
    }
  }

  updateAllCompleteSupervisor(task: any): void {
    // Implemente a lógica para verificar se todas as subtarefas estão completas e marcar a tarefa principal
    task.allComplete = task.subtasks.every((subtask: any) => subtask.completed);
  }

  updateAllCompleteManager(task: any): void {
    // Implemente a lógica para verificar se todas as subtarefas estão completas e marcar a tarefa principal
    task.allComplete = task.subtasks.every((subtask: any) => subtask.completed);
  }

  displayCompany(empresa: Empresa): string {
    return empresa && empresa.nmNome ? empresa.nmNome : "";
  }

  displayDriver(motorista: any): string {
    return motorista && motorista.nmNome ? motorista.nmNome : "";
  }

  displayTerminal(terminal: any): string {
    var terminalPlaca = terminal.cdPlaca ? terminal.cdPlaca : "";
    var terminalFrota = terminal.frota ? terminal.frota : "";

    return terminal && terminal.cdPlaca
      ? terminalPlaca + "/" + terminalFrota
      : "";
  }

  public filterValuesDriver(value: string): Array<any> {
    const filterValue = value.toLowerCase();
    return this.drivers
      .sort((a, b) => a.nmNome.localeCompare(b.nmNome))
      .filter((client) => client.nmNome.toLowerCase().includes(filterValue));
  }

  public filterValuesTerminal(value: string): Array<any> {
    const filterValue = value.toLowerCase();
    return this.vehicles
      .sort((a, b) => a.cdPlaca.localeCompare(b.cdPlaca))
      .filter((client) => client.cdPlaca.toLowerCase().includes(filterValue));
  }

  public getManagerAndSupervisorVehicle(companyName: any, divisionName: any): void {
    this.tools.builder().spinner.show();

    let filteredCompany = null;
    let filteredDivision = null;

    if (companyName) {
      filteredCompany = this.companys.find(company => company.nmNome === companyName);
    } else if (divisionName) {
      filteredDivision = this.companys.find(company => company.nmNome === divisionName);
    }

    // Verificar se pelo menos um foi encontrado
    if (filteredCompany || filteredDivision) {
      const companyId = filteredCompany?.id || null;
      const divisionId = filteredDivision?.id || null;

      console.log('Empresa filtrada:', filteredCompany);
      console.log('Divisão filtrada:', filteredDivision);

      // Chamadas aos serviços, verificando se os IDs existem
      if (companyId || divisionId) {
        this.analyzeTripService.getBySupervisor(companyId, divisionId).subscribe((t) => {
          console.log('Supervisores encontrados:', t);
          this.supervisorAvailable = t;
          this.tools.builder().spinner.hide();
        });

        this.analyzeTripService.getByManager(companyId, divisionId).subscribe((t) => {
          console.log('Gestores encontrados:', t);
          this.managerAvailable = t;
          this.tools.builder().spinner.hide();
        });
      }
    } else {
      console.log('Nenhuma empresa ou divisão encontrada com os nomes fornecidos.');
    }

    this.tools.builder().spinner.hide();
  }

  public getManagerAndSupervisor() {
    this.tools.builder().spinner.show();

    var idCompany = null;
    var idDivision = null;

    if (this.controlCompany.value.cnpj != null) {
      idCompany = this.controlCompany.value.id;
    } else {
      idDivision = this.controlCompany.value.id;
    }

    this.analyzeTripService
      .getBySupervisor(idCompany, idDivision)
      .subscribe((t) => {
        console.log(t);
        this.supervisorAvailable = t;
      });

    this.analyzeTripService
      .getByManager(idCompany, idDivision)
      .subscribe((t) => {
        console.log(t);
        this.managerAvailable = t;
      });
  }

  public filterCompany() {
    this.controlCompany.setValue("");
    var companyModel = this.companyFilterSelected;
    var empresas = [];

    if (companyModel == "Divisão") {
      this.companys.forEach((element) => {
        if (element.cnpj == null) {
          empresas.push(element);
        }
      });
    }

    if (companyModel == "Matriz") {
      this.companys.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz == null) {
          empresas.push(element);
        }
      });
    }

    if (companyModel == "Filial") {
      this.companys.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz != null) {
          empresas.push(element);
        }
      });
    }

    this.companiesFilter = empresas;

    this.companysFiltered.subscribe(this.companiesFilter);
  }

  public filterValuesMotorista(value: string): Array<any> {
    const filterValue = value.toLowerCase();
    console.log(filterValue);
    return this.drivers
      .sort((a, b) => a.nmNome.localeCompare(b.nmNome))
      .filter((client) => client.nmNome.toLowerCase().includes(filterValue));
  }

  public filterValuesCompany(value: any): Array<any> {
    const filterValue = value;

    if (this.controlCompany.value.cnpj != null) {
      if (value != null) {
        this.loadFilterCompany(value);
      }
    } else {
      if (value != null) {
        this.loadFilterDivision(value);
      }
    }

    return this.companys
      .sort((a, b) => a.nmNome.localeCompare(b.nmNome))
      .filter((client) => client.nmNome.toLowerCase().includes(filterValue));
  }

  public async loadFilterCompany(value: any) {
    try {
      this.tools.builder().spinner.show();

      const drivers = await this.serviceCore.get<any>({
        url: "v2/drivers/findByCompany",
        params: {
          profileId: USER_DATA().profileId,
          idEmpresa: value,
          size: "5000",
          page: "0",
          sort: "nome",
          sortDirections: "ASC",
        },
      });

      this.drivers = drivers.elements;
      this.drivers = this.drivers.sort((a, b) =>
        a.nmNome.localeCompare(b.nmNome)
      );

      this.controlDriver.setValue("");

      this.tools.builder().spinner.hide();
    } catch (error) {
      this.tools.builder().spinner.hide();
      throw new EvalError(error);
    }
  }

  public async loadFilterDivision(value: any) {
    try {
      this.tools.builder().spinner.show();

      const drivers = await this.serviceCore.get<any>({
        url: "v2/drivers/findByDivision",
        params: {
          profileId: USER_DATA().profileId,
          idDivisao: value,
          size: "5000",
          page: "0",
          sort: "nome",
          sortDirections: "ASC",
        },
      });

      this.drivers = drivers.elements;
      this.drivers = this.drivers.sort((a, b) =>
        a.nmNome.localeCompare(b.nmNome)
      );

      this.controlDriver.setValue("");

      this.tools.builder().spinner.hide();
    } catch (error) {
      this.tools.builder().spinner.hide();
      throw new EvalError(error);
    }
  }

  // Função para adicionar ou remover empresa selecionada
  toggleSelectedSupervisor(supervisorName: string): void {
    const index = this.selectedSupervisor.indexOf(supervisorName);
    if (index === -1) {
      this.selectedSupervisor.push(supervisorName);
    } else {
      this.selectedSupervisor.splice(index, 1);
    }
    console.log("Supervisor selecionado:", this.selectedSupervisor);
  }

  toggleSelectedManager(managerName: string): void {
    const index = this.selectedManager.indexOf(managerName);
    if (index === -1) {
      this.selectedManager.push(managerName);
    } else {
      this.selectedManager.splice(index, 1);
    }
    console.log("Manager selecionado:", this.selectedManager);
  }

  public async submitForm(): Promise<void> {
    this.spinner.show();

    const values = this.reportOperationalForm.value;

    var dateStart: string = this.monitoringOld.monitoringStartDate + ":00";
    var dateEnd: string = values.dateEnd + ":00";

    var analyzeReport: AnalyzeReportModel = new AnalyzeReportModel();
    var destinyAnalysisManagerModelList: DestinyAnalysisModel[] = [];
    var destinyAnalysisSupervisorModelList: DestinyAnalysisModel[] = [];

    analyzeReport.monitoringUuid = this.monitoringUuid;
    analyzeReport.analyzeStartDate = dateStart;
    analyzeReport.analyzeEndDate = dateEnd;
    analyzeReport.description = values.description;
    analyzeReport.packageNumber = this.monitoringOld.tripNumber;
    analyzeReport.jumpGps = values.gpsJumps;
    analyzeReport.managerAnalyzeUuid = null;
    analyzeReport.supervisorUuid = null;
    analyzeReport.operationalBaseUuid = values.selectOperationaBase;
    analyzeReport.operatorAnalyzeId = storageX
      .whereKeyIs(STORAGE_KEY_CURRENT_USER)
      .get()?.id;
    analyzeReport.userId = storageX
      .whereKeyIs(STORAGE_KEY_CURRENT_USER)
      .get()?.id;
    analyzeReport.nameDriver = this.monitoringOld.driverName;

    analyzeReport.typeReportAnalyzeEnum = this.typeAnalyzeFilterSelected;

    if (this.selectedManager.length > 0) {
      this.selectedManager.forEach((sele) => {
        var destinyAnalysisManagerModel: DestinyAnalysisModel =
          new DestinyAnalysisModel();
        destinyAnalysisManagerModel.idUser = sele.idManager;

        destinyAnalysisManagerModelList.push(destinyAnalysisManagerModel);
      });
    }

    if (this.selectedSupervisor.length > 0) {
      this.selectedSupervisor.forEach((sele) => {
        var destinyAnalysisSupervisorModel: DestinyAnalysisModel =
          new DestinyAnalysisModel();
        destinyAnalysisSupervisorModel.idUser = sele.idSupervisor;

        destinyAnalysisSupervisorModelList.push(destinyAnalysisSupervisorModel);
      });
    }

    analyzeReport.destinyAnalysisManagerList = destinyAnalysisManagerModelList;
    analyzeReport.destinyAnalysisSupervisorList =
      destinyAnalysisSupervisorModelList;

    if (this.controlDriver.value != "") {
      console.log(this.controlDriver.value);
      analyzeReport.idDriver = this.controlDriver.value.id;
    }

    if (this.controlVehicle.value != "") {
      console.log(this.controlVehicle.value);
      analyzeReport.idTerminal = this.controlVehicle.value.id;
    }

    if (this.typeAnalyzeFilterSelected == 'DRIVER_OPERATIONAL') {
      if (this.controlCompany.value.cnpj != null) {
        analyzeReport.idCompany = this.controlCompany.value.id;
        analyzeReport.idDivision = null;
      } else {
        analyzeReport.idDivision = this.controlCompany.value.id;
        analyzeReport.idCompany = null;
      }
    } else {
      // Busca o nome da empresa ou divisão no input
      const companyName = this.controlCompanyName.value;

      // Tenta encontrar primeiro como empresa
      const filteredCompany = this.companys.find(company => company.nmNome === companyName && company.cnpj != null);

      if (filteredCompany) {
        analyzeReport.idCompany = filteredCompany.id;
        analyzeReport.idDivision = null;
        console.log('Empresa encontrada:', filteredCompany);
      } else {
        // Caso não seja uma empresa, tenta encontrar como divisão
        const filteredDivision = this.companys.find(division => division.nmNome === companyName && division.cnpj == null);

        if (filteredDivision) {
          analyzeReport.idDivision = filteredDivision.id;
          analyzeReport.idCompany = null;
          console.log('Divisão encontrada:', filteredDivision);
        }
      }
    }
    console.log(analyzeReport);

    this.analyzeTripService
      .createAnalyzeReport(analyzeReport)
      .subscribe((t) => {
        this.analyzeReportModel = toCamel(t) as AnalyzeReportModel;
      });

    this.spinner.hide();
    this.handleReport();
  }

  public async handleReport(): Promise<void> {
    Swal.fire({
      icon: "success",
      title: "Analise iniciada!",
      html: `<h6>Deseja ir para tela de consulta de análises?</h6>`,
      showCloseButton: false,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: "Não",
      showConfirmButton: true,
      confirmButtonText: "Sim",
      focusConfirm: false,
    }).then((action) => {
      if (action.isConfirmed) {
        this.router.navigate(["/analise-operacional"]);
      }
    });
  }
}

